<template>
  <div class="biz-img1">
    <img id="img1" src="./assets/biz-04.jpg" alt="geek9.kr">
    <div class="biz-title-group">
      <label class="biz-title">
        보관/포장/배송<br>
        <label class="font-detail">
          3PL 물류 업체 <label class="font-detail logo">&nbsp;GEEK9</label>
        </label>
      </label>
      <br>
      <label class="font-description">
        해외결제 / 해외현지픽업 / 해외이사 / 식품라이센스
      </label>
      <br>
      <span class="menu_border border" />
      <br>
      <label class="font-contact">
        010-8235-0642 | geek9geek9@gmail.com
      </label>
    </div>
  </div>

  <div id="section2" class="biz-img2" >
    <img id="img2" src="./assets/biz-07.jpg" alt="geek9.kr">
    <div class="biz-title-group2" >
      <div class="biz-title-sub-group2">
        <table class="table">
          <tr>
            <td>
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">글로벌<br>물류 관리</p>
              <p v-else>글로벌<br>물류 관리</p>
              
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="link desc-windows" @click="click({type:'popupCountry'})">국가보기</label>
              <label v-else class="link desc-mobile" @click="click({type:'popupCountry'})">국가보기</label>
              <!-- <label class="desc">홍콩/싱가포르/프랑스/스페인/이탈리아/영국/독일/네더란드/일본/중국/미국/호주/오스트리아/뉴질랜드/덴마크/베트남</label> -->
            </td>
            <td class="explain">
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">온라인<br>재고/주문관리</p>
              <p v-else>온라인<br>재고/주문관리</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">번거로운 재고/주문관리<br>클릭 한번으로 끝내기!</label>
              <label v-else class="desc-mobile">번거로운 재고/주문관리<br>클릭 한번으로 끝내기!</label>
            </td>
            <td>
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">현지인 / 한국인<br>담당자</p>
              <p v-else>현지인 / 한국인<br>담당자</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">해외 일처리도, 분쟁도<br>모두 모국어로!</label>
              <label v-else class="desc-mobile">해외 일처리도, 분쟁도<br>모두 모국어로!</label>
            </td>
            <td class="explain">
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">실시간<br>온라인 응대</p>
              <p v-else>실시간<br>온라인 응대</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">전화/카톡으로 바로바로<br>문의하고, 답변받기!</label>
              <label v-else class="desc-mobile">전화/카톡으로 바로바로<br>문의하고, 답변받기!</label>
            </td>
          </tr>
          <tr>
            <td class="explain">
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">해외현지<br>물류창고운영</p>
              <p v-else>해외현지<br>물류창고운영</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">세금? 통관? 해외에서<br>재고관리하면 문제 NO!</label>
              <label v-else class="desc-mobile">세금? 통관? 해외에서<br>재고관리하면 문제 NO!</label>
            </td>
            <td>
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">구매 / 배송 /<br>결제 / 픽업 /</p>
              <p v-else>구매 / 배송 /<br>결제 / 픽업 /</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">해외의 일이라면<br>무엇이든 물어보세요!</label>
              <label v-else class="desc-mobile">해외의 일이라면<br>무엇이든 물어보세요!</label>
            </td>
            <td class="explain">
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">세관업무</p>
              <p v-else>세관/통관업무</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">소명? 통관? 폐기?<br>GEEK9에서 대신<br>처리하겠습니다.</label>
              <label v-else class="desc-mobile">입증? 소명? 폐기?<br>GEEK9에서 대신<br>처리하겠습니다.</label>
            </td>
            <td>
              <p v-if="this.api.getOs() === 'Windows' || 'Mac'" class="title-windows">우체국 / DHL /<br>FEDEX / EMS /</p>
              <p v-else>우체국 / DHL /<br>FEDEX / EMS /</p>
              <label v-if="this.api.getOs() === 'Windows' || 'Mac'" class="desc-windows">배송은 원하시는<br>방식으로!</label>
              <label v-else class="desc-mobile">배송은 원하시는<br>방식으로!</label>
            </td>
          </tr>
        </table>
      </div>  
    </div>
  </div>

  <div class="biz-img3">
    <div class="biz-title-group" >
      <label class="font-qna">문의하기</label>
      <br><br>
      <div class="icon-qna">
        <a @click="click({type:'download'})" target="_blank"><img class="kakao link" src="./assets/card.png" style="min-width:60px;height: 60px; padding:20px;"/></a>
        <a href="/qna" target="_blank"><img class="kakao" src="./assets/qna.png" style="min-width:60px;height: 60px; padding:20px;"/></a>
        <a href="http://pf.kakao.com/_ezCxhC/chat" target="_blank"><img class="kakao" src="./assets/kakaochat.png" /></a>
        <a href='tel:010-8235-0642' ><img alt="geek9.kr" class="kakao" src="./assets/call.png" style="min-width:60px;height: 60px; padding:20px;"/></a>
      </div>
      <br><br>
      <label class="font-qna" style="font-size: 25px;">기업고객 배송비 조회</label>
      <br><br><br>
      <div class="navbar delivery biz-title-group div_center" style="max-width:500px; text-align: center;">
        <!-- <span class="navbar delivery" style="width:100%;text-shadow: none; color:black; font-weight: bolder;">기업고객 배송비 조회</span>  -->
        <table style="width:90%;text-shadow: none; color:black; margin: 10px; text-align: center;">
          <tr>
            <td style="width:40%">가로(mm)</td>
            <td style="width:40%"><input ref="bizHorizon" type="number" placeholder="mm" style="max-width:200px;margin: 5px;"></td>
          </tr>
          <tr>
            <td>세로(mm)</td>
            <td><input ref="bizVertical" type="number" placeholder="mm" style="max-width:200px;margin: 5px;"></td>
          </tr>
          <tr>
            <td>높이(mm)</td>
            <td><input ref="bizHeight" type="number" placeholder="mm" style="max-width:200px;margin: 5px;"></td>
          </tr>
          <tr>
            <td>무게(g)</td>
            <td><input ref="bizWeight" type="number" placeholder="g" style="max-width:200px;margin: 5px;"></td>
          </tr>
          <tr>
            <td colspan="2">
              <circle-progress id='progress3' style="transform: translateY(25%); display:block;float:left;" :percent="per" :size="30" :border-width="3" empty-color="#00000" />
              <br>
              <label class="e_price" id="e_val3" style="margin-top:10px;"></label>
              <br><br>
              <input ref='deliveryBtn' class="biz-button" @click="click({type:'delivery'})" type="button" value="배송비조회" style="width:100%;margin: 5px;">
            </td>
          </tr>
        </table>
      </div>
    </div>
    <img id="img3" src="./assets/biz-02.jpg" alt="geek9.kr">
    <div class="biz-footer-group" >
      <label style="font-size: 12px;">
        GEEK9 | 인천 남동구 예술로 226번길 17, A-608 | 사업자번호 : 628-27-00300 | 통신판매업 : 2018-인천연수구-0625
      </label>
    </div>
  </div>

  <Modal v-model='isShow' :close='closeModal' style="z-index: 99999999999;">
    <div id="modal_main" class="modal">
      <div class="div_title" style="text-align: left;margin-top:-20px;"><span style="color: #ff7f50; float:left;">&#9654;&nbsp;</span> 물류국가
        <span style="float:right;">
          <select v-model="addrCountry" style="text-align:center; margin-top:-5px; padding:5px;" @change="changed($event)">
            <option value = "홍콩">홍콩 (+852)</option>
            <option value = "싱가포르">싱가포르 (+65)</option>
            <option value = "말레이시아">말레이시아 (+60)</option>
            <option value = "프랑스">프랑스 (+33)</option>
            <option value = "스페인">스페인 (+34)</option>
            <option value = "이탈리아">이탈리아 (+39)</option>
            <option value = "영국">영국 (+44)</option>
            <option value = "독일">독일 (+49)</option>
            <option value = "네덜란드">네덜란드 (+31)</option>
            <option value = "일본">일본 (+81)</option>
            <option value = "중국">중국 (+86)</option>
            <option value = "미국">미국 (+1)</option>
            <option value = "호주">호주 (+61)</option>
            <option value = "오스트리아">오스트리아 (+43)</option>
            <option value = "뉴질랜드" >뉴질랜드 (+64)</option>
            <option value = "대만" >대만 (+886)</option>
          </select>
        </span>
      </div>
      <br>
      <table id="modal_table" class="table" style="width:100%;">
        <tr>
          <td v-html="this.addrmenu1"></td>
          <td v-on:click="this.api.copyClipboard(this.addr1)">{{this.addr1}}</td>
        </tr>
        <tr>
          <td v-html="this.addrmenu2"></td>
          <td v-on:click="this.api.copyClipboard(this.addr2)">{{this.addr2}}</td>
        </tr>
        <tr>
          <td v-html="this.addrmenu3"></td>
          <td v-on:click="this.api.copyClipboard(this.city)">{{this.city}}</td>
        </tr>
        <tr>
          <td v-html="this.addrmenu4"></td>
          <td v-on:click="this.api.copyClipboard(this.state)">{{this.state}}</td>
        </tr>
        <tr>
          <td v-html="this.addrmenu5"></td>
          <td v-on:click="this.api.copyClipboard(this.zipcode)">{{this.zipcode}}</td>
        </tr>
        <tr>
          <td v-html="this.addrmenu6"></td>
          <td v-on:click="this.api.copyClipboard(this.phone)">{{this.phone}}</td>
        </tr>
      </table>
      <div><button class="modal_button" @click="closeModal()" style="margin-top: 20px;">close</button></div>
    </div>
  </Modal>  
</template>
<script>
// import { useMeta } from 'vue-meta'
import { ref, inject } from 'vue'
import CircleProgress from "vue3-circle-progress";
import ssr from '@/components/common/ssr'

export default {
  mounted() {
    this.addJsonLd()
    this.api.setPage('biz')
    this.$emit("setMenu", {page:'biz'})
    console.log(this.api.getCookies('page'))    
  },  
  components: {
    CircleProgress
  },
  data() {
    return {
      per: 0,
      auto_reload_func: null,
      addrCountry:'홍콩',
      addr1:'Unit C1, 7/F Bonjour Tower',
      addr2:'No. 36-42 Wang Wo Tsai Street, Tsuen Wan, N.T., HK',
      city:'',
      state:'',
      zipcode:'없음',
      phone: '(852) 53161715',
      addrmenu1: '주소 #1',
      addrmenu2: '주소 #2',
      addrmenu3: '도시',
      addrmenu4: '주',
      addrmenu5: '우편번호',
      addrmenu6: '전화번호',
    }
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 기업물류'
    const keywords = '해외물류,수출,수입,수출입,물류대행,통관,무역,fulfillment,해외운송,이사,geek9,3PL,풀필먼트'
    const description = 'GEEK9는 국내외의 물류창고/현지인력/네트워크를 활용하여 글로벌 물류업무와 수출/수입/통관 업무를 대행하고 있습니다.'
    const geek9Url = ssr.GEEK9_URL + 'biz'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          el.remove()
        }
      })
      const script = document.createElement('script')
      script.setAttribute('type', 'application/ld+json')
      script.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          ssr.getWebPage(ssr.GEEK9_URL+"biz", "GEEK9 - 해외 물류/수출/통관/3PL/풀필먼트 전문 업체", ssr.GEEK9_URL, "GEEK9 - 해외 물류/수출/통관/3PL/풀필먼트 전문 업체"),
          {
            "@type": "BreadcrumbList",
            "name": "GEEK9 기업 해외 물류 공식 홈페이지",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "수출입/3PL/풀필먼트 견적 조회",
              "item": {
                "@type": "WebPage",
                "@id": "https://geek9.kr/trade#webPage",
                "url":"https://geek9.kr/trade",
                "name": "해외물류 견적서",
                "isPartOf": {"@id": "https://geek9.kr/biz#webPage"},
                "description": "GEEK9를 통해 진행된 전세계 물류 업무의 견적서를 조회하실 수 있습니다."
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "GEEK9 해외물류서비스와 레퍼런스",
              "item": {
                "@type": "WebPage",
                "@id": "https://geek9.kr/logistic#webPage",
                "url":"https://geek9.kr/logistic",
                "name": "해외물류 > 블로그",
                "isPartOf": {"@id": "https://geek9.kr/biz#webPage"},
                "description": "GEEK9 글로벌 해외 물류 업무 소개 및 레퍼런스"
              }
            }]
          }
        ]
      })
      document.head.appendChild(script)
    }
    const isShow = ref(false)
    const showModal = async () => {
      isShow.value = true;
    }
    const closeModal = async () => {
      isShow.value = false
    }
    return {
      isShow, showModal, closeModal, addJsonLd
    }
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'popupCountry') {
        this.showModal()
      } else if(type === 'delivery') {
        const horizon = this.$refs.bizHorizon.value; 
        const vertical = this.$refs.bizVertical.value;
        const height = this.$refs.bizHeight.value;
        const weight = this.$refs.bizWeight.value;

        if(horizon === '' || vertical == '' || height == '' || weight == '' ) {
          alert('입력하지 않은 값이 있습니다');
        } else {
          this.startProgress()
          const param = {'type': 'calcCorpDelivery', 'horizon': horizon/10, 'vertical': vertical/10, 'height': height/10, 'weight': weight/1000};
          await this.api.axiosGql('calcCorpDelivery', param) 
          .then( async (res) => { 
            let deliveryResultTag = document.getElementById("e_val3")
            var deliveryResult = res.data.result
            deliveryResult = parseInt(deliveryResult.toString()).toLocaleString()
            deliveryResultTag.innerHTML =  deliveryResult + "원(배송비)+3,500원(포장비)+통관비(2,500원)";
            deliveryResultTag.style.color = 'red'
          }) 
          .catch((err) => { console.log(err); }) 
          .finally(() => { 
            this.stopProgress() 
            document.getElementById('progress3').style.visibility = 'hidden'
          })
        }
      } else if(type === 'download') {
        const link = document.createElement('a')
        link.href = './assets/geek9.jpg'
        link.setAttribute('download', 'geek9.jpg')
        document.body.appendChild(link)
        link.click()
      }
    },
    startProgress() {
      document.getElementById("e_val3").innerHTML =  "";
      document.getElementById('progress3').style.visibility = 'visible'
      this.$refs.deliveryBtn.disabled = true
      this.auto_reload_func = setInterval(() => {
        this.per += 1
      }, 3)
    },
    stopProgress() {
      clearInterval(this.auto_reload_func)
      this.$refs.deliveryBtn.disabled = false
    },
    changed(event) {
      this.addrmenu1 = '주소 #1'
      this.addrmenu2 = '주소 #2'
      this.addrmenu3 = '도시'
      this.addrmenu4 = '주'
      this.addrmenu5 = '우편번호'
      this.addrmenu6 = '전화번호'
      
      let country = event.target.value
      if(country === '홍콩') {    // SOLAIR
        this.addrmenu3 = '전화번호'
        this.addrmenu4 = '우편번호'
        this.addrmenu5 = ''
        this.addrmenu6 = ''

        this.addr1 = 'Unit C1, 7/F Bonjour Tower',
        this.addr2 = 'No. 36-42 Wang Wo Tsai Street, Tsuen Wan, N.T',
        this.city = '53161715',
        this.state = '없음',
        this.zipcode = '',
        this.phone = ''
      } else if(country === '싱가포르') {   // POSTGO -> 씽배송(20240831)
        this.addr1 = '27 Hazel Park Terrace',
        this.addr2 = '#17-01',
        this.city = 'Singapore',
        this.state = 'west',
        this.zipcode = '678949',
        this.phone = '98244665'
        this.name = 'Kim sunhoa0642'
      } else if(country === '프랑스') {     // IPORTER
        this.addr1 = '28, Avenue Christian Doppler',
        this.addr2 = 'Menetz, ATTN363953',
        this.city = 'Bailly-Romainvilliers',
        this.state = '',
        this.zipcode = '77700',
        this.phone = '0788178295'
      } else if(country === '스페인') {   // IPORTER
        this.addr1 = 'Calle Mahon, 6',
        this.addr2 = 'Bajo B, KS-Hub spain ATTN363714',
        this.city = 'Las Rozas',
        this.state = 'Madrid',
        this.zipcode = '28290',
        this.phone = '0912511606'
      } else if(country === '이탈리아') {   // WEL
        this.name = 'ACI-SRL WorldExpress #WL1286'
        this.addr1 = 'VIA MARTIRI DI CEFALONIA 5',
        this.addr2 = 'ACI Express #WL1286',
        this.city = 'Segrate (Mi)',
        this.state = 'Lombardia 또는 Milano',
        this.zipcode = '20054',
        this.phone = '02-2187-3258'
      } else if(country === '영국') {   // IPORTER
        this.addr1 = '776 BUCKINGHAM AVENUE, SLOUGH',
        this.addr2 = 'ATTN363953',
        this.city = 'Slough',
        this.state = 'Berkshire',
        this.zipcode = 'SL1 4NL',
        this.phone = '0175-361-0400'
      } else if(country === '독일') {   // IPORTER
        this.addrmenu1 = 'Straße, Hausnummer<br>(지번)'
        this.addrmenu2 = 'Adresszusatz<br>(추가주소)'
        this.addrmenu3 = 'Postleitzahl<br>(우편번호)'
        this.addrmenu4 = 'Stadt<br>(시)'
        this.addrmenu5 = 'Bundesland<br>(주)'
        this.addrmenu6 = 'Telefonnummer<br>(전화번호)'

        this.addr1 = 'Waldstrasse 23C',
        this.addr2 = 'Menetz ATTN363953',
        this.city = '63128',
        this.state = 'Dietzenbach',
        this.zipcode = 'Hessen',
        this.phone = '06181-490-7360'
      } else if(country === '네덜란드') {   // WEL
        this.name = 'SYDAM #WL1286'
        this.addr1 = 'Keurmeesterstraat 57',
        this.addr2 = '#WL1286',
        this.city = 'Amstelveen',
        this.state = 'Noord-Holland',
        this.zipcode = '1187 ZX',
        this.phone = '+31 6 2494 0708'
      } else if(country === '일본') {   // IPORTER
        this.addrmenu1 = '郵便番号<br>(우편번호)'
        this.addrmenu2 = '都道府県<br>(도도부현)'
        this.addrmenu3 = '住所1<br>(시군구)'
        this.addrmenu4 = '住所2<br>(그외주소)'
        this.addrmenu5 = '電話番号<br>(전화번호)'
        this.addrmenu6 = ''
        
        this.addr1 = '112-0002',
        this.addr2 = '東京都',
        this.city = '文京区小石川 2-24-7',
        this.state = '名古屋ビル1F ATTN363953',
        this.zipcode = '03-3830-0852	',
        this.phone = ''
      } else if(country === '중국') {   // IPORTER
        this.addrmenu1 = '选择所在地<br>(지역)'
        this.addrmenu2 = '邮政编码<br>(우편번호)'
        this.addrmenu3 = '街道地址<br>(거리)'
        this.addrmenu4 = '收件人姓名<br>(수취인명)'
        this.addrmenu5 = '电话<br>(전화번호)'
        this.addrmenu6 = '手机<br>(폰번호)'

        this.addr1 = '上海市松江区九亭镇',
        this.addr2 = '201615',
        this.city = '盛龙路 755号 3号楼 208室 ATTN363953',
        this.state = 'Sungho Bae',
        this.zipcode = '189-3089-1491',
        this.phone = '189-3089-1491'
      } else if(country === '미국') {
        this.addr1 = '6424 NE 59th Place(Pl)',
        this.addr2 = 'ATTN363953',
        this.city = 'PORTLAND',
        this.state = 'OR(Oregon)',
        this.zipcode = '97218',
        this.phone = '503-208-3427'
      } else if(country === '호주') {     // ELDEX
        this.addr1 = 'UNIT 7 / 4 AVE OF AMERICAS',
        this.addr2 = 'ELDEX KW96832 PANTOS',
        this.city = 'NEWINGTON',
        this.state = 'NSW',
        this.zipcode = '2127',
        this.phone = '61-2-8305-3750'
      } else if(country === '뉴질랜드') {   // WEL
        this.name = 'SunghoBae #WL1286'
        this.addr1 = 'Unit 1, 61 View Rd, Wairau Valley',
        this.addr2 = 'GS EXPRESS - WEL #WL1286',
        this.city = 'AUCKLAND',
        this.state = 'state',
        this.zipcode = '0627',
        this.phone = '09-444-7897'
      // } else if(country === '덴마크') {   // WEL
      //   this.addr1 = 'Jungshøjgård 48',
      //   this.addr2 = 'Dennord#WL1286',
      //   this.city = 'Måløv',
      //   this.state = 'Hovedstanden',
      //   this.zipcode = '2760',
      //   this.phone = '22521201'
      // } else if(country === '베트남') {   // WEL
      //   this.addr1 = '67-69 Phan khiem ich tan phong ward D7',
      //   this.addr2 = 'HANKOOK #WL1286',
      //   this.city = 'Hochiminh',
      //   this.state = 'Vietman',
      //   this.zipcode = '070000',
      //   this.phone = '0787176492'
      } else if(country === '오스트리아') {   // WEL
        this.name = 'Joo ho Yun #WL1286'
        this.addr1 = 'Faberstraße 26 /9',
        this.addr2 = 'YUN Joo ho #WL1286',
        this.city = 'SALZBURG',
        this.state = 'state',
        this.zipcode = '5020',
        this.phone = '0670 405 9099'
      } else {
        this.name = ''
        this.addr1 = '',
        this.addr2 = '',
        this.city = '',
        this.state = '',
        this.zipcode = '',
        this.phone = ''
      }
    }
  }
}
</script>
<style scoped>

.delivery {
  background: white;
}

#section2 table {
  width:100%;
  height:100%;
  color:white;
}
#section2 td {
  width:25%;
  max-height:50%;
  font-weight: bold;
  font-size: calc(1.2vw + 1.5vh);
}
.desc-mobile {
  font-size: calc(0.7vw + 1vh);
}

.explain {
  background-color: rgba(255,255,255,0.2);
}
.explain label {
  color:white;
}
label {
  z-index:20;
  color:white;
  font-weight:bolder;
  /* text-shadow: 20px 20px 20px black; */
}
.kakao {
  min-width:100px;
  height:100px;
}
.font-qna {
  font-style: italic;
  font-size: 50px;
}
.border {
  background-color: white;
}
.biz-img1 {
  position:absolute;
  top:0px;
  left:0px;
  background-color: black;
  z-index:99999999;
  width:100%;
}
.biz-img2 {
  position:absolute;
  left:0px;
  z-index:99999999;
  width:100%;
}
.biz-img3 {
  position:absolute;
  left:0px;
  z-index:99999999;
  width:100%;
}
#biz-footer {
  z-index:30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
#img1 {
  position: absolute;
  width: 100%;
  height:100%;
  top: 0px;
  left: 0px;
  right: 0px;
  margin-top:0px;
  filter: brightness(50%); 
}
#img2 {
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  filter: brightness(40%);
}
#img3 {
  position: absolute;
  width: 100%;
  height: 120%;
  left: 0px;
  right: 0px;
  filter: brightness(40%);
}
.biz-title-group {
  text-align:center;
  z-index:30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.biz-title-group2 {
  text-align:center;
  width:100%;
  height: 100%;
  z-index:30;
  position: absolute; 
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.biz-footer-group {
  text-align:center;
  z-index:30;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translate(-50%,-20%);
  width:100%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.font-description {
  position: absolute;
  width:100%;
  font-style: italic;
}
.font-contact {
  text-shadow: 0px 0px 0px white;
  box-shadow: 5px 5px 100px black;
  color:white;
  width:100%;
}
.logo {
  text-shadow: 2px 2px 1px white;
}
@media screen and (min-width: 100px) {
  img {
    min-width:500px;  
  }
  .delivery {
    top:170%;
  }
  .biz-img1 {
    min-width:500px;
    height:300px;
  }
  .biz-img2 {
    min-width:500px;
    height:300px;
    margin-top:10px;
  }
  .biz-img3 {
    min-width:500px;
    height:600px;
    margin-top:335px;
  }
  #biz-footer {
    text-align:center;
  }
  .desc-windows {
    font-size: calc(0.1vw + 1vh);
  }
  .title-windows {
    font-size: calc(1.2vw + 1vh);
    font-style: italic;
  }
  .biz-title-group {
    transform: translate(-50%, -65%);
  }
  .biz-title-group2 {
    text-align:center;
    z-index:30;
    position: absolute;
    height:318px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  .biz-title-sub-group2 {
    margin: 20px;
    height:278px;
  }
  .biz-title {
    font-size:35px;
  }
  .font-detail {
    font-size: 16px;
  }
  .logo {
    font-size: 20px;
    color:#ff7f50;
  }
  .font-description {
    font-size:16px;
    transform: translate(-50%, 350%) 
  }
  .font-contact {
    font-size:16px;
    transform: translate(-50%, 350%);
    background-color: #ffa07a;
    padding:10px;
    width:600px;
  }
  .font-qna {
    font-size: 30px;
  }
}
@media screen and (min-width: 500px) {
  img {
    height:400px;
  }
  .delivery {
    top:200%;
  }

  .biz-img1 {
    height:400px;
  }
  .biz-img2 {
    height:400px;
    margin-top:110px;
  }
  .biz-img3 {
    height:600px;
    margin-top:510px;
  }
  #biz-footer {
    position:absolute;
    height:100px;
    margin-top:920px;
  }
  .desc-windows {
    font-size: calc(0.1vw + 1vh);
  }
  .biz-title-group {
    transform: translate(-50%, -100%);
  }
  .biz-title-group2 {
    text-align:center;
    z-index:30;
    height:400px;
    position: absolute;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  .biz-title-sub-group2 {
    margin: 20px;
    height:360px;
  }
  .biz-title {
    font-size:50px;
  }
  .font-detail {
    font-size: 20px;
  }
  .logo {
    font-size: 30px;
  }
  .font-description {
    font-size:20px;
    bottom: 40px;
  }
  .font-contact {
    width:400px;
    font-size:20px;
    position: absolute;
    bottom: 5px;
  }
  .border {
    position: absolute;
    bottom:-80px;
  }
}
@media screen and (min-width: 900px) {
  img {
    height:800px;
  }
  .delivery {
    top:200%;
  }
  .biz-img1 {
    height:600px;
  }
  .biz-img2 {
    height:500px;
    margin-top:310px;
  }
  .biz-img3 {
    height:800px;
    margin-top:1100px;
  }
  #biz-footer {
    position:absolute;
    height:100px;
    left:0px;
    margin-top:1850px;
  }
  .desc-windows {
    font-size: calc(0.4vw + 1vh);
  }
  .biz-title-group {
    transform: translate(-50%, -100%);
  }
  .biz-title-group2 {
    text-align:center;
    z-index:30;
    height:700px;
    position: absolute;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  .biz-title-sub-group2 {
    margin: 20px;
    height:660px;
  }
  .biz-title {
    font-size:90px;
  }
  .font-detail {
    font-size: 40px;
  }
  .logo {
    font-size: 50px;
  }
  .font-description {
    font-size:30px;
  }
  .font-contact {
    width:600px;
    font-size:30px;
    position: absolute;
    bottom:-30px;
  }
  .border {
    position: absolute;
    bottom:-130px;
  }
 .font-qna {
    font-size: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .delivery {
    top:190%;
  }
  .biz-img1 {
    height:800px;
  }
  .biz-img2 {
    height:500px;
    margin-top:510px;
  }
  .biz-img3 {
    height:800px;
    margin-top:1310px;
  }
  #biz-footer {
    position:absolute;
    height:100px;
    left:0px;
    margin-top:2150px;
  }
  .desc-windows {
    font-size: calc(0.5vw + 1vh);
  }
  .biz-title-group {
    transform: translate(-50%, -100%);
  }
  .biz-title-group2 {
    text-align:center;
    z-index:30;
    position: absolute;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  .biz-title-sub-group2 {
    margin: 20px;
    height:760px;
  }
  
  .biz-title {
    font-size:90px;
  }
  .font-detail {
    font-size: 40px;
  }
  .logo {
    font-size: 50px;
  }
  .font-description {
    font-size:30px;
  }
  .font-contact {
    width:600px;
    font-size:30px;
    position: absolute;
    bottom:-30px;
  }
  .border {
    position: absolute;
    bottom:-130px;
  }
  #modal_table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  #modal_table > tr {
    height : 50px;
  }
  #modal_table > tr > td:nth-child(odd) {
    width:fit-content;
    font-weight: bolder;
    background: #F5F5F5;
  }
  #modal_table > tr > td:nth-child(even) {
    text-decoration: underline;
    cursor: pointer;
    border: 1px solid #F5F5F5;
  }
  #modal_main {
    width:80%;
    max-width:600px;
  }
  #modal_main > p{
    text-align: left;
    font-style: italic;
    color:red;
    font-size: 12px;
  }
  .font-qna {
    font-size: 50px;
  }
}
.biz-button {
  background-color: lightsalmon; 
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  cursor: pointer;
}
</style>
  