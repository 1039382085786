<template>
  <confirm />
 
  <div v-if="isB2b === 'true' || isBiz === 'true'" class="fixed-top-menu">
    <div v-if="this.api.getCookies('page') === 'policy'" />
    <div v-else-if="this.api.getCookies('page') === 'logistic' || this.api.getCookies('page') === 'trade' || this.api.getCookies('page') === 'biz'" >
      <div id="modals"/>
      <nav class="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0" style="min-height: 80px;">
        <a href="/biz" class="navbar-brand d-flex align-items-center ">
          <img for="logoLink" src="./assets/geek9_logo.jpg" alt="geek9.kr" style="width:125px;height:30px;margin-left:10px;" >
        </a>
        <div style="position: absolute;right:10px;">
          <a href="tel:010-8235-0642" style="margin-right:10px;">
            <i class="fa fa-phone me-2"></i>
          </a>
          <select class="quote-keyword" v-model="menu" @change="click({TYPE:'change'})" style="padding:5px;margin-right:5px;">
            <option v-if="!menu" value="" style="display: none;">Menu</option>
            <option value="메인">메인</option>
            <option value="견적서">견적서</option>
            <option value="견적문의">견적문의</option>
            <option value="블로그">블로그</option>
          </select>
        </div>
      </nav>
      <Modal v-model='isShowQna' >
        <div id="modal_quoto_main" class="quoto-modal">
          <div class="div_title" style="text-align: left;margin-top:-20px;">
            <span style="color: #ffc107;color: #ff7f50;">문의하기</span>
          </div>
          <br>
          <br>
          <table class="" style="width:100%;text-align:left;" v-if="bizQnaType === ''">
            <tr>
              <td style="font-weight: bolder;">업무선택 *</td>
            </tr>
            <tr>
              <td>
                <select class="quote-keyword" v-model="bizQnaType" style="width:100%;" >
                  <option value="">선택</option>
                  <option value="3PL">3PL(풀필먼트)</option>
                  <option value="수출입">수출입</option>
                  <option value="배송">배송</option>
                  <option value="이사">해외이사</option>
                </select>
              </td>
            </tr>
            <br>
            <tr>
              <td style="text-align: center;" class="margin10">문의할 업무를 선택해주세요</td>
            </tr>
          </table>
          <table class="" style="width:100%;text-align:left;" v-else>
            <tr>
              <td style="font-weight: bolder;">업무선택 *</td>
            </tr>
            <tr>
              <td>
                <select ref="bizQnaType" class="quote-keyword" v-model="bizQnaType" style="width:100%;" >
                  <option value="">선택</option>
                  <option value="3PL">3PL(풀필먼트)</option>
                  <option value="수출입">수출입</option>
                  <option value="배송">배송</option>
                  <option value="이사">해외이사</option>
                </select>
              </td>
            </tr>
            <br>
            <tr>
              <td style="font-weight: bolder;">이메일</td>
            </tr>
            <tr>
              <td><input ref="bizQnaMail" class="quote-keyword" type="email" placeholder="답변 받을 이메일" style="width:100%;"></td>
            </tr>
            <br>
            <tr>
              <td style="font-weight: bolder;">폰번호</td>
            </tr>
            <tr>
              <td><input ref="bizQnaPhone" class="quote-keyword" type="tel" placeholder="답변 받을 폰번호" style="width:100%;"></td>
            </tr>
            <br>
            <tr>
              <td style="font-weight: bolder;">국가</td>
            </tr>
            <tr>
              <td>
                <div class="quote-country-wrapper" >
                  <span ><input ref="bizQnaStartCountry" class="quote-keyword" type="text" placeholder="출발국가" ></span>
                  <span class="arrow-origin"></span>
                  <div class="arrow"></div>
                  <span ><input ref="bizQnaEndCountry" class="quote-keyword" type="text" placeholder="도착국가" ></span>
                </div>
              </td>
            </tr>
            <br>
            
            <tr v-if="bizQnaType !== '이사'">
              <td style="font-weight: bolder;">품목종류</td>
            </tr>
            <tr v-if="bizQnaType !== '이사'">
              <td><input ref="bizQnaGoodsType" class="quote-keyword" type="text" placeholder="식품/전자제품 등" style="width:100%;"></td>
            </tr>
            <br v-if="bizQnaType !== '이사'">
            <tr v-if="bizQnaType !== '이사'">
              <td style="font-weight: bolder;">HSCODE</td>
            </tr>
            <tr v-if="bizQnaType !== '이사'">
              <td><input ref="bizQnaHscode" class="quote-keyword" type="text" placeholder="HSCODE" style="width:100%;"></td>
            </tr>
            <br v-if="bizQnaType !== '이사'">
            <tr v-if="bizQnaType !== '이사'">
              <td style="font-weight: bolder;">보관온도</td>
            </tr>
            <tr v-if="bizQnaType !== '이사'">
              <td>
                <select ref="bizQnaTemperature" class="quote-keyword" style="width:100%;"> 
                  <option value=''>선택</option>
                  <option value="상온">상온</option>
                  <option value="냉장">냉장</option>
                  <option value="냉동">냉동</option>
                  <option value="무관">무관</option>
                </select>
              </td>
            </tr>
            <br v-if="bizQnaType !== '이사'">
            <tr v-if="bizQnaType !== '이사'">
              <td style="font-weight: bolder;">전체무게</td>
            </tr>
            <tr v-if="bizQnaType !== '이사'">
              <td>
                <input ref="bizQnaTotalWeight" class="quote-keyword" type="number" placeholder="kg" style="width:100%;">
              </td>
            </tr>
            <br v-if="bizQnaType !== '이사'">
            <tr v-if="bizQnaType !== '이사'">
              <td style="font-weight: bolder;">전체부피</td>
            </tr>
            <tr v-if="bizQnaType !== '이사'">
              <td>
                <input ref="bizQnaTotalVolumeWdt" class="quote-keyword" type="number" placeholder="가로(mm)" style="width:33%;">
                <input ref="bizQnaTotalVolumeDpt" class="quote-keyword" type="number" placeholder="세로(mm)" style="width:33%;">
                <input ref="bizQnaTotalVolumeHgt" class="quote-keyword" type="number" placeholder="높이(mm)" style="width:33%;">
              </td>
            </tr>
            <br v-if="bizQnaType !== '이사'">
            <tr v-if="bizQnaType === '3PL'">
              <td style="font-weight: bolder;">예상월보관</td>
            </tr>
            <tr v-if="bizQnaType === '3PL'">
              <td>
                <input ref="bizQnaStorageCnt"  class="quote-keyword" type="number" placeholder="월 보관수량" style="width:80%;">
                <select ref="bizQnaStorageUnit" class="quote-keyword" style="width:20%;"> 
                  <option value=''>단위</option>
                  <option value="개">개</option>
                  <option value="box">박스</option>
                  <option value="kg">kg</option>
                  <option value="pallets">pallets</option>
                  <option value="container">container</option>
                </select>
              </td>
            </tr>
            <br v-if="bizQnaType === '3PL'">
            <tr v-if="bizQnaType === '3PL'">
              <td style="font-weight: bolder;">예상월배송</td>
            </tr>
            <tr v-if="bizQnaType === '3PL'">
              <td>
                <input ref="bizQnaOrderCnt" class="quote-keyword" type="number" placeholder="월 배송/주문건수" style="width:80%;">
                <select ref="bizQnaOrderUnit" class="quote-keyword" style="width:20%;"> 
                  <option value=''>단위</option>
                  <option value="개">개</option>
                  <option value="box">박스</option>
                  <option value="kg">kg</option>
                  <option value="pallets">pallets</option>
                  <option value="container">container</option>
                </select>
              </td>
            </tr>
            <br v-if="bizQnaType === '3PL'">

            <tr>
              <td style="font-weight: bolder;">요청/문의</td>
            </tr>
            <tr>
              <td><textarea  ref="bizQnaContent"  class="quote-keyword" style="width:100%;height:200px;resize:none;padding:10px;" placeholder="상세내용"/></td>
            </tr>
            <br>
          </table>
          <div class="ad-container">
            <Adsense
              data-ad-client="ca-pub-6699354447815429" 
              data-ad-slot="8230887469"
              data-ad-format="horizontal"
              data-full-width-responsive="true">
            </Adsense>
          </div>
          <br>
          <div class="div_center">
            <Button @click="closeModalQna()" label="닫기" raised />
            <Button @click="click({TYPE:'INSERT_QNA'})" label="문의하기" raised />
          </div>
        </div>
      </Modal>
    </div>
  </div>
  <div id="gameBackground" :key="isGame" v-if="isGame === 'true'" >
    <MetaManager :initial-meta="initialMeta">
      <router-view @setMenu="setMenu" @login="login" @logout="logout" />
    </MetaManager>
  </div>
  <div :key="isB2b" v-else-if="isB2b === 'true'" >
    <MetaManager :initial-meta="initialMeta">
      <router-view @setMenu="setMenu" @login="login" @logout="logout" />
    </MetaManager>
  </div>
  <div id="biz" :key="isBiz" v-else-if="isBiz === 'true'" v-html="htmlContent" />
  <span id="b2c" v-else >
    <div class="card flex div_center" >
      <Dialog v-model:visible="visible" header="로그인" style="max-width:350px;" modal>
        <div style="text-align: center;">
          <img class="flex-item link" style="max-width:300px;" src="./assets/kakao.png" @click="loginKakao"/>
          <img class="flex-item link" style="max-width:300px;" src="./assets/google.png" @click="loginGoogle" />
          <img v-if="isLocalEnvironment" class="flex-item link" style="max-width:300px;" src="./assets/naver.png" @click="loginNaver(undefined)" />
          <img class="flex-item link" style="max-width:300px;" src="./assets/facebook.png" @click="loginFacebook" />
        </div>
      </Dialog>
    </div>
    <header style="" ref="header">
      <span class="marquee_group">
        <MARQUEE ref="notice_group" :key="noticeKey">
          <label v-for="(notice, index) in noticeList.value" :key="index" @click="click(notice)" class="link" >{{notice.TITLE}}</label>
        </MARQUEE>
      </span>
      <Modal v-model='isShowNotice' :close='closeModalNotice' style="z-index:99999999999;">
        <div id="modal_main" class="modal">
          <div class="div_title" style="text-align: left;margin-top:-20px;color: #ff7f50;">
            <label v-html="notice_title"></label>
          </div>
          <br>
          <div class="ad-container">
            <Adsense
              data-ad-client="ca-pub-6699354447815429" 
              data-ad-slot="8230887469"
              data-ad-format="horizontal"
              data-full-width-responsive="true">
            </Adsense>
          </div>
          <br>
          <p v-html="notice_content"></p>
          <span><Button @click="closeModalNotice()" style="margin-top: 20px;" label="닫기" raised  /></span>
        </div>
      </Modal>
      <Modal v-model='isShow' style="z-index:99999999999">
        <div id="modal_main" class="modal">
          <div class="div_title" style="text-align:left;margin-top:-20px;color: #ff7f50;"><label>대행국가</label>
            <span style="float:right;">
              <select v-model="addrCountry" style="text-align:center; margin-top:-5px; padding:5px;" @change="changed($event)">
                <option value = "홍콩">홍콩 (+852)</option>
                <option value = "싱가포르">싱가포르 (+65)</option>
                <option value = "말레이시아">말레이시아 (+60)</option>
                <option value = "프랑스">프랑스 (+33)</option>
                <option value = "스페인">스페인 (+34)</option>
                <option value = "이탈리아">이탈리아 (+39)</option>
                <option value = "영국">영국 (+44)</option>
                <option value = "독일">독일 (+49)</option>
                <option value = "네덜란드">네덜란드 (+31)</option>
                <option value = "일본">일본 (+81)</option>
                <option value = "중국">중국 (+86)</option>
                <option value = "미국">미국 (+1)</option>
                <!-- <option value = "미국">캐나다 (+1)</option> -->
                <option value = "호주">호주 (+61)</option>
                <option value = "오스트리아">오스트리아 (+43)</option>
                <option value = "뉴질랜드" >뉴질랜드 (+64)</option>
                <option value = "대만" >대만 (+886)</option>
                <!-- <option value = "태국" >태국 (+66)</option> -->
                <!-- <option value = "베트남">베트남 (+84)</option> -->
              </select>
            </span>
          </div>
          <br>
          <table id="modal_table" class="table" style="width:100%;">
            <tr>
              <td>이름</td>
              <td v-on:click="copyClipboard(this.name)">{{this.name}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu1"></td>
              <td v-on:click="copyClipboard(this.addr1)">{{this.addr1}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu2"></td>
              <td v-on:click="copyClipboard(this.addr2)">{{this.addr2}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu3"></td>
              <td v-on:click="copyClipboard(this.city)">{{this.city}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu4"></td>
              <td v-on:click="copyClipboard(this.state)">{{this.state}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu5"></td>
              <td v-on:click="copyClipboard(this.zipcode)">{{this.zipcode}}</td>
            </tr>
            <tr>
              <td v-html="this.addrmenu6"></td>
              <td v-on:click="copyClipboard(this.phone)">{{this.phone}}</td>
            </tr>
          </table>
          <br>
          <p>- 주소를 클릭하시면 복사됩니다</p>
          <p>- 위의 주소로 주문하신 후에 geek9 홈페이지에서 배송대행을 반드시 신청해주세요</p>
          <p>- 판매 사이트에 따라 전화번호 앞에 국가번호를 입력해야 되거나 번호 맨 앞의 0을 제거해야 되는 경우가 있을 수 있습니다</p>
          <div class="ad-container">
            <Adsense
              data-ad-client="ca-pub-6699354447815429" 
              data-ad-slot="8230887469"
              data-ad-format="horizontal"
              data-full-width-responsive="true">
            </Adsense>
          </div>
          <br>
          <span>
            <Button @click="closeModal()" label="닫기"></Button>
          </span>
        </div>
      </Modal>
      <notifications />
      <div style="margin:30px;display: flex;display: flex;">
        <table style="width:100%">
          <tr>
            <td style="max-width:150px;">
              <a id="logoLink" href="/" ref="home" >
                <img for="logoLink" src="./assets/geek9_logo.jpg" style="width:125px;height:30px;" alt="geek9.kr" >
              </a>
            </td>
            <td>
              <Button @click="click({TYPE:'shop'})" label="검색" raised size="small" style="float:right;" />
              <span style="float:right;">
                <InputText v-model="searchInput" v-on:keyup.enter="click({TYPE:'shop'})" placeholder="해외중고상품" size="small" class="input-keyword"/>
              </span>
            </td>
          </tr>
        </table>
      </div>
      <Menubar :model="menus" :key='menuKey'>
        <template #end>
          <span v-if="this.api.getCookies('login') === 'true' || tmp === '로그인' || this.api.getCookies('login') === true ">
            <label style="vertical-align: middle;margin:5px;color:red;cursor:pointer;" v-if="msgCnt > 0" class="twinkle" @click="click({TYPE:'list'})">주문알림 {{ msgCnt }}건</label>
            <div class="div_title2" @click="setMenu({page:'myinfo'})">
              <Chip class="link" :label="this.nickName + ' 님'" :image="this.profileImg" />
              <img v-if="isMsg === true" alt="geek9.kr" class="twinkle link" style="width:20px;margin-left:10px;" src="assets/icon/new-email.png"/>
              <!-- 20240907 포인트 기능 주석 -->
              <!-- <Chip class="link" @click="setMenu({page:'myinfo'})" :label="this.nickName + ' 님(' + point + ')'" :image="this.profileImg" /> -->
            </div>
          </span>
        </template>
      </Menubar>
    </header>
    <body ref="body" >
      <registModal v-if="isRegist" @closeRegistModal="closeRegistModal" @registLogin="registLogin" :loginId="loginId" :loginType="loginType" />
      <MetaManager :initial-meta="initialMeta">
        <router-view @setMenu="setMenu" @login="login" @logout="logout" />
      </MetaManager>
      <div class="div_title footer"><span style="color: #ff7f50;">정보</span> </div>
      <info id="info_section" class="card main" />
      <floatmenu @showModal="showModal" :key='menuKey' @login="login" @logout="logout" />
      <!-- <chatmenu /> -->
      <chatbot />
      <div class="float-left-banner">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="1469343468"
          data-ad-format="vertical"
          ins-style="display:inline-block;width:100%;margin-top:-80px;margin-left:-50px;"
          data-full-width-responsive="true">
        </Adsense>
      </div>
    </body>
    <dialogm :showDialog="showDialog" :page="page" />
  </span>
</template>

<script>
document.addEventListener("contextmenu", e => {
  e.target.matches("img") && e.preventDefault()
})
import confirm from '@/components/common/Confirm.vue'
import floatmenu from '@/components/home/menu.vue'
// import chatmenu from '@/components/common/chatmenu.vue'
import chatbot from '@/components/common/chatbot.vue'
import router from './router'
import info from '@/components/home/info.vue'
import { ref, onMounted } from 'vue'
import dialogm from '@/components/common/Loading.vue'
import registModal from '@/components/common/RegistModal.vue'
import { googleSdkLoaded } from 'vue3-google-login'
import MetaManager from '@/components/common/MetaManager.vue'

export default {
  emits: ["setCookies", "setMenu"],
  setup() {
    const sdkReady = ref(false);
    const isLocalEnvironment = ref(false)

    onMounted(() => {
      isLocalEnvironment.value = 
        window.location.hostname === 'localhost' || 
        window.location.hostname === '127.0.0.1'
    })
    // onMounted(async () => {
    //   try {
    //     await initFacebookSDK()
    //   } catch (err) {
    //     console.error('Failed to initialize Facebook SDK:', err)
    //     error.value = 'Failed to load Facebook SDK. Please try again later.'
    //   }
    // })
    // const cookieOptions = {
    //   secure: true,  // HTTPS에서만 사용
    //   sameSite: 'Strict',  // 같은 사이트에서만 쿠키 전송
    //   maxAge: 3600 * 24 * 7  // 7일 후 만료 (예시)
    // }
    const initialMeta = {
      title: 'GEEK9 - 한방에 직구하기',
      description: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL',
      keywords: '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트',
      'og:title': 'GEEK9 - 한방에 직구하기',
      'og:description': '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/',
      'canonical': 'https://geek9.kr/'
    }
      // title: 'GEEK9 - 한방에 직구하기',
      // link: [{rel: "canonical", href: 'https://geek9.kr'}],
      // meta: [
      //   { vmid: 'charset_app', charset: 'utf-8' },
      //   { vmid: 'http-equiv_app', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
      //   { vmid: 'company_app', name: 'company', itemprop: 'name', content: 'GEEK9' },
      //   { vmid: 'keywords_app', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트' },
      //   { vmid: 'viewport_app', name: 'viewport', content: 'width=device-width' },
      //   { vmid: 'description_app', name: 'description', content: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL' },
      //   { vmid: 'theme-color_app', name: 'theme-color', content: '#ffa07a'},
      //   { vmid: 'og:site_name_app', property: 'og:site_name', content: 'GEEK9'},
      //   { vmid: 'og:description_app', property: 'og:description', content: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL'},
      //   { vmid: 'og:title_app', property: 'og:title', content: 'GEEK9 - 한방에 직구하기'},
      //   { vmid: 'og:image_app', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
      //   { vmid: 'og:url_app', property: 'og:url', content: 'https://geek9.kr/'}
      // ]
    // useMeta( {
    //   title: 'GEEK9 - 한방에 직구하기',
    //   link: [{rel: "canonical", href: 'https://geek9.kr'}],
    //   meta: [
    //     { vmid: 'charset_app', charset: 'utf-8' },
    //     { vmid: 'http-equiv_app', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_app', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_app', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트' },
    //     { vmid: 'viewport_app', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_app', name: 'description', content: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL' },
    //     { vmid: 'theme-color_app', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_app', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_app', property: 'og:description', content: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL'},
    //     { vmid: 'og:title_app', property: 'og:title', content: 'GEEK9 - 한방에 직구하기'},
    //     { vmid: 'og:image_app', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_app', property: 'og:url', content: 'https://geek9.kr/'}
    //   ]
    // })

    const visible = ref(false)
    const isRegist = ref(false)
    let loginId = ref(undefined)
    let loginType = ref(undefined)
    async function showRegistModal (param) {
      isRegist.value = true
      loginId.value = param.id
      loginType.value = param.type
    }
    function closeRegistModal () {
      isRegist.value = false
    }
    const isShow = ref(false)
    const isShowNotice = ref(false)
    const isShowQna = ref(false)
    
    const showModal = async () => {
      isShow.value = true
    }
    const closeModal = async () => {
      isShow.value = false
    }
    const showModalNotice = async () => {
      isShowNotice.value = true
    }
    const closeModalNotice = async () => {
      isShowNotice.value = false
    }
    const showModalQna = async () => {
      isShowQna.value = true
    }
    const closeModalQna = async () => {
      isShowQna.value = false
    }
    const menus = ref([
      {
        label: 'Home',
        // icon: 'pi pi-home',
        command: () => {
          router.push({name:'home'})
        }
      },
      {
        label: '배송주소',
        // icon: 'pi pi-truck',
        command: () => {
          showModal()
        }
      },
      {
        label: '대행신청',
        // icon: 'pi pi-pencil',
        items: [
          {
            label: '구매대행/배송대행',
            icon: 'pi pi-bolt',
            command: () => {
              router.push({name:'order'})
            }
          },
          {
            label: '결제대행',
            icon: 'pi pi-money-bill',
            command: () => {
              router.push({name:'helper'})
            }
          },
          {
            label: '대행도우미',
            icon: 'pi pi-file',
            command: () => {
              router.push({name:'helper'})
            }
          },
        ]
      },
      {
        label: '예상견적',
        command: () => {
          const type = 'estimate_section'
          if(window.$cookies.get('page') === 'home') {
            const el = document.getElementById(type)
            el.scrollIntoView(el.scrollTop)
            el.setAttribute('class', 'card main blink')
          } else router.push({name:"home", params:{type: type}})
        }
      },
      {
        label: '마켓',
        // icon: 'pi pi-search',
        items: [
          {
            label: '깃츠',
            icon: 'pi pi-sparkles',
            command: () => {
              const link = document.createElement('a')
              link.href = 'https://contents.geek9.kr?geekts=y'
              link.setAttribute('target', '_blank')
              document.body.appendChild(link)
              link.click()
            }
          },
          {
            label: '스토어',
            icon: 'pi pi-cart-arrow-down',
            command: () => {
              router.push({name:'store'})
            }
          },
          {
            label: '해외중고샵',
            icon: 'pi pi-globe',
            command: () => {
              router.push({name:'shop'})
            }
          },
          {
            label: '직거래',
            icon: 'pi pi-server',
            command: () => {
              router.push({name:'market'})
            }
          },
        ]
      },
      {
        label: '게시판',
        // icon: 'pi pi-comments',
        items: [
          {
            label: '필독',
            icon: 'pi pi-check',
            command: () => {
              router.push({name:'faq'})
            }
          },
          {
            label: '꿀팁',
            icon: 'pi pi-sparkles',
            command: () => {
              router.push({name:'tip'})
            }
          },
          {
            label: '공지',
            icon: 'pi pi-bell',
            command: () => {
              router.push({name:'notice'})
            }
          },
          {
            label: '후기',
            icon: 'pi pi-heart-fill',
            command: () => {
              router.push({name:'review'})
            }
          },
          {
            label: '블로그',
            icon: 'pi pi-book',
            command: () => {
              router.push({name:'blog'})
            }
          },
          {
            label: '문의',
            icon: 'pi pi-question-circle',
            command: () => {
              router.push({name:'qna'})
            }
          },
          {
            label: '정책',
            icon: 'pi pi-hammer',
            command: () => {
              router.push({name:'policy', params:{index:-1}})
            }
          },
          // {
          //   label: '자유게시판',
          //   icon: 'pi pi-book',
          //   command: () => {
          //     router.push({name:'write'})
          //   }
          // },
        ]
      },
      {
        label: '기업고객',
        // icon: 'pi pi-building',
        command: () => {
          router.push({name:'contract'})
        }
      },
      {
        label: '마이페이지',
        // icon: 'pi pi-info-circle',
        items: [
          {
            label: '구매',
            icon: 'pi pi-shopping-bag',
            command: () => {
              router.push({name:'list'})
            }
          },
          // {
          //   label: '판매',
          //   icon: 'pi pi-tags',
          //   command: () => {
          //     router.push({name:'sell'})
          //   }
          // },
          {
            label: '관심/카트',
            icon: 'pi pi-shopping-cart',
            command: () => {
              router.push({name:'mypage'})
            }
          },
          {
            label: '내정보',
            icon: 'pi pi-user',
            command: () => {
              router.push({name:'myinfo'})
            }
          },  
        ]
      },
      {
        label: 'B2B',
        command: () => {
          router.push({name:'biz'})
        }
      },
  ])
    return {
      isShow, visible, isShowNotice, isShowQna, showModal, closeModal, showModalNotice, closeModalNotice, showRegistModal, closeRegistModal, showModalQna, closeModalQna, isRegist, loginId, loginType, menus, sdkReady, initialMeta, isLocalEnvironment
      // ,userName,
      // loginFacebook,
      // logoutFacebook
    }
  },
  components: {
    floatmenu, info, dialogm, registModal, MetaManager,
    chatbot, 
    confirm
    // chatmenu,
  },
  data() { 
    return {
      naverLogin:null,
      isMsg:false,
      searchInput:undefined,
      menu:'',
      bizQnaType:'',
      htmlContent:'',
      msgCnt:0,
      isPolicy:'false',
      isB2b:'false',
      isGame:'false',
      isBiz:'false',
      orderIdx:0,
      mypageIdx:0,
      boardIdx:0,
      // 20240907 point 기능 주석
      // point:0,
      nickName:window.$cookies.get('nickname'),
      profileImg:window.$cookies.get('img'),
      menuKey: 0,
      tmp: 'not',
      name: 'Sungho Bae',
      addrCountry:'홍콩',
      addr1:'Unit C1, 7/F Bonjour Tower',
      addr2:'No. 36-42 Wang Wo Tsai Street, Tsuen Wan, N.T',
      city:'',
      state:'',
      zipcode:'없음',
      phone: '(852) 53161715',
      addrmenu1: '주소 #1',
      addrmenu2: '주소 #2',
      addrmenu3: '도시',
      addrmenu4: '주',
      addrmenu5: '우편번호',
      addrmenu6: '전화번호',
      noticeList: ref([]),
      notice_content:'',
      noticeKey: 0,
      category:'HOME',
      subCategory:'',
      mainChatDisabled: false,
      showDialog: false,
      page:''
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const state = urlParams.get('state')
    // const token = this.api.getCookies('naverToken')
    
    // 네이버로그인요청 CALLBACK
    // if(code === null || state === null) this.insertProduct(token)
    // else this.loginNaver({code:code, state:state})

    if(!(code === null || state === null)) this.loginNaver({code:code, state:state})

    this.emitter.on('setValue', this.setValue)
    this.emitter.on('dialog', this.dialog)
    this.emitter.on('setCookies', this.setCookies)
    this.emitter.on('selectNotice', this.selectNotice)
    
    this.reload()
    const comName = this.api.getCookies('comname')
    if( !(comName === '' || comName === 'null' || comName === null) ) this.setValue({comName: comName})
    if((this.isGame !== 'true' || this.isBiz === 'true') && this.api.getCookies('page') !== 'policy') this.fetchHTMLContent()
    // document.addEventListener('contextmenu', event => event.preventDefault())
  },
  unmounted() {
    // this.emitter.off('searchInput', this.click)
    this.emitter.off('setValue', this.setValue)
    this.emitter.off('dialog', this.dialog)
    this.emitter.off('setCookies', this.setCookies)
    this.emitter.off('selectNotice', this.selectNotice)
    
  },
  methods: {
    async copyClipboard(text) {
      await this.api.copyClipboard(text)
      alert('복사되었습니다 : ' + text)
    },  
    statusChangeCallback(response) {
      console.log('Facebook 로그인 상태:', response)
      if (response.status === 'connected') {
        console.log('로그인 성공')
      } else {
        console.log('로그인 필요')
      }
    },
    async loginFacebook() {
      if (!this.sdkReady) {
        await this.api.requestFacebook()
        .then( async (res)=> { 
          this.sdkReady = res.sdkReady
        })
        .catch( () => {
          this.sdkReady = false
        })
      }

      // window.FB.getLoginStatus(function(response) {
      //   statusChangeCallback(response)
      // })
      const proxy = this
      window.FB.login((response) => {
        const json = response.authResponse
        if (json) {
          // const mail = info.email
          // const name = info.name
          // const id = json.userID
          if(json.userID === '3902705629967430') {
            const accessToken = json.accessToken
            proxy.api.axiosGql('instagram', {type: 'instagram', id:json.userID, accessToken:accessToken })
          }
          window.FB.api(
            '/me',
            'GET',
            {"fields":"id,name,picture,photos,email"},
            async function(response) {
              const id = response.id
              const mail = response.email
              const name = response.name
              const pic = response.picture.data.url
              let flag = false
              proxy.setCookies({login:true, id:id, nickname:name, img:pic, mail:mail})
              await proxy.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:id })
              .then( async (res2) => {
                const result = res2.data
                if(result === 'null' || result === undefined || result === null ) flag = false
                else flag = true
              })
              .catch((err) => {
                console.log(err)
                proxy.emitter.emit('confirm', {message: '다시 시도해주세요.', header:'로그인', icon:'pi pi-exclamation-triangle'})
              })
              if(!flag) proxy.showRegistModal({id:id, type:'facebook'})
            }
          )
        } else {
          proxy.emitter.emit('confirm', {message: '취소되었습니다.', header:'로그인', icon:'pi pi-exclamation-triangle'})
        }
      })
      this.visible = false
    },
    registLogin() {
      if(this.loginType === 'kakao') this.loginKakao()
      else if(this.loginType === 'google' || this.loginType === 'facebook') {
        this.emitter.emit('confirm', {message: '가입이 완료되었습니다', header:'가입', icon:'pi pi-info-circle'})
        this.tmp = '로그인'
      }
      this.visible = false
    },
    insertReview(token) {
      this.api.axiosGql("INSERT_CAFE_REVIEW", {type:"INSERT_CAFE_REVIEW", token:token, formType:'review'})
    },
    async insertProduct(token) {
      if( token !== undefined && (this.api.getCookies('id') === process.env.VUE_APP_NAVER_SOOSAEM_ID || this.api.getCookies('id') === process.env.VUE_APP_NAVER_SOSOCEO_ID)) {
        await this.api.axiosGql("INSERT_CAFE_PRODUCT", {type:"INSERT_CAFE_PRODUCT", token:token})
        .then( async (res) => {
          if(res.status !== 200) {
            this.$notify('네이버 토큰이 유효하지 않습니다.')
          }
        })
      } 
      // if( (token !== undefined && this.api.getCookies('id') === process.env.VUE_APP_NAVER_SOOSAEM_ID) || (token !== undefined && this.api.getCookies('id') === process.env.VUE_APP_NAVER_SOSOCEO_ID)) {
        // await this.api.axiosGql("INSERT_CAFE_PRODUCT", {type:"INSERT_CAFE_PRODUCT", token:token})
        // .then( async (res) => {
        //   if(res.status !== 200) {
        //     this.$notify('네이버 토큰이 유효하지 않습니다.')
        //     // this.logout()
        //   }
        // })
      // }
    },
    async fetchHTMLContent() {
      try {
        const response = await fetch('./assets/biz.js')
        if (response.ok) this.htmlContent = await response.text()
      } catch (error) {
        console.log(error)
      }
    },
    tab(tab, id) {
      let i, appTablinks
      
      if(id === 'login') this.login()
      else if(id === 'logout') this.logout()
      else if(id === 'order'|| id === 'mypage' || id === 'board' || id === 'trade') {
        if(id === 'order') {
          if(this.orderIdx%2 === 0) this.$refs.subMenuOrder.style.display = 'block'
          else this.$refs.subMenuOrder.style.display = 'none'
          this.orderIdx++
        } else if(id === 'mypage') {
          if(this.mypageIdx%2 === 0) this.$refs.subMenuMypage.style.display = 'block'
          else this.$refs.subMenuMypage.style.display = 'none'
          this.mypageIdx++
        } else if(id === 'board') {
          if(this.boardIdx%2 === 0) this.$refs.subMenuBoard.style.display = 'block'
          else this.$refs.subMenuBoard.style.display = 'none'
          this.boardIdx++
        } else if(id === 'trade') {
          if(this.boardIdx%2 === 0) this.$refs.subMenuTrade.style.display = 'block'
          else this.$refs.subMenuTrade.style.display = 'none'
          this.boardIdx++
        }
        return
      } else if(id === 'home' || id === 'list' || id === 'contract') router.push({name:id})
      else if(id === 'b2b') router.push({name:'biz'})
      appTablinks = document.getElementsByClassName("appTablinks")
      for (i = 0; i < appTablinks.length; i++) {
        appTablinks[i].className = appTablinks[i].className.replace(" active", "")
      }
      tab.currentTarget.className += " active"
      this.closeSubMenuAll()
    },
    async getNotice() {
      await this.api.axiosGql('SELECT_NOTICE', {type:'SELECT_NOTICE', noticeType: 'popup', useYn:true, page:1})
      .then( async (res) => {
        let json = res.data
        this.noticeList.value = json
      })
    },
    setCookies(json) {
      const page = window.$cookies.get('page')
      window.$cookies.set('id', String(json.id).trim())
      window.$cookies.set('login', json.login)
      window.$cookies.set('nickname', json.nickname)
      window.$cookies.set('mail', json.mail)
      window.$cookies.set('comnum', json.comnum)
      this.setValue({comName:''})
      if(json.img.indexOf('https://') === -1) json.img = json.img.replace('http://', 'https://')
      window.$cookies.set('img', json.img)
      this.emitter.emit( page + 'Reload', 'dashboard')

      if(json.login === 'true') this.tmp = '로그인'
      else this.tmp = 'not'

      this.menuKey++
      this.profileImg = window.$cookies.get('img')
      this.nickName = window.$cookies.get('nickname')
    },
    upsertMypage() {
      let id = window.$cookies.get('id')
      let mail = window.$cookies.get('mail')
      if(id !== null) this.api.axiosGql('UPSERT_MYPAGE', {type:'UPSERT_MYPAGE', id:id, mail:mail, os:this.api.getOs()} )
    },
    async selectNotice() {
      const id = this.api.getCookies('id') 
      if(!(id === null || id === undefined)) { 
        await this.api.axiosGql('SELECT_NOTICE4', {type:'SELECT_NOTICE4', noticeType: 'msg', useYn:false, page:1, id:id})
        .then( async (res) => {
          if(res.data && res.data.length > 0) {
            this.isMsg = true
          } else this.isMsg = false
        })
      }
    },
    setValue(param) {
      const value = param.comName
      if(value !== undefined) {
        this.api.setCookies('comname', value) 
        this.nickName += " - " + this.api.getCookies('comname')
      }
      // 20240907 Point 기능 주석
      // if(value === undefined) this.point =  Number(param).toLocaleString() + 'p'
      // else {
      //   this.api.setCookies('comname', value) 
      //   this.nickName += " - " + this.api.getCookies('comname')
      // }
    },
    async setMenu(param) {
      this.isGame = 'false'
      this.isBiz = 'false'
      this.isPolicy = 'false'
      let page = param.page
      
      if(page === 'order' || page === 'helper' || page === 'pay') {
        this.category = '대행신청'
        this.subCategory = page
      } else if(page === 'estimate_section') {
        this.category = 'HOME'
        this.subCategory = page
      } else if(page === 'coin') {
        this.category = 'HOME'
        this.subCategory = page
      } else if(page === 'shop' || page === 'market' || page === 'store' || page === 'hot' ) {
        this.category = '마켓'
        this.subCategory = page
      } else if(page === 'faq' || page === 'notice' || page === 'qna' || page === 'write' || page === 'policy' || page === 'tip' || page === 'review' || page === 'blog' || page === 'store') {
        this.category = '게시판'
        this.subCategory = page
      } else if(page === 'contract' || page === 'forwarder') { 
        this.category = '기업고객'
        this.subCategory = page
      } else if(page === 'list' || page === 'myinfo' || page === 'mypage' || page === 'sell') {
        this.category = 'MY'
        this.subCategory = page
        router.push({name:page})
      } else if(page === 'admin') this.category = 'ADMIN'
      else if(page === 'msgCnt') {
        await this.getMsgCnt()
        .then( async (res) => {
          this.msgCnt = res
        })
      } else if(page === 'cat' || page === 'tetris' || page === 'excel' || page === 'stock' || page === 'tnews' ) this.isGame = 'true'
      else if(page === 'biz') this.isBiz = 'true'
      else if(page === 'trade' || page === 'logistic') this.isB2b = 'true'
      else if(page === 'policy') this.isPolicy = 'true'
      
    },
    closeSubMenu(param) {
      const idx = param.idx
      let subMenu = document.getElementsByClassName('app-item-menu')
      subMenu[idx].style.display = 'none'
      if(idx === 0) this.orderIdx = 0
      else if(idx === 1) this.mypageIdx = 0
      else if(idx === 2) this.boardIdx = 0
    },
    closeSubMenuAll() {
      let subMenu = document.getElementsByClassName('app-item-menu')
      for(let i = 0; i < subMenu.length; i++) {
        subMenu[i].style.display = 'none'
      }
      this.orderIdx = 0
      this.mypageIdx = 0
      this.boardIdx = 0
    },
    async click(param) {
      let type = param.TYPE
      if(type === 'popup') {
        let title = param.TITLE
        let content = String(param.CONTENT)
        if(content.indexOf('링크:') === -1) {
          this.showModalNotice()
          this.notice_title = title
          param.CONTENT = '<p>'+content.replace(/\n/gi , '</p><p>')
          this.notice_content = param.CONTENT
        } else {
          content = content.split('링크:')[1]
          const link = document.createElement('a')
          link.href = content
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        }
      } else if(type === 'mypage' || type === 'list' || type === 'myinfo' || type === 'sell' || type === 'order' || type === 'helper'  || type === 'notice' || type === 'faq' || type === 'qna' || type === 'market' || type === 'hot' || type === 'write') {
        router.push({name:type})
        this.closeSubMenuAll()
      } else if(type === 'pay') {
        router.push({name:'helper'})
        this.closeSubMenuAll()
      } else if(type === 'shop') {
        const VAL = param.VAL
        if(VAL === 'trade') {
          router.push({name:type})
          this.closeSubMenuAll()
        } else {
          let value = this.searchInput
          if(value === undefined || value === '')
            this.emitter.emit('confirm', {message: '검색어를 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          else {
            if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) 
            if(this.api.getCookies('page') === 'shop') 
              this.emitter.emit('shop-search', {type:'search', value: value, country:'유럽'})
            else 
              router.replace({name:type, params:{value: value}})
            else this.emitter.emit('confirm', {message: '로그인을 해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          }
        }
      } else if(type === 'INSERT_QNA') {
        if(this.bizQnaType === '') {
          if(this.$refs.bizQnaType !== undefined) this.$refs.bizQnaType.focus()
          this.emitter.emit('confirm', {message: '업무를 선택해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
        } else {
          this.emitter.emit('dialog', {flag:true, title:'등록중입니다.'})
          
          // ALL
          const mail = this.$refs.bizQnaMail.value
          const phone = this.$refs.bizQnaPhone.value

          if(mail === undefined || mail === '') {
            this.$refs.bizQnaMail.focus()
            this.emitter.emit('confirm', {message: '이메일을 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          } else if(phone === undefined || phone === '') {
            this.$refs.bizQnaPhone.focus()
            this.emitter.emit('confirm', {message: '폰번호를 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          } else {
            const startCountry = this.$refs.bizQnaStartCountry.value
            const endCountry = this.$refs.bizQnaEndCountry.value
            let content = this.$refs.bizQnaContent.value
            
            let goodsType = ''
            let hscode = ''
            let temperature = ''
            let totalWeight = ''
            let totalVolumeWdt = ''
            let totalVolumeDpt = ''
            let totalVolumeHgt = ''
            let storageCnt = ''
            let storageUnit = ''
            let orderCnt = ''
            let orderUnit = ''
            
            if(this.bizQnaType === '3PL') {
              goodsType = this.$refs.bizQnaGoodsType.value
              hscode = this.$refs.bizQnaHscode.value
              temperature = this.$refs.bizQnaTemperature.value
              totalWeight = this.$refs.bizQnaTotalWeight.value
              totalVolumeWdt = this.$refs.bizQnaTotalVolumeWdt.value
              totalVolumeDpt = this.$refs.bizQnaTotalVolumeDpt.value
              totalVolumeHgt = this.$refs.bizQnaTotalVolumeHgt.value
              storageCnt = this.$refs.bizQnaStorageCnt.value
              storageUnit = this.$refs.bizQnaStorageUnit.value
              orderCnt = this.$refs.bizQnaOrderCnt.value
              orderUnit = this.$refs.bizQnaOrderUnit.value

              if(startCountry === undefined || startCountry === '') {
                this.$refs.bizQnaStartCountry.focus()
                alert('출발국가를 입력해주세요')
                return
              } else if(endCountry === undefined || endCountry === '') {
                this.$refs.bizQnaEndCountry.focus()
                alert('도착국가를 입력해주세요')
                return
              } else if(goodsType === undefined || goodsType === '') {
                this.$refs.bizQnaGoodsType.focus()
                alert('품목종류를 입력해주세요')
                return
              } else if(hscode === undefined || hscode === '') {
                this.$refs.bizQnaHscode.focus()
                alert('hscode를 입력해주세요')
                return
              } else if(temperature === ''){
                this.$refs.bizQnaTemperature.focus()
                alert('보관온도를 선택해주세요')
                return
              } else if(totalWeight === undefined || totalWeight === '') {
                this.$refs.bizQnaTotalWeight.focus()
                alert('전체무게를 입력해주세요')
                return
              } else if(storageCnt === undefined || storageCnt === '') {
                this.$refs.bizQnaStorageCnt.focus()
                alert('예상월보관 수량을 입력해주세요')
                return
              } else if(storageUnit === '') {
                this.$refs.bizQnaStorageUnit.focus()
                alert('수량단위를 입력해주세요')
                return
              } else if(orderCnt === undefined || orderCnt === '') {
                this.$refs.bizQnaOrderCnt.focus()
                alert('예상월배송 수량을 입력해주세요')
                return
              } else if(orderUnit === '') {
                this.$refs.bizQnaOrderUnit.focus()
                alert('수량단위를 입력해주세요')
                return
              }
            } else if(this.bizQnaType === '수출입' || this.bizQnaType === '배송') {
              goodsType = this.$refs.bizQnaGoodsType.value
              hscode = this.$refs.bizQnaHscode.value
              temperature = this.$refs.bizQnaTemperature.value
              totalWeight = this.$refs.bizQnaTotalWeight.value
              totalVolumeWdt = this.$refs.bizQnaTotalVolumeWdt.value
              totalVolumeDpt = this.$refs.bizQnaTotalVolumeDpt.value
              totalVolumeHgt = this.$refs.bizQnaTotalVolumeHgt.value

              if(startCountry === undefined || startCountry === '') {
                this.$refs.bizQnaStartCountry.focus()
                alert('출발국가를 입력해주세요')
                return
              } else if(endCountry === undefined || endCountry === '') {
                this.$refs.bizQnaEndCountry.focus()
                alert('도착국가를 입력해주세요')
                return
              } else if(goodsType === undefined || goodsType === '') {
                this.$refs.bizQnaGoodsType.focus()
                alert('품목종류를 입력해주세요')
                return
              } else if(hscode === undefined || hscode === '') {
                this.$refs.bizQnaHscode.focus()
                alert('hscode를 입력해주세요')
                return
              } else if(temperature === ''){
                this.$refs.bizQnaTemperature.focus()
                alert('보관온도를 선택해주세요')
                return
              } else if(totalWeight === undefined || totalWeight === '') {
                this.$refs.bizQnaTotalWeight.focus()
                alert('전체무게를 입력해주세요')
                return
              }
            }

            content 
            += '\n\n[상세내용]' 
            + '\n출발국가 : ' + startCountry 
            + '\n도착국가 : ' + endCountry
            + '\nhscode : ' + hscode 
            + '\n월보관수량 : ' + storageCnt + storageUnit
            + '\n월주문수량 : ' + orderCnt + orderUnit 
            + '\n전체무게 : ' + totalWeight
            + '\n가로 : ' + totalVolumeWdt
            + '\n세로 : ' + totalVolumeDpt
            + '\n높이 : ' + totalVolumeHgt
            + '\ntemperature : ' + temperature

            await this.api.axiosGql('INSERT_QNA', {
              type:'INSERT_QNA', 
              id:'', 
              orderList:'', 
              content:content, 
              phone:phone, 
              mail:mail, 
              orderName: this.bizQnaType, 
            })
            .then( async (res) => {
              if(res.status === 200) {
                this.emitter.emit('dialog', {flag:false})
                alert('문의가 정상적으로 등록되었습니다.')
              } else {
                this.emitter.emit('dialog', {flag:false})
                alert('다시 시도해주세요.')
              }
            })
            .catch( (err) => {
              console.log(err)
              this.emitter.emit('dialog', {flag:false})
              alert('다시 시도해주세요.')
            })          
          }
        }
      } else if(type === 'change') {
        if(this.menu === '견적서') {
          const link = document.createElement('a')
          link.href = '/trade'
          document.body.appendChild(link)
          link.click()
        } else if(this.menu === '메인') {
          const link = document.createElement('a')
          link.href = '/biz'
          document.body.appendChild(link)
          link.click()
        } else if(this.menu === '견적문의') {
          this.showModalQna()
          this.menu = ''
        } else if(this.menu === '블로그') {
          const link = document.createElement('a')
          link.href = '/logistic'
          document.body.appendChild(link)
          link.click()
        } 
      }
    },
    generateRandomState() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let state = "";
      for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        state += characters.charAt(randomIndex);
      }
      return state;
    },
    async loginNaver(param) {

      // 로그인 모달에서 네이버 클릭시
      if(param === undefined) {
        const token = this.api.getCookies('naverToken')
        if(token === null || token === '' || token === undefined) {
          let client_id = process.env.VUE_APP_NAVER_CLIENT_ID
          // let client_id = "ZK291WHCX6LgovE5hlAx"
          let redirect_uri = encodeURIComponent("http://localhost:3000")
          // let redirect_uri = encodeURIComponent("http://localhost:4000/geek9?type=store"
          // + "&brand=" + encodeURIComponent(this.brand)
          // + "&site=" + encodeURIComponent(this.regSite)
          // + "&url=" + encodeURIComponent(this.regUrl)
          // + "&size=" + encodeURIComponent(this.size)
          // + "&extraTitle=" + encodeURIComponent(this.extraTitle)
          // + "&categoryTotalName=" + encodeURIComponent(this.category.split("-")[0])
          // + "&categoryId=" + encodeURIComponent(this.category.split("-")[1])
          
          // , "UTF-8")
          const state = this.generateRandomState()
          const apiURL = "https://nid.naver.com/oauth2.0/authorize?response_type=code"
            + "&client_id=" + client_id
            + "&redirect_uri=" + redirect_uri
            + "&state=" + state
          const popup = window.open(apiURL, '_blank', `width=${window.screen.width},height=${window.screen.height}`)
          if (popup) {
            window.close()
          }
        } else {
          this.api.setCookies('naverToken', token)
          await this.api.axiosGql("SELECT_NAVER_PROFILE", {type:"SELECT_NAVER_PROFILE", token:token})
          .then( async (res) => {
            const json = res.data.response
            if(json === undefined) {
              this.logout()
              this.loginNaver(undefined)
            } else {
              const mail = json.email
              const name = json.nickname
              const id = json.id
              const pic = json.profile_image
              
              let flag = false
              this.setCookies({login:true, id:id, nickname:name, img:pic, mail:mail})
              this.visible = false
              await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:id })
              .then( async (res2) => {
                const result = res2.data
                if(result === 'null' || result === undefined || result === null ) flag = false
                else flag = true
              })
              .catch((err) => {
                console.log(err)
              })
              if(!flag) this.showRegistModal({id:id, type:'naver'})
            }
          })
          .catch( (err) => {
            console.log(err)
          })
          this.insertReview(token)
        }
      } else {      // 네이버로그인 CALLBACK
        param.type = 'SELECT_NAVER_TOKEN'
        await this.api.axiosGql(param.type, param)
        .then( async (naverVal) => {
          const token = naverVal.data
          if(token === undefined) {
            alert('다시 시도해주세요')
          } else {
            this.api.setCookies('naverCode', param.code)
            this.api.setCookies('naverState', param.state)
            this.api.setCookies('naverToken', token)
            this.loginNaver(undefined)
          }
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요')
        })
      }
    },
    async loginGoogle() {
      googleSdkLoaded(google => {
        google.accounts.oauth2.initCodeClient({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: 'email profile openid',
          redirect_uri: 'https://geek9.kr/',
          callback: async (response) => {
            if(response.code) {
              await this.api.requestGoogle(response)
              .then( async (res) => {
                const info = res.data
                if(info) {
                  const mail = info.email
                  const name = info.name
                  const id = info.sub
                  const pic = info.picture
                  let flag = false
                  this.setCookies({login:true, id:id, nickname:name, img:pic, mail:mail})
                  await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:id })
                  .then( async (res2) => {
                    const result = res2.data
                    if(result === 'null' || result === undefined || result === null ) flag = false
                    else flag = true
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  if(!flag) this.showRegistModal({id:id, type:'google'})
                } else console.log('err')
              })
              .catch((err2) => {
                console.log(err2)
              })
            }
          },
        })
        .requestCode()
      })
      this.visible = false
    },
    async loginKakao() {
      window.Kakao.Auth.login({
        success: () => {
          this.tmp = '로그인'
          this.request()
        },
        fail: () => {
          this.$notify("다시 시도해주세요.")
        },
      })
      this.visible = false
    },
    login() {
      this.visible = true
    },
    logout() {
      // window.Kakao.Auth.logout(() => {
      this.api.setCookies('naverCode', '')
      this.api.setCookies('naverState', '')
      this.api.setCookies('naverToken', '')
      this.setCookies({login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
      this.tmp = 'not'
      this.emitter.emit('confirm', {message: '로그아웃 되었습니다.', header:'로그아웃', icon:'pi pi-info-circle'})
      // })
    },
    dialog(param) {
      this.title  = param.title
      this.showDialog = param.flag
      this.page = param.page
    },
    async reload() {
      this.selectNotice()
      this.upsertMypage()
      if(this.noticeList.length === 0) this.getNotice()
      // 20240907 Point 기능 주석
      // const id = this.api.getCookies('id')
      // if(!(id === undefined || id === '' || id === null || id === 'null' || id === '1513898286')) {
      //   this.api.axiosGql('SELECT_MYPAGE', {type:'SELECT_MYPAGE', id:id})
      //   .then( async (res) => {
      //     let json = res.data
      //     if(!(json === null || json[0] === undefined)) this.point = Number(json[0].POINT).toLocaleString() + 'p'
      //   })
      //   await this.getMsgCnt()
      //   .then( async (res) => {
      //     this.msgCnt = res
      //   })
      // }
    },
    async getMsgCnt() {
      return new Promise( (resolve, reject) => {
        this.api.axiosGql("SELECT_ORDER_BY_ID2", {type:"SELECT_ORDER_BY_ID2", id:this.api.getCookies('id'), page:1})
        .then( async(res) => {
          let noChkCnt = 0
          let result = res.data
          for(let i = 0; i < result.length; i++) {
            if(result[i].MSG_CHK_YN === false) noChkCnt++ 
          }
          if(result === undefined) reject('error')
          else resolve(noChkCnt)
        })
        .catch( (err) => {
          reject(err)
        })
      })
    },
    async request() {
      window.Kakao.API.request({
        url: '/v2/user/me',
        success: async (response) => {
          let flag = false
          await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
          .then( async (res) => {
            const result = res.data
            if(result === 'null' || result === undefined || result === null ) flag = false
            else flag = true
          })
          .catch((err) => {
            console.log(err)
          })
          
          if(flag) {
            let nickName = response.properties.nickname
            let img = response.properties.thumbnail_image
            let mail = response.kakao_account.email
            this.setCookies({login:true, id:response.id, nickname:nickName, img:img, mail:mail})
          } else this.showRegistModal({id:response.id, type:'kakao'})
        },
        fail: (error) => {
          console.log(error)
        }
      })
    },
    phref(type) {
      if(window.$cookies.get('page') === 'home') {
        const el = document.getElementById(type)
        el.scrollIntoView(el.scrollTop)
        el.setAttribute('class', 'card main blink')
      } else router.push({name:"home", params:{type: type}})
    },
    changed(event) {
      this.name = 'Sungho Bae'
      this.addrmenu1 = '주소 #1'
      this.addrmenu2 = '주소 #2'
      this.addrmenu3 = '도시'
      this.addrmenu4 = '주'
      this.addrmenu5 = '우편번호'
      this.addrmenu6 = '전화번호'
      
      let country = event.target.value
      if(country === '홍콩') {    // SOLAIR
        this.addrmenu3 = '전화번호'
        this.addrmenu4 = '우편번호'
        this.addrmenu5 = ''
        this.addrmenu6 = ''
        this.addr1 = 'Unit C1, 7/F Bonjour Tower',
        this.addr2 = 'No. 36-42 Wang Wo Tsai Street, Tsuen Wan, N.T.',
        this.city = '53161715',
        this.state = '없음',
        this.zipcode = '',
        this.phone = ''
      } else if(country === '싱가포르') {   // POSTGO -> 씽배송(쏭배송)
        this.addr1 = '27 Hazel Park Terrace',
        this.addr2 = '#17-01',
        this.city = 'Singapore',
        this.state = 'west',
        this.zipcode = '678949',
        this.phone = '98244665'
        this.name = 'Kim sunhoa0642'
      } else if(country === '대만') {   // 대배송(쏭배송)
        // this.addr1 = '新北市三重區五華街241巷6號1樓',
        this.addr1 = '',
        this.addr2 = '',
        this.city = '당분간 대만은 구매대행만 가능합니다',
        this.state = '',
        this.zipcode = '',
        this.phone = ''
        this.name = ''
        // this.phone = '0910-369-633'
        // this.name = '倪苑琄 + (주문자 한글 이름)'
      // } else if(country === '태국') {   // 쏭배송
      //   this.addr1 = '',
      //   this.addr2 = '',
      //   this.city = '',
      //   this.state = '',
      //   this.zipcode = '',
      //   this.phone = ''
      //   this.name = ''
      } else if(country === '말레이시아') {   
        this.name = 'jay'
        this.addr1 = 'Warehouse6(Cosmo SCM), Lot 38279, Jalan SU 8A, Lion Industrial Park',
        this.addr2 = '',
        this.city = 'Shah Alam',
        this.state = 'Selangor',
        this.zipcode = '40300',
        this.phone = '0105302234'
      } else if(country === '프랑스') {     // IPORTER
        this.addr1 = '28, Avenue Christian Doppler',
        this.addr2 = 'Menetz, ATTN363953',
        this.city = 'Bailly-Romainvilliers',
        this.state = '',
        this.zipcode = '77700',
        this.phone = '0788178295'
      } else if(country === '스페인') {   // IPORTER
        this.addr1 = 'Calle Mahon, 6',
        this.addr2 = 'Bajo B, KS-Hub spain ATTN363953',
        this.city = 'Las Rozas',
        this.state = 'Madrid',
        this.zipcode = '28290',
        this.phone = '0912511606'
      } else if(country === '이탈리아') {   // POSTGO
        this.addr1 = 'Via Martiri Di Cefalonia, 5',
        this.addr2 = 'PIANO TERRA, ACI SRL #PG41370',
        this.city = 'SEGRATE',
        this.state = 'MILANO (MI)',
        this.zipcode = '20054',
        this.phone = '0221873258'
      } else if(country === '영국') {   // IPORTER
        this.addr1 = '776 BUCKINGHAM AVENUE, SLOUGH',
        this.addr2 = 'ATTN363953',
        this.city = 'Slough',
        this.state = 'Berkshire',
        this.zipcode = 'SL1 4NL',
        this.phone = '0175-361-0400'
      } else if(country === '독일') {   // IPORTER
        this.addrmenu1 = 'Straße, Hausnummer<br>(지번)'
        this.addrmenu2 = 'Adresszusatz<br>(추가주소)'
        this.addrmenu3 = 'Postleitzahl<br>(우편번호)'
        this.addrmenu4 = 'Stadt<br>(시)'
        this.addrmenu5 = 'Bundesland<br>(주)'
        this.addrmenu6 = 'Telefonnummer<br>(전화번호)'

        this.addr1 = 'Waldstrasse 23C',
        this.addr2 = 'Menetz ATTN363953',
        this.city = '63128',
        this.state = 'Dietzenbach',
        this.zipcode = 'Hessen',
        this.phone = '06181-490-7360'
      } else if(country === '네덜란드') {   // WEL
        this.name = 'Sue SYDAM #WL1286'
        this.addr1 = 'Keurmeesterstraat 57',
        this.addr2 = '#WL1286',
        this.city = 'Amstelveen',
        this.state = 'Noord-Holland',
        this.zipcode = '1187 ZX',
        this.phone = '0624940708'
      } else if(country === '일본') {   // IPORTER
        this.addrmenu1 = '郵便番号<br>(우편번호)'
        this.addrmenu2 = '都道府県<br>(도도부현)'
        this.addrmenu3 = '住所1<br>(시군구)'
        this.addrmenu4 = '住所2<br>(그외주소)'
        this.addrmenu5 = '電話番号<br>(전화번호)'
        this.addrmenu6 = ''
        this.addr1 = '112-0002',
        this.addr2 = '東京都',
        this.city = '文京区小石川 2-24-7',
        this.state = '名古屋ビル1F ATTN363953',
        this.zipcode = '03-3830-0852	',
        this.phone = ''
      } else if(country === '중국') {   // IPORTER
        this.addrmenu1 = '选择所在地<br>(지역)'
        this.addrmenu2 = '邮政编码<br>(우편번호)'
        this.addrmenu3 = '街道地址<br>(거리)'
        this.addrmenu4 = '收件人姓名<br>(수취인명)'
        this.addrmenu5 = '电话<br>(전화번호)'
        this.addrmenu6 = '手机<br>(폰번호)'
        this.name = 'ATTN363953, SunghoBae'
        this.addr1 = '上海市松江区九亭镇',
        this.addr2 = '201615',
        this.city = '金马路59号1号楼209室 ATTN363953',
        this.state = 'Sungho Bae',
        this.zipcode = '189-3089-1491',
        this.phone = '189-3089-1491'
      } else if(country === '미국') {
        this.addr1 = '6424 NE 59th Place(Pl)',
        this.addr2 = 'ATTN363953',
        this.city = 'PORTLAND',
        this.state = 'OR(Oregon)',
        this.zipcode = '97218',
        this.phone = '503-208-3427'
      } else if(country === '호주') {     // ELDEX
        this.addr1 = 'UNIT 7 / 4 AVE OF AMERICAS',
        this.addr2 = 'ELDEX KW96832 PANTOS',
        this.city = 'NEWINGTON',
        this.state = 'NSW',
        this.zipcode = '2127',
        this.phone = '61-2-8305-3750'
      } else if(country === '뉴질랜드') {   // WEL
        this.name = 'SunghoBae #WL1286'
        this.addr1 = 'Unit 1, 61 View Rd, Wairau Valley',
        this.addr2 = 'GS EXPRESS - WEL #WL1286',
        this.city = 'AUCKLAND',
        this.state = 'state',
        this.zipcode = '0627',
        this.phone = '09-444-7897'
      } else if(country === '오스트리아') {   // WEL
        this.name = 'Joo ho Yun #WL1286'
        this.addr1 = 'Faberstraße 26 /9',
        this.addr2 = 'YUN Joo ho #WL1286',
        this.city = 'SALZBURG',
        this.state = 'state',
        this.zipcode = '5020',
        this.phone = '0670 405 9099'
      } else {
        this.name = ''
        this.addr1 = '',
        this.addr2 = '',
        this.city = '',
        this.state = '',
        this.zipcode = '',
        this.phone = ''
      }
    }
  }

}
</script>

<style>
#b2c {
  max-width: 1024px;
  min-width: 450px;
  margin: 0 auto; 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#gameBackground {
  width:100%;
  height:100%;
}
floatmenu {
  z-index: 9999999999;
}
.agree {
  color: red;
  margin-left: 10px;
}
.agree-wrapper {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  height: 50px;
}
.mypage-icon {
  vertical-align: middle;
  height:35px;
}
#logoLink img {
  height: 30px;
  margin:5px;
}
.container_sub_style {
  width: 50%;
  padding: 10px;
  border-right: 1px solid lightgray;
}
.menu_border {
  position: absolute;
  height: 4px;
  width: 100%;
  min-width: 500px;
  margin-top: -10px;
  left: 0px;
  right: 0px;
  background-color: red;
}
html::-webkit-scrollbar {
  display: none;
}
#login {
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
}
.container_login {
  display: flex;
}
.main {
  border: 1px dotted gray;
  width: auto;
  margin-bottom: 25px;
  box-shadow: 10px 10px 10px  lightgray;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  display:inline-block;
}
.container {
  display:table;
  width: 100%;
}
.td_title {
  min-width: 60px;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-right: 10px;
}
.td_value {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  border-right: 1px dotted gray;
}
.div_title {
  margin: 10px;
  padding-top:20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div_title2 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div_title3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div_center {
  text-align:center;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  width:100%;
}
.table-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.padding-left {
  padding-left:10px;
}
.container_no_login {
  text-align: center;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fff;
  color: blue;
  border-radius: 6px;
  border: 2px dotted gray;
  padding: 5px;
  position: absolute;
  z-index: 1;
}
.tooltip {
  position: absolute;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltip-bottom {
  width: 200px;
  top: 50%;
  left: 50%;
}
.tooltip .tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent black transparent;
}
/* input {
  width:100%;
} */
.modal {
  width: 80%;
  max-width:1024px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  border-radius: 9px;
}
.button:active {
  opacity: 0.5;
}
.divider {
  background-color:lightsalmon; 
  height:1px;
  margin:10px;
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink-effect 1s step-end 4;
}
.footer {
  margin-top: 200px;
}
.selected {
  background-color: #ffe5db;
  border-radius: 6px;
  padding: 5px;
}
.ad-container {
  margin-top: 10px;
}
.float-left-banner {
  position:fixed;
  top:400px;
  left:90px;
  z-index: 999;
  width: 240px;
  height: 600px;
  cursor: pointer;
}
.category {
  position:fixed;
  top:268px;
  left:40px;
  z-index: 999;
  width: 240px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background: #ff7f50;
  color:white;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}
@media(max-width: 1600px) { 
  .float-left-banner { 
    width: 240px; 
    height: 600px; 
    top:200px;
    left:-2000px;
  } 
  .category {
    top:268px;
    left:-2000px;
    width: 240px;
    height: 50px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.status_cnt {
  float: right;
}
.status_cnt a{
  color: red;
  font-weight: bold;
}
.user_pic {
  vertical-align:middle;
  height: 25px;
  border-radius: 50%;
  margin-right:5px;
}
/* .profile {
  background-color: #fff7ed;
  text-align: right;
  vertical-align: middle;
  min-height: 23px;
  overflow: hidden;
  position: relative;
  width:100%;
  border-top: 1px solid #ff7f50;
  border-bottom: 1px solid #ff7f50;
  padding-left:40px;
  padding-right:40px;
} */
/* @media(min-width: 1000px) { 
  .menuNavigator {
    display: none; 
  }
} */
/* .menuItem {
  width: 120px;
  height: auto;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
} */
#modal_table {
  border-collapse: collapse;
  border-spacing: 0;
}
#modal_table > tr {
  height : 50px;
}
#modal_table > tr > td:nth-child(odd) {
  width:fit-content;
  font-weight: bolder;
  background: #F5F5F5;
}
#modal_table > tr > td:nth-child(even) {
  text-decoration: underline;
  cursor: pointer;
  border: 1px solid #F5F5F5;
}
#modal_main {
  width:80%;
  max-width:600px;
}
.regist_modal_main {
  width:100%;
  max-width:1000px;
}
.regist_textarea {
  width:100%;
  resize: none;
  min-height:200px;
}
#modal_main > p{
  text-align: left;
  font-style: italic;
  color:red;
  font-size: 12px;
}
.navbar {
  width: 100%;
  overflow: auto;
  cursor: pointer;
  background-color: lightgoldenrodyellow;
  border-radius: 9px;
}
.navbar span {
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  color: black;
  width: 20%;
  text-align: center;
  border-radius: 9px;
}
.navbar span.active {
  background-color: lightsalmon;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 50px) {
  .navbar span {
    float: none;
    display: block;
    width: 100%;
    text-align: left;
  }
}
.collapsible {
  background-color: white;
  color: black;
  cursor: pointer;
  margin: 5px;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.select, .collapsible:hover {
  background-color: #f1f1f1;
  border-radius: 9px;
}
.collapsible:after {
  content: '\002B';
  font-weight: bold;
  float: left;
  color: red;
  position:absolute;
}
.select:after {
  content: "\2212";
}
.input-normal {
  text-align: center;
  border: 1px dotted gray;
  width:100%;
  padding: 10px;
  margin: 10px;
  align-self: center;
}
/* .button-normal {
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  cursor: pointer;
} */
MARQUEE {
  z-index: 1;
  height:100%;
}
MARQUEE > label {
  margin-right:100px;
}
.marquee_group {
  width:100%;
  position:absolute;
  left:0px;
  margin-top:85px;
  margin-right:50px;
}
#order_helper {
  border-radius: 9px;
  cursor: pointer;
  font-style:italic;
  border: 1px solid lightsalmon;
  background-color: white;
  animation: blink-effect 1.2s infinite;
  width:100px;
  padding:5px;
  margin-top: 5px;
  float:right;
}
.point-font {
  color: #ffa07a;
  font-weight: bold;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index:10
}
#b2c > body {
  margin-top:100px;
}
.marketImgPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}
.margin10 {
  margin:10px;
}
.tab {
  overflow: hidden;
}
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}
.tab button:hover {
  background-color: #ffcc99;
}
.tab button.active {
  background-color: #ff7f50;
  color:white;
}
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ff7f50;
  border-top: none;
}
.normalInput {
  width:100%;
  text-align:center;
  padding-top:5px;
  padding-bottom:5px;
}
.normal-checkbox {
  width:20px;
  height:20px;
}
.modal_button {
  width:20%;
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  cursor: pointer;
  padding:10px;
  margin-left:5px;
  margin-right:5px;
}
.no-result {
  text-align: center;
  color:#ff7f50;
}
.no-login-title > span{
  color:red;
}
.no-login-title > ul > li{
  margin-bottom:10px;
}

.trade_table {
  width:100%;
}
.reg-img-container {
  width:fit-content;
  position: relative;
  text-align: center;
}
.reg-img-title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  opacity:0;
  display: flex;
  align-items: center; /* Vertically center align */
  justify-content: center; /* Horizontally center align */
}
.reg-img {
  aspect-ratio: 1;
  object-fit: contain;
}
.reg-img-container img {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
}
.special-content:hover .reg-img-title {
  opacity:1;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,0.8);
  font-size: 20px;
}
.search-input {
  text-align: center;
  padding:5px;
  max-width:50px;
  float:right; 
  margin-right:5px; 
}
.trade-checkbox {
  width:20px;
  height:20px; 
  vertical-align: middle; 
  position:relative; 
  right:5px;
}
.text-cut {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break : nowrap; 
}
.shop-menu-navigator {
  z-index: 9999; 
  background-color: transparent; 
  border: none; 
  font-size: 40px; 
  color:#ff7f50;
  cursor: pointer;
}
.country-flag {
  margin:-5px;
  width:25px;
  float:right;
}
.write-wrapper-wrapper > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.write-wrapper-wrapper > p > select {
  min-width:100px;
  text-align:center;
  padding:5px;
}
.write-wrapper-wrapper > p > label {
  text-align: left;
  min-width:70px;
  margin-right:10px;
  display: flex;
  align-items: center;
}
.write-wrapper-wrapper > p > input {
  text-align: center;
}
.button {
  float:right;
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  max-width: 50px;
  cursor: pointer;
}
.estimate-button {
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
}
.mypage-wrapper {
  display:flex;
  align-items: center;
}

@media screen and (min-width: 300px) {
  #cart-img {
    max-height:120px;
    max-width:80px;
  }
  #desc-window {
    display:none;
  }
  #desc-mobile {
    display:block;
    float:right;
  }
  #cart-container {
    width:100%;
  }
  .forwarder-cart-wrapper {
    width:60%;
    vertical-align:middle;
  }
}
@media screen and (min-width: 500px) {
  #cart-img {
    max-height:120px;
    max-width:80px;
  }
  #desc-window {
    display:none;
  }
  #desc-mobile {
    display:block;
    float:right;
  }
  #cart-container {
    width:100%;
  }
  .forwarder-cart-wrapper {
    width:100%;
    vertical-align:middle;
  }
}
@media screen and (min-width: 850px) {
  #cart-img {
    max-height:150px;
    max-width:100px;
  }
  #desc-window {
    display:block;
    float:right;
  }
  #desc-mobile {
    display:none;
  }
  #cart-container {
    width:100%;
    display: flex;
  }
  .forwarder-cart-wrapper {
    width:50%;
    padding-left:20px;
    padding-right:20px;
    min-height:150px;
    vertical-align:middle;
    border-right:1px dotted gray;
  }
}




@media screen and (min-width: 300px) {
  #cart-img {
    max-height:120px;
    max-width:80px;
  }
  #desc-window {
    display:none;
  }
  #desc-mobile {
    display:block;
    float:right;
  }
  #cart-container {
    width:100%;
  }
  .mypage-cart-wrapper {
    width:100%;
    vertical-align:middle;
  }
}
@media screen and (min-width: 850px) {
  #cart-img {
    max-height:150px;
    max-width:100px;
  }
  #desc-window {
    display:block;
    float:right;
  }
  #desc-mobile {
    display:none;
  }
  #cart-container {
    width:100%;
    display: flex;
  }
  .mypage-cart-wrapper {
    width:50%;
    padding-left:20px;
    padding-right:20px;
    min-height:150px;
    vertical-align:middle;
    border-right:1px dotted gray;
  }
}
.twinkle {
  animation: blink-effect 1s step-end infinite;
}

.sell-collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.sell-content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.sell-collapsible:after {
  content: '\002B';
  font-weight: bold;
  float: left;
  color: red;
  position:absolute;
}
.shop-search-result {
  display:flex;
  flex-wrap: wrap;
}


@media screen and (min-width: 1400px) {
  .quote-country {
    font-weight:bolder;
    font-size: 20px;
    min-width:100px;
  }
}
@media screen and (max-width: 1400px) {
  .quote-country {
    font-weight:bolder;
    font-size: 20px;
    min-width:100px;
  }
}

@media screen and (max-width: 840px) {
  .quote-country {
    font-weight:bolder;
    font-size: 20px;
    min-width:50px;
  }
}


@media screen and (min-width: 1024px) {
  .special-column {
    width: 33.33%;
    padding: 10px;
    box-sizing: border-box;
  }
  .special-content {
    height:100%;
    background-color: #f1f1f1;
    text-align: center;
    border-radius: 8px;
    aspect-ratio: 1;
  }
}
@media screen and (max-width: 1024px) {
  .special-column {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
  }
  .special-content {
    height:100%;
    background-color: #f1f1f1;
    border-radius: 8px;
    text-align: center;
    aspect-ratio: 1;
  }
}
@media screen and (max-width: 700px) {
  .special-column {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .special-content {
    height:100%;
    text-align: center;
    background-color: #f1f1f1;
    border-radius: 8px;
    aspect-ratio: 1;
  }
}

.item-menu-img {
  z-index:1;
  width:20px;
  position:absolute;
  text-align: center;
}
.item-menu {
  box-shadow: 3px 3px 3px  lightgray;
  padding:10px;
  opacity:0.8;
  display:none;
  margin-top:50px;
  width:100px;
  height:50px;
  border-radius: 10px;
  background-color: white;
}
.item-menu a {
  font-weight: bolder;
  font-size: 16px;
  color:black;
  display: block;
  text-decoration: none;
}
.item-menu a:hover{
  background-color: #ff7f50;
  color:white;
  border-radius: 10px;
}
.shop-table-value {
  width:100%;
  border-spacing: 0;
}
.quote-keyword > option {
  min-height: 100px;
}
.shop-table td:nth-child(odd) {
  border: 1px dotted #ffa07a; 
}
.shop-table tr:nth-child(odd) td{
  text-align: center;
  border: 1px dotted #ffa07a; 
}
.shop-table {
  border-collapse: collapse;
}
.shop-table tr:first-child td {
  border-top: 0;
}
.shop-table tr td:first-child {
  border-left: 0;
}
.shop-table tr:last-child td {
  border-bottom: 0;
}
.shop-table tr td:last-child {
  border-right: 0;
}
#shop-modal_table {
  border-collapse: collapse;
  border-spacing: 0;
}
#shop-modal_table > tr {
  height : 50px;
}
#shop-modal_table > tr > td:nth-child(odd) {
  width:150px;
  font-weight: bolder;
  background: aliceblue;
}
.button_more {
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
}
.select-contract {
  padding-top:5px;
  padding-bottom:5px; 
  float:right; 
  margin-top:-5px; 
  margin-right:10px;
}
#biz {
  bottom: 0;
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
}
.fullfillment-keywords > td {
  text-align: center;
  background-color: aliceblue;
  height:50px;
  border-radius: 16px;
}
.fixed-top-menu {
  position: fixed;
  top: 0;
  width:100%;
  background-color: #fff; /* 배경색을 원하는 색상으로 지정하세요 */
  z-index: 999; /* 다른 요소 위에 보이도록 z-index를 설정합니다 */
}

/* QNA Modal */
.quote-keyword {
  text-align: center;
  border-radius:16px;
  background-color:#f3f4f6;
  border:1px solid #f3f4f6;
  padding:5px;
}
.quote-keyword:hover {
  border:1px solid #ffc107 !important;
} 
.quote-keyword:focus {
  outline: none;
  border:2px solid #ffc107 !important;
}
#modal_quoto_main {
  width:95%;
  max-width:600px;
}
.quoto-modal {
  width: 100%;
  max-width:600px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quoto-modal > * {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.quoto-modal input,
.quoto-modal select,
.quoto-modal textarea,
.quoto-modal button {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.quoto-modal img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .quoto-modal {
    width: 95%;
    padding: 20px;
  }
}
.arrow {
  position: relative;
  width: 100%; /* 화살표의 길이 */
  height: 2px; /* 화살표의 두께 */
  background-color: green; /* 화살표의 색상 */
  margin: 0 20px; /* 화살표 좌우 여백 조절 */
  text-align: center;
}
.arrow::before {
  content: '';
  position: absolute;
  top: -4px; /* 위쪽으로 이동 */
  right: -10px; /* 오른쪽으로 이동 */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px; /* 화살표의 크기 및 모양 설정 */
  border-color: transparent transparent transparent green; /* 화살표의 색상 */
}
.quote-country-wrapper {
  display: flex; 
  align-items: center;
}
input {
  cursor:pointer;
} 
textarea {
  caret-color: red; /* Change to your desired color */
  cursor: url('./assets/cursor.png'), auto;
}
.ql-editor > p {
  caret-color: red; /* Change to your desired color */
  cursor: url('./assets/cursor.png'), auto;  
}
.ql-container {
  caret-color: red; /* Change to your desired color */
  cursor: url('./assets/cursor.png'), auto;
}
.input-keyword {
  max-width:120px;
  text-align:center;
  margin-right:10px;
  margin-left:10px;
}
.select-keyword {
  padding:6px;
  margin-right:10px;
  float:right;
}
.input-margin {
  margin-top:30px;
}
.order_sub_table {
  width:100%;
  border-collapse: collapse; 
}

.order_sub_table tr td {
  position: relative;
  min-height: 30px;
}
.dynamic-table td {
  width: 50%;
}

.img-td > img {
  width: 100px;
}

@media screen and (max-width: 620px) {
  .dynamic-table td {
    display: block;
    position: relative;
    text-align: left;
    width: auto;
  }

  .dynamic-table td:before {
    position: absolute;
    padding-right: 10px;
    left: 0;
    white-space: nowrap;
    content: attr(data-title);
  }
  .img-td > img {
    width: 50px;
  }
}
.order-div > div:nth-child(odd) {
  font-weight: bold;
  min-width:100px;
}
.order-div > div:nth-child(even) {
  width:100%;
}
.tag-input {
    font-family: Arial, sans-serif;
    width:100%;
  }
  .input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: text;
  }
  .input-container.is-focused {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .tag {
    background-color: #e1ecf4;
    border-radius: 2px;
    padding: 2px 4px;
    margin: 2px;
    display: flex;
    align-items: center;
  }
  .remove-tag {
    background: none;
    border: none;
    color: #666;
    margin-left: 4px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 2px;
  }
  .input-tag {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 14px;
  }
  .bottom-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top:10px;
  }
  .bottom-wrapper > div {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .write-input {
    width:80%;
    border:none;
    padding:10px;
    font-size: 20px;
  }
  .write-input:focus {
    outline:none;
  }
  .quill-editor-container {
    position: relative;
  }

  .quill-editor-container .ql-toolbar.ql-snow {
    z-index: 1000;
    position: static; 
    top: auto; 
    width: auto;
    background-color: white;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: var(--p-primary-100);
  }

  .quill-editor-container .ql-container.ql-snow {
    border: 1px solid #ccc;
    border-top: none;
  }

  .ql-editor {
    caret-color: #ff7f50;
    cursor: url('./assets/cursor.png'), auto;
    min-height: 300px;
  }

  .ql-snow .ql-toolbar button.ql-link:hover {
    color: #06c;
    position: static; 
    top: auto; 
    width: auto;
  }
  :deep(.p-autocomplete) {
    width: 100%;
  }
  :deep(.p-autocomplete-input) {
    width: 100%;
  }
  .mypage-cart-wrapper > p > label {
    color:gray;
    margin-right:10px;
    text-align: center;
  }
  .tracking-input {
    flex: 1;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding:5px;
    margin-right: 10px;
    min-width: 0; /* 이는 flex item이 너무 작아지는 것을 방지합니다 */
  }
</style>
