<template>
  <div class="card">
    <div class="container">
      <table>
        <!-- <tr>
          <td><input id="chk_fta" class="td_title normal-checkbox" ref="chk_fta" type="checkbox" :checked='(String(notice).indexOf("원산지증명") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_fta">원산지증명(FTA 통관) : +10,000원</label>
              <span class="tooltiptext tooltip-bottom">1. 원산지증명가능국가 :프랑스, 독일, 영국, 미국, 중국, 호주, 일본 <br><br>2. 물건을 받은 후에 대행국가에서 생산되었다는 게 확인이 되어야 원산지증명 진행이 가능합니다.</span>
            </div>
          </td>
        </tr> -->
        <tr>
          <td><input id="chk_packing" class="td_title normal-checkbox" ref="chk_packing" type="checkbox" :checked='(String(notice).indexOf("포장보완비용") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_packing">추가포장 : +5,000원</label>
              <span class="tooltiptext tooltip-bottom">추가포장을 하셔도 파손에 대해서는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>          
        </tr>
        <tr>
          <td><input id="chk_picture" class="td_title normal-checkbox" ref="chk_picture" type="checkbox" :checked='(String(notice).indexOf("사진촬영비용") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_picture">상품사진 : +5,000원</label>
              <span class="tooltiptext tooltip-bottom">상품의 가품, 파손 여부는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>
        </tr>
        <tr>
          <td><input id="chk_wine" class="td_title normal-checkbox" ref="chk_wine" type="checkbox" :checked='(String(notice).indexOf("와인") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip"><label for="chk_wine">와인(프랑스만 대행 가능) : +8,000원 x 수량</label>
              <span class="tooltiptext tooltip-bottom">상품의 가품, 파손 여부는 GEEK9에서 책임지지 않습니다.</span>
            </div>
          </td>
        </tr>  
        <tr>
          <td><input id="chk_insurance" class="td_title normal-checkbox" ref="chk_insurance" type="checkbox" :checked='(String(notice).indexOf("보험") === -1? false : true)' /></td>
          <td class="td_title">
            <div class="td_title tooltip">
              <label for="chk_insurance">보험 : +30,000원</label>
              &nbsp;<span style="color:red;">&gt;&gt;<label class="link" @click="showInsurance()" >필독</label>&lt;&lt;</span>
            </div>
          </td>
        </tr>              
      </table>
    </div>
  </div> 
  <ConfirmDialog group="templating">
    <template #message="">
      <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700">
        <div class="no-result">전액 환불 대상</div><br>
        <div>1. 결제 이후에 해외 판매자나 판매처가 상품을 보내지 않은 경우</div>
        <p>2. 배송 도중에 상품이 분실되거나 판매자가 잘못된 주소로 배송을 보낸 경우</p>
        <br><div class="no-result">주의사항</div><br>
        <div>1. 판매자가 가품이나 잘못된 상품을 보낸 경우는 보험의 대상이 아닙니다</div>
        <p>2. 배송 중 파손된 상품은 보험의 대상이 아닙니다</p>
        <div>3. 구매대행 주문만 보험신청가능</div>
        <p>4. 대행비 기준 50만 원 미만의 구매대행 주문만 보험신청가능</p>
        <div>5. 보험신청시 GEEK9 창고에 도착한 상품의 사진 제공</div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import { useConfirm } from "primevue/useconfirm"

export default {
  props: {
    notice: String
  },
  setup() {
    const confirm = useConfirm()
    const showInsurance = () => {
      confirm.require({
        group: 'templating',
        header: '보험',
        acceptProps: {
          label: '확인',
          icon: 'pi pi-check',
          size: 'small'
        },
        rejectProps: { // rejectProps 대신 rejectButtonProps 사용
          style: 'display: none;'
        },
        reject: null
      })
    }
    return {
      showInsurance
    }
  }
}
</script>
<style scoped>
input {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: -40px;
}
</style>