<template>
  <span></span>
  <div style="width:100%; display:block" >
    <div class="div_title">
      <span >No.{{idx}}</span>
      <span>
        {{this.api.getDateByFormat(qnaDate, 'YYYY.MM.DD')}} 
        <span class="link" style="margin:10px; color: red;" @click="this.click({type:'remove'})">삭제</span> 
      </span>
    </div>
    <table class="dynamic-table">
      <tr style="height:100px;">
        <td style="width:100%;">
          <table class="order_sub_table" style="table-layout:fixed;">
            <tbody >
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" >이름</div>
                    <div style="width:100%;">{{orderName}}</div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;">이메일</div>
                    <div style="width:100%;">{{ mail }}</div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;" >폰번호</div>
                    <div style="width:100%;">{{ phone }}</div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:100px;">주문내역</div>
                    <div style="width:100%;">{{ orderList }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
    <table style="width:100%">
      <tbody>
        <br>
        <tr style="height: 30px;">
          <td class="text-left" style="padding-top:10px;">문의내용</td>
          <td colspan="3" v-if="!(this.fileName === undefined || this.fileName === '')">
            <span class="link" v-if="String(this.fileName).indexOf('png') === -1 && String(this.fileName).indexOf('jpeg') === -1 && String(this.fileName).indexOf('jpg') === -1" id="attach-btn" @click="click({type:'download', fileName:this.fileName})">첨부파일</span>
            <span class="link" v-else id="attach-btn" @click="click({type:'showImg', fileName:this.fileName})">첨부사진</span>
          </td>
        </tr>
        <tr style="height: 30px;">
          <td colspan="4">
            <textarea :value="content" rows="10" style='width:100%;padding:10px;' disabled="true" />
          </td>
        </tr>
        <br>
        <tr>
          <td class="text-left" style="padding-top:10px;">답변</td>
          <td colspan="3" v-if="!(this.replyFileName === undefined || this.replyFileName === null || this.replyFileName === '')">
            <span class="link" v-if="String(this.replyFileName).indexOf('png') === -1 && String(this.replyFileName).indexOf('jpeg') === -1 && String(this.replyFileName).indexOf('jpg') === -1" id="attach-btn" @click="click({type:'download', fileName:this.replyFileName})">첨부파일</span>
            <span class="link" v-else id="attach-btn" @click="click({type:'showImg', fileName:this.replyFileName})">첨부사진</span>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <textarea class="ta" rows="10" :value="reply" style='width:100%;padding:10px;' disabled="true" placeholder='곧 답변드리겠습니다.' />
          </td>
        </tr>
        <tr v-if="this.fileName !== ''">
          <td class="text-left" style="padding-top:10px;">
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="this.api.getCookies('id') === '1513898286'" >
      <br>
      <table style="width:100%;">
        <tr >
          <td class="text-left" style="padding-top:10px;">운영자답변</td>
          <td style="float:right;">
            <Select v-model="selected" :options="contents" optionLabel="TITLE" @change="click({type:'change'})" placeholder="첨부콘텐츠"  />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <textarea class="ta" rows="10" v-model="adminReply" style='width:100%;padding:10px;' />
          </td>
        </tr>
      </table>
      <div class="div_title2">
        <input type="file" id="adminInputFile" @change="adminReadFile($event)" style="max-width:180px;">
        <span class="div_title">
          <Checkbox v-model="qaYn" inputId="qaYn" name="qaYn" :binary="true" />
          <label for="qaYn" >QA</label>&nbsp;
          <Button label="수정" @click="click({type:'geek9.updateQa', idx:idx})" raised size="small"  />&nbsp;
          <Button label="답변" @click="click({type:'geek9.updateQna', idx:idx})" raised size="small" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contents:[],
    idx: Number,
    qa: Number,
    content: String,
    country: String,
    mail: String,
    orderList: String,
    orderName: String,
    phone: String,
    qnaDate: String,
    fileName: String,
    replyFileName: String,
    reply: String,
  },
  data() { 
    return { 
      selected:{},
      adminReply:'',
      adminFileName:'',
      qaYn:false
    }
  },
  mounted() {
    if(this.qa === 1) this.qaYn = true
    if(!(this.reply === undefined || this.reply === '' || this.reply === null || this.reply === 'null') ) this.adminReply = this.reply
  },
  methods: {
    async adminReadFile(event) {
      this.file = event.target.files[0]
      console.log(this.file)
      if(this.file.size >= 3072000) {
        alert('3M 이하 파일만 첨부가 가능합니다')
      } else {
        const type = 'UPLOAD_IMG'
        const formData = new FormData()
        formData.append("file", this.file)
        formData.append('service', 'geek9')
        formData.append('pbType', 'qna')
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (res) => {
          this.adminFileName = res.data
        })
      }  
    },
    async click(param) {
      const type = param.type
      const fileName = param.fileName
      if(type === 'showImg') {
        const img = new Image()
        const imgUrl = this.api.WSRV_POCKETBASE_URL + fileName
        window.open(imgUrl, '_blank', `location=no;width:${img.width}px;height:${img.height}px;`)
      } else if(type === 'remove') {
        this.$emit("remove", {idx: this.idx, fileName:this.fileName, replyFileName:this.replyFileName})
      } else if(type === 'download') {
        const link = document.createElement('a')
        // link.href = this.api.WSRV_POCKETBASE_URL + fileName
        link.href = this.api.POCKETBASE_URL + ':8090/api/files/' + fileName
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else if(type === 'geek9.updateQna') {
        const idx = param.idx
        if(confirm('답변하시겠습니까?')) {
          this.emitter.emit('dialog', {flag:true, title:'답변중입니다.'})
          await this.api.axiosGql(type, {type:type, qnaDate:this.qnaDate, orderName:this.orderName, phone:this.phone, orderList:this.orderList,  content:this.content, mail:this.mail, reply:this.adminReply, idx:Number(idx), replyFileName:this.adminFileName, qaYn:this.qaYn == true ? 1 : 0})
          .then( async (res) => {
            if(res.status === 201) {
              alert('답변완료')
            } else alert('다시 시도해주세요.')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요.')
          })
          .finally( () => {
            this.emitter.emit('dialog', {flag:false})
          })
        }
      } else if(type === 'geek9.updateQa') {
        const idx = param.idx
        if(confirm('수정하시겠습니까?')) {
          this.emitter.emit('dialog', {flag:true, title:'답변중입니다.'})
          console.log({type:type, reply:this.adminReply, idx:Number(idx), qaYn:this.qaYn == true ? 1 : 0})
          await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), reply:this.adminReply, idx:Number(idx), qaYn:this.qaYn == true ? 1 : 0})
          .then( async (res) => {
            if(res.status === 200) {
              alert('수정완료')
            } else alert('다시 시도해주세요.')
          })
          .catch( (err) => {
            console.log(err)
            alert('다시 시도해주세요.')
          })
          .finally( () => {
            this.emitter.emit('dialog', {flag:false})
          })
        }
      } else if(type === 'change') {
        const url = `https://contents.geek9.kr/${this.selected.TYPE}/${this.selected.IDX}`
        this.adminReply += `\n\n아래의 글을 참고해주세요!\n\n[${this.selected.TITLE}]\n${url}`
        this.selected = {}
        this.api.copyClipboard(url)
      }
    },
  }
}
</script>
<style scoped>
.attach_label {
  float:right;
  border: 0px;
  padding:5px;
}
.attach {
  float:right;
  border-radius: 9px;
  font-style: italic;
  padding:5px;
  max-width: 70px;
  cursor: pointer;
}
.text-left {
  text-align: left;
  font-weight: bold;
  padding: 10px;
}
.text-right {
  text-align: right;
}
.qna_sub_style {
  width: 25%;
  border-right: 1px dotted gray;
}
textarea {
  height: auto;
  overflow:visible;
}
#attach-btn {
  float:right; 
  margin-right: 10px;
  cursor: pointer;
  text-decoration: underline;
  color:red;
}
</style>