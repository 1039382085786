<template>

  <div class="quote-section">
    <table class="table">
      <tr>
        <div class="div_title">
          <context :context=cate b2b="true" />
        </div>
      </tr>
      <tr class="quote-keywords" style="height:100px;" >
        <td v-if="tradeMenu === 'SELECT_WORK_REQUEST'">
          <input ref='quoteAnd' id="quoteAnd" type="checkbox" style="max-width: 15px;" checked @change="changedKeyword({type:'and'})"/>
          <label for="quoteAnd" style="margin-right:5px;">AND</label>
          <input ref='quoteOr' id="quoteOr" type="checkbox" style="max-width: 15px;" @change="changedKeyword({type:'or'})"/>
          <label for="quoteOr" style="margin-right:5px;">OR</label>
          <input class="quote-keyword" ref="quoteTagKeyword" type="text" placeholder="키워드" v-on:keyup.enter="click({type:'addKeyword'})" style="max-width:100px;">&nbsp;
        </td>
        <td v-else>
          <input ref='quoteAnd' id="quoteAnd" type="checkbox" style="max-width: 15px;visibility: hidden;"  />
          <label for="quoteAnd" style="margin-right:5px;visibility: hidden;">AND</label>
          <input ref='quoteOr' id="quoteOr" type="checkbox" style="max-width: 15px;visibility: hidden;" />
          <label for="quoteOr" style="margin-right:5px;visibility: hidden;">OR</label>
          <input class="quote-keyword" ref="quoteTagKeyword" type="text" placeholder="키워드" style="max-width:100px;visibility: hidden;" >&nbsp;
        </td>
        <td style="width:100px;">
          <select class="quote-keyword" v-model="tradeMenu" @change="click({type:'change'})" style="padding:5px;border-bottom-color:white;">
            <option v-if="!tradeMenu" value="" style="display: none;">견적서</option>
            <option value="SELECT_WORK_REQUEST">고객의뢰</option>
            <option value="SELECT_WORK_WAREHOUSE">물류창고</option>
            <option value="QUOTE">견적계산</option>
          </select>
        </td>
        <td style="width:120px;" v-if="tradeMenu !== 'QUOTE'">
          <Button class="quote-search" @click="click({type:'addKeyword'})" label="견적서조회" raised size="small" />
        </td>
      </tr>
      <tr id="tagList">
        <td colspan="3">
          <span v-for="(tag, index) in quoteTagList" :key="index" >
            <Tag severity="warn" class="small-btn"  style="cursor:pointer;margin-bottom:10px;" @click="click({type:'remove', idx:index})" >
              <div class="flex items-center gap-2 px-1">
                <span class="text-base">{{ tag }}</span>
                &times;
              </div>
            </Tag>
          </span>
        </td>
      </tr>
    </table>
    <div v-if="quoteList.length === 0 && groupingQuote.size === 0 && tradeMenu !== 'QUOTE'" style="text-align:center;width:100%;font-weight:bolder;">
      조회중입니다
    </div>
    <div v-if="tradeMenu === ''" style="text-align:center;width:100%;font-weight:bolder;">
      견적서 유형을 선택해주세요
    </div>
    <div v-else-if="tradeMenu === 'SELECT_WORK_REQUEST'" class="quote-column" v-for="(quote, index) in quoteList" :value="quote.val" v-bind:key="index" >
      <div class="quote-content" @click="click({type:'quote', selectedIdx:quote.IDX, idx:index})" >
        <div class="quote-country-wrapper" v-if="!(quote.START_COUNTRY === null || quote.START_COUNTRY === undefined || quote.START_COUNTRY === '' || quote.START_COUNTRY === 'null')">
          <span class="quote-country" >{{quote.START_COUNTRY}}</span>
          <span class="arrow-origin"></span>
          <div class="arrow"></div>
          <span class="quote-country" >{{quote.END_COUNTRY}}</span>
        </div>
        <div class="quote-goods-wrapper">{{quote.GOODS_TYPE}}</div>
        <div class="quote-type-wrapper">{{quote.TYPE}}</div>
        <table class="table quote-info" >
          <tr>
            <td>문의일</td>
            <td>{{this.api.getDateByFormat(quote.REG_DATE, 'YYYY.MM.DD')}}</td>
          </tr>
          <tr v-if="!(quote.ORDER_CNT === null || quote.ORDER_CNT === 0 || quote.ORDER_CNT === '')">
            <td>예상월배송</td>
            <td>{{Number(quote.ORDER_CNT).toLocaleString()}} {{quote.ORDER_UNIT}}</td>
          </tr>
          <tr v-if="!(quote.STORAGE_CNT === null || quote.STORAGE_CNT === 0 || quote.STORAGE_CNT === '')">
            <td>예상월보관</td>
            <td>{{Number(quote.STORAGE_CNT).toLocaleString()}} {{quote.STORAGE_UNIT}}</td>
          </tr>
          <tr v-if="!(quote.TOTAL_WEIGHT === null || quote.TOTAL_WEIGHT === undefined || quote.TOTAL_WEIGHT === 0 || quote.TOTAL_WEIGHT === '' || quote.TOTAL_WEIGHT === 'null')">
            <td>전체무게</td>
            <td>{{Number(quote.TOTAL_WEIGHT).toLocaleString()}} kg</td>
          </tr>
          <tr v-if="!(quote.TOTAL_VOLUME_WID === null || quote.TOTAL_VOLUME_WID === undefined || quote.TOTAL_VOLUME_WID === 0 || quote.TOTAL_VOLUME_WID === '' || quote.TOTAL_VOLUME_WID === 'null')">
            <td>전체부피</td>
            <td>{{quote.TOTAL_VOLUME_WID}} X {{quote.TOTAL_VOLUME_DEP}} X {{quote.TOTAL_VOLUME_HGT}} mm</td>
          </tr>
          <tr v-if="!(quote.UNIT_WEIGHT === null || quote.UNIT_WEIGHT === undefined || quote.UNIT_WEIGHT === 0 || quote.UNIT_WEIGHT === '' || quote.UNIT_WEIGHT === 'null')">
            <td>개당무게</td>
            <td>{{quote.UNIT_WEIGHT}} kg</td>
          </tr>
          <tr v-if="!(quote.UNIT_VOLUME_HGT === null || quote.UNIT_VOLUME_HGTT === undefined || quote.UNIT_VOLUME_HGT === 0 || quote.UNIT_VOLUME_HGT === '' || quote.UNIT_VOLUME_HGT === 'null')">
            <td>개당부피</td>
            <td>{{quote.UNIT_VOLUME_WID}} X {{quote.UNIT_VOLUME_DEP}} X {{quote.UNIT_VOLUME_HGT}} mm</td>
          </tr>
          
          <tr v-if="!(quote.TEMPERATURE === null || quote.TEMPERATURE === undefined || quote.TEMPERATURE === 0 || quote.TEMPERATURE === '' || quote.TEMPERATURE === 'null')">
            <td>보관온도</td>
            <td>{{quote.TEMPERATURE}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="tradeMenu === 'SELECT_WORK_WAREHOUSE'" v-for="(quotes, key) in groupingQuote" :value="quotes[0]"  v-bind:key="key" style="width:100%;">
      <div style="font-size: 25px;color:black;font-weight: bold;border-bottom: 4px solid #ffc107;height:60px;margin-bottom:20px">{{ quotes[0] }}</div>
      <div class="warehouse-section">
        <div class="quote-column" v-for="(quote, index) in quotes[1]" :value="index" v-bind:key="index" >
          <div class="quote-content" style="text-align: left;height:100%;" @click="click({type:'warehouse', selectedIdx:index+1, json:quote})">
            <table class="table quote-info" >
              <tbody>
                <tr >
                  <td rowspan="3" v-if="quote.IMG_URL !== null"><img :src="'./assets/' + quote.IMG_URL" style="height:80px;" ></td>
                  <td style="font-weight: bold;">물류창고-{{ index+1 }}</td>
                </tr>
                <tr>
                  <td>
                    <label v-for="(desc, index2) in quote.DESC.split(',')" :value="desc" v-bind:key="index2">#{{ desc }}&nbsp;</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-if="!(quote.ETC === null || quote.ETC === '')" class="table quote-info" >
              <tbody style="font-size: 10px;">
                <tr v-for="(etc, index3) in quote.ETC.split(',')" :value="etc" v-bind:key="index3">- {{ etc }}</tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="tradeMenu === 'QUOTE'" class="container">
      <Stepper value="1">
        <StepItem value="1" >
          <Step><h5>INVOICE</h5></Step>
          <StepPanel v-slot="{ activateCallback }" class="step-panel">
            <div class="card">
              <div>운송정보</div>
              <div class="div_title" >
                <FloatLabel class="quote-menu">
                  <Select id="startCountry" v-model="startCountry" :options="country" optionLabel="country" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="startCountry" class="quote-label">출발국</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <Select id="endCountry" v-model="endCountry" :options="country" optionLabel="country" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="endCountry" class="quote-label">도착국</label>
                </FloatLabel>
                <FloatLabel class="order_sub_table" >
                  <Select id="transport" v-model="transport" :options="[{name:'항공',}, {name:'해상',}]" optionLabel="name" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="transport" class="quote-label">운송수단</label>
                </FloatLabel>
              </div>
              <div class="div_title" >
                <FloatLabel class="quote-menu">
                  <InputText id="startAddress" v-model="startAddress" class="order_sub_table" />
                  <label for="startAddress" class="quote-label">출발국주소</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="startPhone" v-model="startPhone" class="normalInput" />
                  <label for="startPhone" class="quote-label">출발국연락처</label>
                </FloatLabel>
                <FloatLabel class="order_sub_table">
                  <Select id="pickup" v-model="pickup" :options="needYn" optionLabel="need" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="pickup" class="quote-label">상품픽업</label>
                </FloatLabel>
              </div>
              <div class="div_title" >
                <FloatLabel class="quote-menu">
                  <InputText id="endAddress" v-model="endAddress" class="normalInput" />
                  <label for="endAddress" class="quote-label">도착국주소</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="endPhone" v-model="endPhone" class="normalInput" />
                  <label for="endPhone" class="quote-label">도착국연락처</label>
                </FloatLabel>
                <FloatLabel class="order_sub_table">
                  <Select id="trucking" v-model="trucking" :options="needYn" optionLabel="need" checkmark :highlightOnSelect="false" class="order_sub_table"/>
                  <label for="trucking" class="quote-label">현지운송</label>
                </FloatLabel>
              </div>
            </div>
            <div class="card">
              <div>명의</div>
              <div class="div_title" >
                <FloatLabel class="quote-menu">
                  <Select id="startCompanyYn" v-model="startCompanyYn" :options="companyYn" optionLabel="company" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="startCompanyYn" class="quote-label">출발국명의</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <Select id="endCompanyYn" v-model="endCompanyYn" :options="companyYn" optionLabel="company" checkmark :highlightOnSelect="false" class="order_sub_table" />
                  <label for="endCompanyYn" class="quote-label">도착국명의</label>
                </FloatLabel>
              </div>
              <div class="div_title" v-if="!(startCompanyYn === undefined || startCompanyYn.company !== '보유')" >
                <FloatLabel class="quote-menu">
                  <InputText id="startCompanyName" v-model="startCompanyName" class="normalInput" />
                  <label for="startCompanyName" class="quote-label">출발국사업자명</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="startCompanyAddress" v-model="startCompanyAddress" class="normalInput" />
                  <label for="startCompanyAddress" class="quote-label">출발국사업자주소</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="startCompanyPhone" v-model="startCompanyPhone" class="normalInput" />
                  <label for="startCompanyPhone" class="quote-label">출발국사업자연락처</label>
                </FloatLabel>
              </div>
              <div v-else-if="startCompanyYn !== undefined" class="no-result margin10" >
                출발국 명의 없음 - 명의대행(IOR) 서비스 필요
              </div>
              <div class="div_title" v-if="!(endCompanyYn === undefined || endCompanyYn.company !== '보유')" >
                <FloatLabel class="quote-menu">
                  <InputText id="endCompanyName" v-model="endCompanyName" class="normalInput" />
                  <label for="endCompanyName" class="quote-label">도착국사업자명</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="endCompanyAddress" v-model="endCompanyAddress" class="normalInput" />
                  <label for="endCompanyAddress" class="quote-label">도착국사업자주소</label>
                </FloatLabel>
                <FloatLabel class="quote-menu">
                  <InputText id="endCompanyPhone" v-model="endCompanyPhone" class="normalInput" />
                  <label for="endCompanyPhone" class="quote-label">도착국사업자연락처</label>
                </FloatLabel>
              </div>
              <div v-else-if="endCompanyYn !== undefined" class="no-result margin10" >
                도착국 명의 없음 - 명의대행(IOR) 서비스 필요
              </div>
            </div>
            <div class="div_title">
              <Button label="뒤로" severity="secondary" disabled />
              <Button label="다음" @click="activateCallback('2')" />
            </div>
          </StepPanel>
        </StepItem>
        <StepItem value="2" class="">
          <Step><h5>PACKING LIST</h5></Step>
          <StepPanel v-slot="{ activateCallback }" class="step-panel">
            <div class="div_title" >
              <FloatLabel >
                <InputNumber id="sku" v-model="sku" placeholder="품목종류" />
                <label for="sku" >SKU</label>
              </FloatLabel>
            </div>
            <div class="div_title">
              <Button label="뒤로" severity="secondary" @click="activateCallback('1')" />
              <Button label="다음" @click="activateCallback('3')" />
            </div>
          </StepPanel>
        </StepItem>
        <StepItem value="3" class="">
            <Step><h5>Header III</h5></Step>
            <StepPanel v-slot="{ activateCallback }" class="step-panel">
                <div class="flex flex-col h-48">
                  <div class="trade-progress-content">Content 3</div>
                </div>
                <div class="div_title">
                  <Button label="뒤로" severity="secondary" @click="activateCallback('2')" />
                  <Button label="다음" @click="activateCallback('4')" />
                </div>
            </StepPanel>
        </StepItem>
        <StepItem value="4" class="">
            <Step><h5>Header III</h5></Step>
            <StepPanel v-slot="{ activateCallback }" class="step-panel">
                <div class="flex flex-col h-48">
                  <div class="trade-progress-content">Content 4</div>
                </div>
                <div class="div_title">
                  <Button label="뒤로" severity="secondary" @click="activateCallback('3')" />
                  <Button label="다음" @click="activateCallback('5')" />
                </div>
            </StepPanel>
        </StepItem>
        <StepItem value="5" class="">
            <Step><h5>Header III</h5></Step>
            <StepPanel v-slot="{ activateCallback }" class="step-panel">
                <div class="flex flex-col h-48">
                  <div class="trade-progress-content">Content 5</div>
                </div>
                <div class="div_title">
                  <Button label="뒤로" severity="secondary" @click="activateCallback('4')" />
                  <Button label="다음" @click="activateCallback('6')" />
                </div>
            </StepPanel>
        </StepItem>
        <StepItem value="6" class="">
            <Step><h5>Header III</h5></Step>
            <StepPanel v-slot="{ activateCallback }" class="step-panel">
                <div class="flex flex-col h-48">
                  <div class="trade-progress-content">Content 6</div>
                </div>
                <div class="div_title">
                  <Button label="뒤로" severity="secondary" @click="activateCallback('5')" />
                  <Button label="완료" disabled />
                </div>
            </StepPanel>
        </StepItem>
      </Stepper>
    </div>
  </div>
  <div v-if="!(quoteList.length === 0 || tradeMenu === 'SELECT_WORK_WAREHOUSE' )" style="text-align: center; margin-bottom: 20px;">
    <label @click="paging('prev')" class="link">
      이전
    </label>
    <span style="margin:20px">{{ page }}</span>
    <label @click="paging('next')" class="link">
      다음
    </label>
  </div>
  <br>
  <div class="ad-container" style="width:100%;text-align: center;">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
  <div id="modals"></div>
  <Modal v-model='isShow' style="z-index: 99999999;">
    <div id="modal_quoto_main" class="quoto-modal">
      <div class="div_title" >
        <span style="color: #ffc107; ">견적받기</span>
      </div>
      <br>
      <div class="quote-content" style="border:2px solid #ffc107 !important;">
        <div class="quote-country-wrapper" v-if="!(json.START_COUNTRY === null || json.START_COUNTRY === undefined || json.START_COUNTRY === '' || json.START_COUNTRY === 'null')">
          <span class="quote-country" >{{ json.START_COUNTRY }}</span>
          <span class="arrow-origin"></span>
          <div class="arrow"></div>
          <span class="quote-country" >{{ json.END_COUNTRY }}</span>
        </div>
        <div class="quote-goods-wrapper">{{ json.GOODS_TYPE }}</div>
        <div class="quote-type-wrapper">{{ json.TYPE }}<label v-if="!(json.TEMPERATURE === undefined || json.TEMPERATURE === '')">, {{ json.TEMPERATURE }}</label></div>
      </div>
      <br>
      <table class="table" style="width:100%;text-align: left;;">
        <tr>
          <td style="font-weight: bolder;">이메일 *</td>
        </tr>
        <tr>
          <td><input ref="quoteMail" class="quote-keyword" type="email" placeholder="견적서 받을 이메일" style="width:100%;"></td>
        </tr>
        <br>
        <tr>
          <td style="font-weight: bolder;">폰번호 *</td>
        </tr>
        <tr>
          <td><input ref="quotePhone" class="quote-keyword" type="tel" placeholder="연락 받을 폰번호" style="width:100%;"></td>
        </tr>
        <br>
        <tr>
          <td style="font-weight: bolder;">요청/문의</td>
        </tr>
        <tr>
          <td><textarea ref="quoteContent" class="quote-keyword" style="width:100%;height:200px;resize:none;padding:10px;" placeholder="상세내용"></textarea></td>
        </tr>
      </table>
      <div class="ad-container">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="8230887469"
          data-ad-format="horizontal"
          data-full-width-responsive="true">
        </Adsense>
      </div>
      <br>
      <div class="div_center">
        <Button class="quote-search" @click="click({type:'INSERT_QNA', orderList:json.IDX, id:'', orderName:'quote', fileName:json.FILE_NAME, country:(json.START_COUNTRY + '-'+ json.END_COUNTRY)})" label="견적서받기" raised />
        <Button class="quote-search" type="button" @click="closeModal()" label="닫기" raised />
      </div>
    </div>
  </Modal>
  <Modal v-model='isWarehouseShow' style="z-index: 99999999;">
    <div id="modal_quoto_main" class="quoto-modal">
      <div class="div_title">
        <span style="color: #ffc107; ">견적받기</span>
      </div>
      <br>
      <div class="quote-content" style="border:2px solid #ffc107 !important;">
        <div class="quote-country">{{ json.COUNTRY }}창고 #{{ warehouseQuoteIdx }}</div>
        <div class="quote-type-wrapper">
          <label v-for="(desc, index3) in json.DESC.split(',')" :value="desc" v-bind:key="index3">#{{ desc }}</label>  
        </div>
        <div v-if="!(json.ETC === null || json.ETC === '')" style="text-align: left;font-size: 12px;">
          <div v-for="(etc, index4) in json.ETC.split(',')" :value="etc" v-bind:key="index4">- {{ etc }}</div>  
          <br>
        </div>
      </div>
      <br>
      <table class="table" style="width:100%;text-align: left;">
        <tr>
          <td style="font-weight: bolder;">이메일 *</td>
        </tr>
        <tr>
          <td><input ref="quoteMail" class="quote-keyword" type="email" placeholder="견적서 받을 이메일" style="width:100%;"></td>
        </tr>
        <br>
        <tr>
          <td style="font-weight: bolder;">폰번호 *</td>
        </tr>
        <tr>
          <td><input ref="quotePhone" class="quote-keyword" type="tel" placeholder="연락 받을 폰번호" style="width:100%;"></td>
        </tr>
        <br>
        <tr>
          <td style="font-weight: bolder;">요청/문의</td>
        </tr>
        <tr>
          <td><textarea ref="quoteContent" class="quote-keyword" style="width:100%;height:200px;resize:none;padding:10px;" placeholder="상세내용"/></td>
        </tr>
      </table>
      <div class="ad-container">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="8230887469"
          data-ad-format="horizontal"
          data-full-width-responsive="true">
        </Adsense>
      </div>
      <br>
      <div class="div_center">
        <Button class="quote-search" type="button" @click="click({type:'INSERT_QNA', orderList:json.IDX, id:'', orderName:'quote', fileName:json.FILE_NAME, country:json.COUNTRY})" label="견적서받기" raised/>
        <Button class="quote-search" type="button" @click="closeWarehouseModal()" label="닫기" raised />
      </div>
    </div>
  </Modal>
  <footerInfo />
</template>
<script>
// import { useMeta } from 'vue-meta'
import { ref, inject } from 'vue'
import ssr from './components/common/ssr'
import context from '@/components/common/Context.vue'
import footerInfo from './components/common/footer'

export default {
  emits: ["setMenu", "login", "logout"],
  mounted() {
    this.addJsonLd()
    if(this.$route.query.warehouse === 'y') this.tradeMenu = 'SELECT_WORK_WAREHOUSE'
    else if(this.$route.query.quote === 'y') this.tradeMenu = 'QUOTE'
    else this.tradeMenu = 'SELECT_WORK_REQUEST'
    this.api.setPage('trade')
    this.$emit("setMenu", {page:'trade'})
    console.log(this.api.getCookies('page'))
    this.search({type:this.tradeMenu})
  },  
  components: {
    footerInfo, context
  },
  data() {
    return {
      startCompanyName:undefined,
      endCompanyName:undefined,
      startCompanyAddress:undefined,
      endCompanyAddress:undefined,
      startCompanyPhone:undefined,
      endCompanyPhone:undefined,
      startCompanyYn:undefined,
      endCompanyYn:undefined,
      trucking:undefined,
      pickup:undefined,
      transport:undefined,
      startCountry:undefined,
      startAddress:undefined,
      startPhone:undefined,
      endCountry:undefined,
      endAddress:undefined,
      endPhone:undefined,
      warehouseQuoteIdx:-1,
      tradeMenu:'',
      isAnd:true,
      page:1,
      quoteTagList:[],
      quoteList:[],
      groupingQuote:new Map(),
    }
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 기업 > 해외물류견적'
    const keywords = '물류,3pl,풀필먼트fulfillment,물류대행,기업물류,수출,수입,수출입,세관,통관,견적서,물류견적서,창고,해외창고'
    const description = 'GEEK9에서 처리한 기업의 해외물류업무에 대한 견적서를 조회하실 수 있습니다.'
    const geek9Url = ssr.GEEK9_URL + 'trade'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > 기업 > 해외물류견적',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/trade'}],
    //   meta: [
    //     { vmid: 'charset_trade', charset: 'utf-8' },
    //     { vmid: 'http-equiv_trade', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_trade', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_trade', name: 'keywords', content:'물류,3pl,풀필먼트fulfillment,물류대행,기업물류,수출,수입,수출입,세관,통관,견적서,물류견적서,창고,해외창고' },
    //     { vmid: 'viewport_trade', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_trade', name: 'description', content: 'GEEK9에서 처리한 기업의 해외물류업무에 대한 견적서를 조회하실 수 있습니다.' },
    //     { vmid: 'theme-color_trade', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_trade', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_trade', property: 'og:description', content: 'GEEK9에서 처리한 기업의 해외물류업무에 대한 견적서를 조회하실 수 있습니다.'},
    //     { vmid: 'og:title_trade', property: 'og:title', content: 'GEEK9 > 기업 > 해외물류견적'},
    //     { vmid: 'og:image_trade', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_trade', property: 'og:url', content: 'https://geek9.kr/trade'}
    //   ]
    // })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL+"biz", description)
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    let isShow = ref(false)
    let isWarehouseShow = ref(false)
    let selectedIdx = ref(-1)
    let json = ref('')
    const country = ref([{country:'홍콩'},{country:'중국'},{country:'싱가포르'},{country:'한국'},{country:'미국'},{country:'일본'},{country:'영국'},{country:'프랑스'},{country:'스페인'},{country:'네덜란드'},{country:'말레이시아'},{country:'이탈리아'},{country:'벨기에'},{country:'캐나다'},{country:'말레이시아'}])
    const needYn = ref([{need:'필요',}, {need:'불필요',}])
    const companyYn = ref([{company:'보유',}, {company:'미보유',}])
    const active = ref('0')
    const showModal = async (param) => {
      isShow.value = true
      json.value = param.json
      selectedIdx.value = param.idx
    }
    const closeModal = async () => {
      isShow.value = false
    }
    const showWarehouseModal = async (param) => {
      isWarehouseShow.value = true
      json.value = param.json
      selectedIdx.value = param.selectedIdx
    }
    const closeWarehouseModal = async () => {
      isWarehouseShow.value = false
    }
    const cate = ref([
      { label: '견적서', route: '/trade' }
    ])
    return {
      isShow, showModal, closeModal, showWarehouseModal, closeWarehouseModal, isWarehouseShow, json, active, country, needYn, companyYn, addJsonLd, cate
    }
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'addKeyword') {
        const keyword = this.$refs.quoteTagKeyword.value
        if(keyword === '') this.search({type:this.tradeMenu})
        else {
          if(this.quoteTagList.indexOf(keyword) === -1) {
            this.quoteTagList.push(keyword)
            this.$refs.quoteTagKeyword.value = ''
            this.autoSearch()
          } else alert('이미 추가된 키워드입니다')
        }
      } else if(type === 'delKeyword') {
        this.quoteTagList = []
        this.autoSearch()
      } else if(type === 'remove') {
        const idx = param.idx
        this.quoteTagList.splice(idx, 1)
        this.autoSearch()
      } else if(type === 'quote') {
        const idx = param.idx
        const json = this.quoteList[idx]
        this.showModal({selectedIdx:param.selectedIdx, json:json})
      } else if(type === 'warehouse') {
        const json = param.json
        this.warehouseQuoteIdx = param.selectedIdx
        this.showWarehouseModal({selectedIdx:param.selectedIdx, json:json})
      } else if(type === 'INSERT_QNA') {
        const content = this.$refs.quoteContent.value
        const phone = this.$refs.quotePhone.value
        const mail = this.$refs.quoteMail.value
        if(mail === undefined || mail === '') {
          alert('견적서를 받으실 이메일을 입력해주세요')
          this.$refs.quoteMail.focus()
          return
        } else if(phone === undefined || phone === '') {
          alert('연락처를 입력해주세요')
          this.$refs.quotePhone.focus()
          return 
        } else {
          param.content = content
          param.mail = mail
          param.phone = phone
          const fileName = param.fileName
          await this.api.axiosGql('INSERT_QNA', param)
          .then( async (res) => {
            if(res.status === 200) {
              if(fileName === undefined || fileName === '') alert('문의가 정상적으로 등록되었습니다.')
              else alert('이메일로 견적서를 보내드렸습니다.')
              
            } else alert('다시 시도해주세요.')
          })
          .catch( (err) => {
            console.log(err)
            this.emitter.emit('dialog', {flag:false})
            alert('다시 시도해주세요.')
          })
          .finally( ()=>{
            this.closeModal()
          })
        }
      } else if(type === 'change') {
        this.clear()
        this.click({type:'addKeyword'})
        // if(this.tradeMenu === '작업기준') 
        // else if(this.tradeMenu === '창고기준') {
        //   console.log(this.tradeMenu)
        // }
      }
    },
    async changedKeyword(param) {
      let type
      if(param === undefined) type = undefined
      else type = param.type
      
      if(type === 'and') {
        if(this.$refs.quoteOr.checked === true) {
          this.isAnd = true
          this.$refs.quoteOr.checked = false
        }  
        if(this.$refs.quoteAnd.checked === false) {
          this.isAnd = true
          this.$refs.quoteAnd.checked = true
        }
        this.autoSearch()
      } else if(type === 'or') {
        if(this.$refs.quoteAnd.checked === true) {
          this.isAnd = false
          this.$refs.quoteAnd.checked = false
        }
        if(this.$refs.quoteOr.checked === false) this.$refs.quoteOr.checked = true
        this.autoSearch()
      }
    },
    async autoSearch() {
      this.page = 1
      this.search({type:this.tradeMenu})
    },
    async search(param) {
      if(this.tradeMenu === 'QUOTE') return
      else {
        const type = param.type
        const pageType = param.pageType
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), page:this.page, isAnd:this.isAnd, keywordList:this.quoteTagList})
        .then( async(res) => {
          let result = res.data
          if(result === undefined || result.length === 0) {
            if(pageType === 'prev' || pageType === 'next') {
              this.page -= 1
              alert('마지막 페이지입니다.')
              return
            } else {
              this.quoteList = []
              alert('검색 결과가 없습니다.')
            }
          } else {
            this.quoteList = []
            if(this.tradeMenu === 'SELECT_WORK_REQUEST') this.quoteList = result
            else {
              this.groupingQuote.clear()
              for(var i = 0; i < result.length; i++) {
                const quote = result[i]
                let country = quote.COUNTRY
                if(this.groupingQuote.has(country)) {
                  let valueList = this.groupingQuote.get(country)
                  valueList.push(quote)
                  this.groupingQuote.set(country, valueList)
                } else this.groupingQuote.set(country, [quote])
              }
            }
          }
        }).catch( (err) => {
          console.log(err)
          if(pageType === 'prev') this.page += 1
          else if(pageType === 'next') this.page -= 1
        })
      
      }
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1

      await this.search({pageType:type, type:this.tradeMenu})
      window.scrollTo(0, 0)
    },
    clear() {
      this.page = 1
      this.quoteList = []
      this.quoteTagList = []
      this.isAnd = true
      this.groupingQuote.clear()
    }
  }
}
</script>
<style scoped>
.quote-menu {
  width:100%;
  margin-right:5px;
  font-size: 0.9em;
}
.quote-title {
  font-weight: bold;
}
.quote-label {
  font-size: 0.9em;
}

.quote-section {
  display:flex;
  flex-wrap: wrap;
  margin-top:100px;
  margin-right:25px;
  margin-left:25px;
  margin-bottom:25px;
}
.warehouse-section {
  display:flex;
  flex-wrap: wrap;
  margin-bottom:25px;
}
@media screen and (min-width: 1400px) {
  .quote-column {
    width: 25%;
    padding: 10px;
    box-sizing: border-box;
  }
  .quote-content {
    height:100%;
    text-align: center;
    border-radius: 16px;
    border:2px solid gray !important;
    cursor: pointer;
    padding-top:20px;
    padding-left:30px;
    padding-right:30px;
  }
  .quote-country {
    font-weight:bolder;
    font-size: 20px;
    min-width:100px;
  }
  .quote-search {
    max-width:140px;
    min-height:40px;
    border-radius:16px;
    float:right;
    background-color:#ffc107;
    border:2px solid white;
    color:white;
    font-weight:bolder;
  }
}
@media screen and (max-width: 1400px) {
  .quote-column {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
  }
  .ads-column {
    width:100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .quote-content {
    height:100%;
    border:2px solid gray !important;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    padding-top:20px;
    padding-left:30px;
    padding-right:30px;
  }
  .quote-country {
    font-weight:bolder;
    font-size: 20px;
    min-width:100px;
  }
  .quote-search {
    max-width:140px;
    min-height:40px;
    border-radius:16px;
    float:right;
    background-color:#ffc107;
    border:2px solid white;
    color:white;
    font-weight:bolder;
  }
}
@media screen and (max-width: 840px) {
  .quote-column {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .quote-content {
    height:100%;
    border:2px solid gray !important;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    padding-top:20px;
    padding-left:10px;
    padding-right:10px;
  }
  .quote-country {
    font-weight:bolder;
    font-size: 13px;
    min-width:50px;
  }
  .quote-search {
    max-width:100%;
    min-height:40px;
    border-radius:16px;
    float:right;
    background-color:#ffc107;
    border:2px solid white;
    color:white;
    font-weight:bolder;
  }
}
#tagList {
  margin:10px;
  vertical-align: middle;
}
.quote-country-wrapper {
  display: flex; 
  align-items: center;
}
.quote-goods-wrapper {
  margin-top:20px;
  font-weight:bolder;
  font-size:20px;
}
.quote-type-wrapper {
  margin-bottom:20px;
  font-size:12px;
  color:blue;
}
.arrow {
  position: relative;
  width: 100%; /* 화살표의 길이 */
  height: 2px; /* 화살표의 두께 */
  background-color: green; /* 화살표의 색상 */
  margin: 0 20px; /* 화살표 좌우 여백 조절 */
  text-align: center;
}
.arrow::before {
  content: '';
  position: absolute;
  top: -4px; /* 위쪽으로 이동 */
  right: -10px; /* 오른쪽으로 이동 */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px; /* 화살표의 크기 및 모양 설정 */
  border-color: transparent transparent transparent green; /* 화살표의 색상 */
}
.quote-info > tr > td:nth-child(odd) {
  float:left;
}
.quote-info > tr > td:nth-child(even) {
  float:right;
}
.quote-content:hover {
  border:2px solid #ffc107 !important;
}
.quote-keywords {
  height:100px;
  border-radius: 16px;
}
.quote-keywords > td > label {
  cursor:pointer;
}
.quote-keyword {
  text-align: center;
  border-radius:16px;
  background-color:#f3f4f6;
  border:1px solid #f3f4f6;
}
.quote-keyword:hover {
  border:1px solid #ffc107 !important;
} 
.quote-keyword:focus {
  outline: none;
  border:2px solid #ffc107 !important;
}
.quote-search:hover {
  border:2px solid gray !important;
}

#modal_quoto_main {
  width:95%;
  max-width:600px;
}
#modal_quoto_main > p{
  text-align: left;
  font-style: italic;
  color:red;
  font-size: 12px;
}
.quoto-modal {
  width: 80%;
  max-width:600px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  border-radius: 9px;
}
.modal_button {
  width:20%;
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  cursor: pointer;
  padding:10px;
  margin-left:5px;
  margin-right:5px;
}
.trade-progress-content {
  width:100%;
  padding:20px;
  border: 1px solid black;
}
.step-panel {
  margin-top:20px;
}
/* .card {
  background: var(--card-bg);
  border: var(--card-border);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.quote-progress > Button {
  width:42px;
  margin-left:10px;
  border: 1px solid #ffc107;
  color:#ffc107;
}
.quote-progress > Button:hover {
  background-color: #ffc107;
  color:white;
  border: white;
} */
/* .p-button {
  background-color: white;
} */
/* .p-button[data-clicked="true"]:hover {
  background-color: #ffc107;
} */

</style>
  