<template>
<div id="workRequestC">
  <div style="width:100%; display:block;">
    <div class="card main" >
      <table style="width:100%;">
        <tr>
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;"  >
            <select ref="statusC" :value="json.STATUS" class="orderc-input" style="float:left;" @change="changeStatus()">
              <option value=-2>{{ this.api.getRequestStatus(-2) }}</option>
              <option value=-1>{{ this.api.getRequestStatus(-1) }}</option>
              <option value=0>{{ this.api.getRequestStatus(0) }}</option>
              <option value=1>{{ this.api.getRequestStatus(1) }}</option>
              <option value=2>{{ this.api.getRequestStatus(2) }}</option>
              <option value=3>{{ this.api.getRequestStatus(3) }}</option>
              <option value=4>{{ this.api.getRequestStatus(4) }}</option>
              <option value=5>{{ this.api.getRequestStatus(5) }}</option>
              <option value=6>{{ this.api.getRequestStatus(6) }}</option>
            </select>
            견적내용 
            <input type="button" value="삭제" style="float:right;max-width:50px;" @click="click({type:'DELETE_WORK_REQUEST'})"/>
            <input type="button" value="수정" style="float:right;max-width:50px;margin-right:5px;" @click="click({type:'UPDATE_WORK_REQUEST'})"/>
          </td>
        </tr>
        <tr>
          <td>품목종류</td>
          <td><input ref="goodsTypeC" class="orderc-input" type="text" :value="json.GOODS_TYPE" placeholder="품목종류" /></td>
          <td>견적유형</td>
          <td>
            <select ref="typeC" class="orderc-input">
              <option v-if="json.TYPE === ''" selected value=''>선택</option>
              <option v-else value=''>선택</option>
              <option v-if="json.TYPE === '3PL(풀필먼트)'" selected value='3PL(풀필먼트)'>3PL(풀필먼트)</option>
              <option v-else value='3PL(풀필먼트)'>3PL(풀필먼트)</option>
              <option v-if="json.TYPE === '수출입'" selected value='수출입'>수출입</option>
              <option v-else value='수출입'>수출입</option>
              <option v-if="json.TYPE === '배송'" selected value='배송'>배송</option>
              <option v-else value='배송'>배송</option>
              <option v-if="json.TYPE === '이사'" selected value='이사'>이사</option>
              <option v-else value='이사'>이사</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>보관온도</td>
          <td>
            <select ref="temperatureC" class="orderc-input">
              <option v-if="json.TEMPERATURE === ''" selected value=''>선택</option>
              <option v-else value=''>선택</option>
              <option v-if="json.TEMPERATURE === '상온'" selected value='상온'>상온</option>
              <option v-else value='상온'>상온</option>
              <option v-if="json.TEMPERATURE === '냉장'" selected value='냉장'>냉장</option>
              <option v-else value='냉장'>냉장</option>
              <option v-if="json.TEMPERATURE === '냉동'" selected value='냉동'>냉동</option>
              <option v-else value='냉동'>냉동</option>
              <option v-if="json.TEMPERATURE === '무관'" selected value='무관'>무관</option>
              <option v-else value='무관'>무관</option>
            </select>
          </td>
          <td>HSCODE</td>
          <td><input ref="hsCodeC" class="orderc-input" type="text" :value="json.HSCODE" placeholder="HSCODE" /></td>
        </tr>
        <tr>
          <td>보관/출발국가</td>
          <td><input ref="startCountryC" class="orderc-input" type="text" :value="json.START_COUNTRY" placeholder="보관/출발국가" /></td>
          <td>배송도착국가</td>
          <td><input ref="endCountryC" class="orderc-input" type="text" :value="json.END_COUNTRY" placeholder="배송도착국가" /></td>
        </tr>
        <tr>
          <td>전체무게(kg)</td>
          <td>
            <input ref="totalWeightC" class="orderc-input" type="number" :value="json.TOTAL_WEIGHT" placeholder="전체무게(KG)"/>
          </td>
          <td>전체부피(mm)</td>
          <td >
            <input ref="totalVolumeWidC" class="orderc-input" type="number" :value="json.TOTAL_VOLUME_WID" placeholder="가로" style="width:25%;margin-right:1px;text-align: center;"/>
            <input ref="totalVolumeDepC" class="orderc-input" type="number" :value="json.TOTAL_VOLUME_DEP" placeholder="세로" style="max-width:25%;margin-right:1px;text-align: center;"/>
            <input ref="totalVolumeHgtC" class="orderc-input" type="number" :value="json.TOTAL_VOLUME_HGT" placeholder="높이" style="max-width:25%;text-align: center;"/>
          </td>
        </tr>
        <tr>
          <td>개당무게(kg)</td>
          <td><input ref="unitWeightC" class="orderc-input" type="text" :value="json.UNIT_WEIGHT" placeholder="개당무게"/></td>
          <td>개당부피(mm)</td>
          <td>
            <input ref="unitVolumeWidC" class="orderc-input" type="number" :value="json.UNIT_VOLUME_WID" placeholder="가로" style="width:25%;margin-right:1px;text-align: center;"/>
            <input ref="unitVolumeDepC" class="orderc-input" type="number" :value="json.UNIT_VOLUME_DEP" placeholder="세로" style="max-width:25%;margin-right:1px;text-align: center;"/>
            <input ref="unitVolumeHgtC" class="orderc-input" type="number" :value="json.UNIT_VOLUME_HGT" placeholder="높이" style="max-width:25%;text-align: center;"/>
          </td>
        </tr>
        <tr>
          <td>예상월배송</td>
          <td>
            <input ref="orderCntC" class="orderc-input" type="number" :value="json.ORDER_CNT" placeholder="예상월배송건수" style="width:25%;margin-right:1px;text-align: center;"/>
            <select ref="orderUnitC" class="orderc-input" >
              <option v-if="json.ORDER_UNIT === ''" selected value=''>단위</option>
              <option v-else value=''>단위</option>
              <option v-if="json.ORDER_UNIT === '개'" selected value='개'>개</option>
              <option v-else value='개'>개</option>
              <option v-if="json.ORDER_UNIT === 'kg'" selected value='kg'>kg</option>
              <option v-else value='kg'>kg</option>
              <option v-if="json.ORDER_UNIT === 'pallets'" selected value='pallets'>pallets</option>
              <option v-else value='pallets'>pallets</option>
              <option v-if="json.ORDER_UNIT === 'container'" selected value='container'>container</option>
              <option v-else value='container'>container</option>
            </select>
          </td>
          <td>예상월보관</td>
          <td>
            <input ref="storageCntC" class="orderc-input"  type="number" :value="json.STORAGE_CNT" placeholder="예상월보관수량" style="width:25%;margin-right:1px;text-align: center;"/>
            <select ref="storageUnitC" class="orderc-input" >
              <option v-if="json.STORAGE_UNIT === ''" selected value=''>단위</option>
              <option v-else value=''>단위</option>
              <option v-if="json.STORAGE_UNIT === '개'" selected value='개'>개</option>
              <option v-else value='개'>개</option>
              <option v-if="json.STORAGE_UNIT === 'kg'" selected value='kg'>kg</option>
              <option v-else value='kg'>kg</option>
              <option v-if="json.STORAGE_UNIT === 'pallets'" selected value='pallets'>pallets</option>
              <option v-else value='pallets'>pallets</option>
              <option v-if="json.STORAGE_UNIT === 'container'" selected value='container'>container</option>
              <option v-else value='container'>container</option>

            </select>
          </td>
        </tr>
        <tr>
          <td>이름</td>
          <td>
            <input ref="orderNameC" class="orderc-input" type="text" :value="json.ORDER_NAME" placeholder="견적요청자 이름" />
          </td>
          <td>SNS</td>
          <td>
            <input ref="snsIdC" class="orderc-input"  type="text" :value="json.SNS_ID" placeholder="SNS ID" style="width:25%;margin-right:1px;text-align: center;"/>
            <select ref="snsServiceC" class="orderc-input" >
              <option v-if="json.SNS_SERVICE === ''" selected value=''>선택</option>
              <option v-else value=''>선택</option>
              <option v-if="json.SNS_SERVICE === '카카오톡'" selected value='카카오톡'>카톡</option>
              <option v-else value='카카오톡'>카톡</option>
              <option v-if="json.SNS_SERVICE === '위챗'" selected value='위챗'>위챗</option>
              <option v-else value='위챗'>위챗</option>
              <option v-if="json.SNS_SERVICE === '라인'" selected value='라인'>라인</option>
              <option v-else value='라인'>라인</option>
              <option v-if="json.SNS_SERVICE === '인스타'" selected value='인스타'>인스타</option>
              <option v-else value='인스타'>인스타</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>폰번호</td>
          <td><input ref="phoneC" class="orderc-input" type="tel" :value="json.PHONE" placeholder="견적요청자 폰번호" /></td>
          <td>이메일</td>
          <td><input ref="mailC" class="orderc-input" type="email" :value="json.MAIL" placeholder="견적요청자 이메일" /></td>
        </tr>
        <tr>
          <td colspan="4" style="background-color: aliceblue; padding-top:5px;"  >
            <select ref="quoteC" :value="json.FILE_NAME" class="orderc-input" style="float:left;" @change="changeQuote()">
              <option value="">견적서 선택</option>
              <option value="GEEK9_물류비_국내_냉장3PL소분.xlsx">GEEK9_물류비_국내_냉장3PL소분</option>
              <option value="GEEK9_물류비_국내_보관_배송_각인.xlsx">GEEK9_물류비_국내_보관_배송_각인</option>
              <option value="GEEK9_물류비_국내와인.xlsx">GEEK9_물류비_국내와인</option>
              <option value="GEEK9_물류비_한국_물류창고.xlsx">GEEK9_물류비_한국_물류창고</option>
              <option value="GEEK9_물류비_한국2글로벌_소량.xlsx">GEEK9_물류비_한국2글로벌_소량</option>
              <option value="GEEK9_물류비_한국2홍콩.xlsx">GEEK9_물류비_한국2홍콩</option>
              <option value="GEEK9_물류비_한국2싱가포르_명의대행_보관_대량.xlsx">GEEK9_물류비_한국2싱가포르_명의대행_보관_대량</option>
              <option value="GEEK9_물류비_한국2싱가포르_항공수출_식품_냉동.xlsx">GEEK9_물류비_한국2싱가포르_항공수출_식품_냉동</option>
              <option value="GEEK9_물류비_한국2싱가포르_냉동냉장.xlsx">GEEK9_물류비_한국2싱가포르_냉동냉장</option>
              <option value="GEEK9_물류비_한국2싱가포르_전자제품.xlsx">GEEK9_물류비_한국2싱가포르_전자제품</option>
              <option value="GEEK9_물류비_한국2미국_미국보관_소량.xlsx">GEEK9_물류비_한국2미국_미국보관_소량</option>
              <option value="GEEK9_물류비_중국2한국.xlsx">GEEK9_물류비_중국2한국</option>
              <option value="GEEK9_물류비_중국2홍콩_대량수출.xlsx">GEEK9_물류비_중국2홍콩_대량수출.xlsx</option>
              <option value="GEEK9_물류비_중국로컬3PL_냉동_상해.xlsx">GEEK9_물류비_중국로컬3PL_냉동_상해</option>
              <option value="GEEK9_물류비_중국2미국일본_화학제품.xlsx">GEEK9_물류비_중국2미국일본_화학제품</option>
              <option value="GEEK9_물류비_중국천진_부산_선박.xlsx">GEEK9_물류비_중국천진_부산_선박</option>
              <option value="GEEK9_물류비_3PL_미국2한국.xlsx">GEEK9_물류비_3PL_미국2한국</option>
              <option value="GEEK9_물류비_미국2한국_와인_배송_CA보관.xlsx">GEEK9_물류비_미국2한국_와인_배송_CA보관</option>
              <option value="GEEK9_물류비_미국2한국_와인_배송_뉴저지보관2.xlsx">GEEK9_물류비_미국2한국_와인_배송_뉴저지보관</option>
              <option value="GEEK9_물류비_미국3PL.xlsx">GEEK9_물류비_미국3PL</option>
              <option value="GEEK9_물류비_미국로컬3PL.xlsx">GEEK9_물류비_미국로컬3PL</option>
              <option value="GEEK9_물류비_미국2한국_3PL_건강기능식품.xlsx">GEEK9_물류비_미국2한국_3PL_건강기능식품</option>
              <option value="GEEK9_물류비_미국내배송_UPS.xlsx">GEEK9_물류비_미국내배송_UPS</option>
              <option value="GEEK9_물류비_싱가포르_3PL_냉동.xlsx">GEEK9_물류비_싱가포르_3PL_냉동</option>
              <option value="GEEK9_물류비_싱가포르_로컬_3PL_상온.xlsx">GEEK9_물류비_싱가포르_로컬_3PL_상온</option>
              <option value="GEEK9_물류비_싱가포르_로컬_3PL_상온2.xlsx">GEEK9_물류비_싱가포르_로컬_3PL_상온2</option>
              <option value="GEEK9_물류비_싱가포르_보관_로컬배송.xlsx">GEEK9_물류비_싱가포르_보관_로컬배송</option>
              <option value="GEEK9_물류비_싱가포르3PL_로컬배송.xlsx">GEEK9_물류비_싱가포르3PL_로컬배송</option>
              <option value="GEEK9_물류비_싱가포르_로컬_라스트마일.xlsx">GEEK9_물류비_싱가포르_로컬_라스트마일</option>
              <option value="GEEK9_물류비_홍콩2한국.xlsx">GEEK9_물류비_홍콩2한국</option>
              <option value="GEEK9_물류비_홍콩2홍콩.xlsx">GEEK9_물류비_홍콩2홍콩</option>
              <option value="GEEK9_물류비_홍콩2글로벌_배송비별도1.xlsx">GEEK9_물류비_홍콩2글로벌_배송비별도1</option>
              <option value="GEEK9_물류비_홍콩2글로벌_배송비별도2.xlsx">GEEK9_물류비_홍콩2글로벌_배송비별도2</option>
              <option value="GEEK9_물류비_한국2홍콩_전자상거래_명의대행.xlsx">GEEK9_물류비_한국2홍콩_전자상거래_명의대행</option>
              <option value="GEEK9_물류비_한국2홍콩_소량.xlsx">GEEK9_물류비_한국2홍콩_소량</option>
            </select>
            상세내용
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <textarea ref="descC" class="taRequest" placeholder="상세설명" style="width:100%;background-color:transparent;resize:none;height:auto;padding:5px;" :value="json.DESC" />
          </td>
        </tr>
      </table>
      <div style="margin-top:5px;text-align:center;">
        <input type="text" class="work-request-history-name" placeholder="제안물류업체명" v-on:keyup.enter="click({type:'INSERT_WORK_REQUEST_HISTORY'})" v-model="mgrName">
        <input type="text" class="work-request-history-content" placeholder="제안이력상세" v-on:keyup.enter="click({type:'INSERT_WORK_REQUEST_HISTORY'})" v-model="content">
        <label>{{ historyList.length }}건</label>
        <input type="button" class="orderc-input" @click="click({type:'INSERT_WORK_REQUEST_HISTORY'})" value="등록" style="float:right;">
      </div>
      <br>
      <div v-if="historyList.length === 0" class="work-request-history-list">
        제안내역이 없습니다
      </div>
      <span v-else>
        <table style="width:100%;">
          <tr v-for="(history, index) in historyList" :key="index" class="work-request-history-list" >
            <td style="border:0px;font-weight:normal;"><label style="float:left;margin-left:5px;">IDX.{{ history.IDX }}</label> &nbsp;&nbsp;{{ this.api.getDateByFormat(history.REG_DATE, 'YYYY.MM.DD') }} &nbsp;&nbsp; </td>
            <td style="border:0px;font-weight:normal;">{{ history.COMPANY_NAME }}</td>
            <td style="border:0px;font-weight:normal;">{{ history.DESC }}</td>
          </tr>
        </table>
      </span>
    </div> 
  </div>
</div>  
</template>
<script>
export default {
  mounted() {
    this.emitter.on('getHistory' + this.json.IDX, this.searchRequestHistory)
    this.$emit("taResize", {class:'taRequest'})
    this.originStatus = this.json.STATUS
    this.quoteStatus = this.json.FILE_NAME
  },
  unmounted() {
    this.emitter.off('getHistory' + this.json.IDX, this.searchRequestHistory)
  },
  data() { 
    return { 
      quoteStatus:undefined,
      originStatus:undefined,
      historyList:[],
      mgrName:'',
      file: undefined,
      fileName:'',
      content:''
    }
  },
  props: {
    json: {},
  },
  methods: {
    click(param) {
      const type = param.type
      const formData = new FormData()
      let workType = undefined
      if(type === 'UPDATE_WORK_REQUEST') {
        workType = '수정'
        const goodsType = this.$refs.goodsTypeC.value
        const requestType = this.$refs.typeC.value
        const temperature = this.$refs.temperatureC.value
        const hsCode = this.$refs.hsCodeC.value
        const startCountryC = this.$refs.startCountryC.value
        const endCountryC= this.$refs.endCountryC.value
        const totalWeight = this.$refs.totalWeightC.value
        const totalVolumeWid = this.$refs.totalVolumeWidC.value
        const totalVolumeDep = this.$refs.totalVolumeDepC.value
        const totalVolumeHgt = this.$refs.totalVolumeHgtC.value
        const unitWeight = this.$refs.unitWeightC.value
        const unitVolumeWid = this.$refs.unitVolumeWidC.value
        const unitVolumeDep = this.$refs.unitVolumeDepC.value
        const unitVolumeHgt = this.$refs.unitVolumeHgtC.value
        const orderCnt = this.$refs.orderCntC.value
        const orderUnit = this.$refs.orderUnitC.value
        const storageCnt = this.$refs.storageCntC.value
        const storageUnit = this.$refs.storageUnitC.value
        const orderName = this.$refs.orderNameC.value
        const snsId = this.$refs.snsIdC.value
        const snsService = this.$refs.snsServiceC.value
        const phone = this.$refs.phoneC.value
        const mail = this.$refs.mailC.value
        const desc = this.$refs.descC.value
        
        formData.append('totalVolumeDep', totalVolumeDep)
        formData.append('totalVolumeWid', totalVolumeWid)
        formData.append('totalWeight', totalWeight)
        formData.append('endCountry', endCountryC)
        formData.append('startCountry', startCountryC)
        formData.append('hsCode', hsCode)
        formData.append('temperature', temperature)
        formData.append('requestType', requestType)
        formData.append('goodsType', goodsType)
        formData.append('idx', this.json.IDX)
        formData.append('type', type)
        formData.append('snsService', snsService)
        formData.append('phone', phone)
        formData.append('mail', mail)
        formData.append('desc', desc)
        formData.append('snsId', snsId)
        formData.append('orderName', orderName)
        formData.append('storageUnit', storageUnit)
        formData.append('storageCnt', storageCnt)
        formData.append('orderUnit', orderUnit)
        formData.append('orderCnt', orderCnt)
        formData.append('unitVolumeHgt', unitVolumeHgt)
        formData.append('unitVolumeDep', unitVolumeDep)
        formData.append('unitVolumeWid', unitVolumeWid)
        formData.append('unitWeight', unitWeight)
        formData.append('totalVolumeHgt', totalVolumeHgt)
      } else if(type === 'DELETE_WORK_REQUEST') {
        workType = '삭제'
        formData.append('type', type)
        formData.append('idx', this.json.IDX)
      } else if(type === 'SELECT_WORK_REQUEST_HISTORY') {
        this.searchRequestHistory()
        return
      } else if(type === 'INSERT_WORK_REQUEST_HISTORY') {
        workType = '등록'
        formData.append('type', type)
        formData.append('srcCompanyName', this.mgrName)
        formData.append('srcDesc', this.content)
        formData.append('id', this.api.getCookies('id'))
        formData.append('requestIdx', this.json.IDX)
      } else if(type === 'DELETE_QUOTE') {
        if(this.json.FILE_NAME === undefined || this.json.FILE_NAME === '') alert('견적서가 없습니다.')
        else {
          let fileName = ''
          if(this.fileName === '') fileName = this.json.FILE_NAME
          else fileName = this.fileName

          const formData = new FormData()
          formData.append('fileName', fileName)
          formData.append('idx', this.json.IDX)
          formData.append('type', type)
          this.api.axiosGql(type, formData)
          this.fileName = ''
          this.$emit("search", {type:'SELECT_WORK_REQUEST', page:1})
        }
        return
      } else if(type === 'DOWNLOAD_QUOTE') {
        let fileName = ''
        if(this.fileName === '') fileName = this.json.FILE_NAME
        else fileName = this.fileName
        const link = document.createElement('a')
        link.href = this.api.WSRV_POCKETBASE_URL + fileName
        link.setAttribute('download', fileName)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        return
      }  
      if(confirm(workType + "할까요?")) {
        this.execute({type:type, workType:workType, json:formData})
      }
    },
    async execute(param) {
      const type = param.type
      const json = param.json
      const workType = param.workType
      await this.api.axiosGql(type, json)
      .then( async (res) => {
        if(res.data === null) alert('다시 시도해주세요.')  
        else if(type.indexOf('_HISTORY') === -1) {
          this.$emit("search", {type:'SELECT_WORK_REQUEST', page:1})
          alert(workType + ' 되었습니다.')
        } else {
          if(res.status === 200) {
            if(type === "INSERT_WORK_REQUEST_HISTORY") this.searchRequestHistory()
            alert('등록되었습니다.')
          } else alert('다시 시도해주세요.')
        }
      }).catch( (err) => {
        alert('다시 시도해주세요.')
        console.log(err)
      })
    },
    async searchRequestHistory() {
      await this.api.axiosGql("SELECT_WORK_REQUEST_HISTORY", {type:"SELECT_WORK_REQUEST_HISTORY", requestIdx:this.json.IDX})
      .then( async (res) => {
        if(res.data === null) alert('다시 시도해주세요.')  
        else if(res.status === 200) {
          this.historyList = res.data
        } else alert('다시 시도해주세요.')
      }).catch( (err) => {
        alert('다시 시도해주세요.')
        console.log(err)
      })
    },
    async changeQuote() {
      const fileName = this.$refs.quoteC.value
      if(confirm("등록할까요?" ) === true) {
        const type = 'UPDATE_WORK_REQUEST_FILE_NAME'
        await this.api.axiosGql(type, {type:type, idx:this.json.IDX, 'fileName':fileName})
        .then( async (res) => {
          if(res.data === null) alert('다시 시도해주세요.')  
          else {
            this.quoteStatus = status
            this.$emit("search", {type:'SELECT_WORK_REQUEST', page:1})
            alert('변경 되었습니다.')
          }
        }).catch( (err) => {
          alert('다시 시도해주세요.')
          console.log(err)
        })
      } else this.$refs.quoteC.value = this.originStatus
    },
    async changeStatus() {
      const status = Number(this.$refs.statusC.value)
      const type = 'UPDATE_WORK_REQUEST_STATUS'
      if(confirm(this.api.getRequestStatus(status) + "로 변경 할까요?" ) === true) {
        await this.api.axiosGql(type, {type:type, idx:this.json.IDX, status:status})
        .then( async (res) => {
          if(res.data === null) alert('다시 시도해주세요.')  
          else {
            this.originStatus = status
            this.$emit("search", {type:'SELECT_WORK_REQUEST', page:1})
            alert('변경 되었습니다.')
          }
        }).catch( (err) => {
          alert('다시 시도해주세요.')
          console.log(err)
        })
      } else this.$refs.statusC.value = this.originStatus
    },
  }
}
</script>

<style scoped>
.orderc-input {
  text-align: center;
  max-width:fit-content;
  padding-top:5px;
  padding-bottom:5px;
}
.work-request-history-list > label {
  margin-left:20px;
}
/* .work-request-history-list > td {
  border:0px;
} */
.work-request-history-list {
  width:100%;
  /* padding-top:10px;
  padding-bottom:10px; */
  border-top:2px solid white;
  background-color:aliceblue;
}
.work-request-history-name {
  text-align: center;
  padding:5px;
  width:20%;
  margin-right:5px;
}
.work-request-history-content {
  text-align: center;
  padding:5px;
  width:50%;
  margin-right:5px;
}
.orderc-chkbox {
  width:fit-content;
  vertical-align: middle;
}
td:nth-child(even){
  width:25%;
  border: 1px dotted #ffa07a; 
  padding-top:5px;
  padding-bottom:5px;
}
td:nth-child(odd){
  text-align: center;
  width:25%;
  border: 1px dotted #ffa07a; 
  font-weight:bold;
}
tr {
  height:30px;
}
table {
  border-collapse: collapse;
}
table tr:first-child td {
  border-top: 0;
}
table tr td:first-child {
  border-left: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:last-child {
  border-right: 0;
}
</style>