<template>
<div>
  <div class="div_title">
    <context :context=cate />
    <policies/>
  </div>
  <Tabs :value="menuIdx" @click="click({type:'menu'})">
    <TabList>
      <Tab value=0>홈피주문</Tab>
      <Tab value=1>카톡주문</Tab>
    </TabList>
    <TabPanels class="card main" >
      <TabPanel value=0>
        <div ref="list_menu">
          <Button label="조회" v-on:click="refresh" raised size="small" style="float:right;" ></Button>
          <select v-model="date" class="select-keyword">
            <option value = "r1">최근 1개월</option>
            <option value = "r2">최근 3개월</option>
            <option value = "r3">최근 6개월</option>
            <option value = "r4">최근 1년</option>
          </select>
        </div>
        <br><br>
        <div style="width:100%; ">
          <Timeline :value="progressList" layout="horizontal" align="bottom" >
            <template #marker="slotProps">
              <span class="flex w-8 h-8 items-center justify-center text-white rounded-full z-10 shadow-sm" style="cursor: pointer" >
                <i @click="click({type:'progress',val:slotProps.item })" :class="slotProps.item.icon"></i>
              </span>
            </template>
            <template #content="slotProps">
              <span @click="click({type:'progress',val:slotProps.item })" style="cursor: pointer;">
                <label class="progress-font" v-html="slotProps.item.status" />
              </span>
            </template>
          </Timeline>
        </div>
        <dashboard page="list" :key='dashboardKey' @setOrder="setOrder" @login="this.$emit('login')"/>
      </TabPanel>
      <TabPanel value=1>
        <kaorder :key='kakaoOrderKey' @setOrder="setOrder" @login="this.$emit('login')"/>
      </TabPanel>
    </TabPanels>
  </Tabs>

  <div class="div_title">
    <span style="color:#ff7f50;">주문내역&nbsp;<label style="color:black;font-weight: normal;">(주문 클릭시 상세내역 조회 가능)</label></span>
    <span v-if="status > -3"><Tag severity="success" :value="this.api.getOrderStatus(this.status)"/></span>
  </div>
  <div :key="groupingKey">
    <login v-if="!(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true)" pageKey="list" /> 
    <div v-else-if="this.groupingOrders.size === 0" class="card main no-result">
      <p>상세 조회 내역이 없습니다</p>
      <p>위에서 주문내역을 클릭해주세요</p>
    </div>
    <div v-else class="card main" style="text-align:left;">
      <DataTable >
        <Column field="" header="주문유형" />
        <Column field="" header="합배송" />
        <Column field="" header="배송비(원)" />
        <Column field="" header="나라"  />
        <Column field="" header="배송조회"  />
      </DataTable>
      <Accordion v-model:activeIndex="openPanels" value="0" :key="groupingOrdersKey">
        <AccordionPanel :value="groupingOrders.get(group[0])[0].IDX + ''" v-for="(group, key) in groupingOrders" :key="key" >
          <AccordionHeader 
            :style="[
              key%2 === 1 ? 'background-color: #f9f9f9;border-radius: 10px;' : 'border-radius: 10px;',
              openPanels === group[1][0].IDX ? 'background-color: #ffa07a; color: #2563EB;' : ''
              // openPanels === group[1][0].IDX ? 'background-color: #EBF5FF; color: #2563EB;' : ''

              
            ]" > 
            <div class="table-content" style="width:100%;">
              <div style="width:18%;margin-left:5px;margin-right:5px;">{{ groupingOrders.get(group[0])[0].ORDER_TYPE }}</div>
              <div style="width:auto;margin-left:5px;margin-right:5px;text-align:left;"><Tag severity="info" :value='getOrdersLength(group)' ></Tag></div>
              <div style="width:19%;margin-left:5px;margin-right:5px;text-align:center;"><span :id="`deliveryPrice-${key}`" class="deliveryPrice">0</span></div>
              <div style="width:18%;margin-left:5px;margin-right:5px;text-align:center;">{{ groupingOrders.get(group[0])[0].COUNTRY }}</div>
              <div style="width:20%;margin-left:5px;margin-right:5px;" v-if="groupingOrders.get(group[0])[0].INVOICE_NUMBER.indexOf('index.html') !== -1 && (groupingOrders.get(group[0])[0].STATUS === '배송중' || groupingOrders.get(group[0])[0].STATUS === '거래완료')" >
                <a :href="groupingOrders.get(group[0])[0].INVOICE_NUMBER" target="_blank">클릭</a> 
              </div>
              <div style="width:20%;margin-left:5px;margin-right:5px;" v-else-if="groupingOrders.get(group[0])[0].INVOICE_NUMBER.indexOf('http') !== -1 && (groupingOrders.get(group[0])[0].STATUS === '배송중' || groupingOrders.get(group[0])[0].STATUS === '거래완료')" >
                <a :href="groupingOrders.get(group[0])[0].INVOICE_NUMBER" target="_blank">클릭</a> 
              </div>
              <div style="width:20%;margin-left:5px;margin-right:5px;" v-else-if="groupingOrders.get(group[0])[0].INVOICE_NUMBER.indexOf('http') === -1 && (groupingOrders.get(group[0])[0].STATUS === '배송중')" >
                <a :href="this.api.getDeliveryUrl(groupingOrders.get(group[0])[0].INVOICE_NUMBER, groupingOrders.get(group[0])[0].DELIVERY_KR_COMPANY)" target="_blank">클릭</a>
              </div>
              <div style="width:15%;margin-left:5px;margin-right:5px;" v-else>발급전</div>
            </div>
          </AccordionHeader>
          <AccordionContent style="background-color: var(--p-datatable-body-cell-border-color);border-radius: 10px;">
            <div class="flex-item card" style="width:100%;margin-top:15px;margin-bottom:-5px;">
              <span class="link" @click="click({type:'modify', val:groupingOrders.get(group[0]) })" style="float:right;margin-left:10px;">수정</span>
              <span class="link" @click="click({type:'orderInfo', val: groupingOrders.get(group[0]), deliveryIdx:key })" style="float:right;">상세정보</span>
              <span class="link" @click="click({type:'file', val:groupingOrders.get(group[0])[0].FILE_NAME })" v-if="!(groupingOrders.get(group[0])[0].FILE_NAME === undefined || groupingOrders.get(group[0])[0].FILE_NAME === null)" >첨부파일</span>&nbsp;&nbsp;
              <img alt="geek9.kr" class="twinkle link" @click="click({type:'msg', val:groupingOrders.get(group[0])[0].MSG})" style="width: 20px;" v-if="groupingOrders.get(group[0])[0].MSG !== '' && groupingOrders.get(group[0])[0].MSG !== null " src="assets/icon/new-email.png"/>
            </div>
            <AccordionPanel :value="(index+1) + ''" v-for="(order, index) in groupingOrders.get(group[0])" :key="order.IDX">
              <AccordionHeader style="border-radius: 10px;margin-top:20px;" disabled> 
                <orderc @setValue="setValue" :index="index" :deliveryIdx="key" :idx="order.IDX" :orderType="order.ORDER_TYPE" :uid="order.UID" :name="order.NAME" :orderNumber="order.ORDER_NUMBER" :amount="order.AMOUNT" :invoiceNumber="order.INVOICE_NUMBER" :trackingNumber="order.TRACKING_NUMBER" :clearanceNumber="order.CLEARANCE_NUMBER" :deliveryKrCompany="order.DELIVERY_KR_COMPANY" :deliveryForeignCompany="order.DELIVERY_FOREIGN_COMPANY" :orderDate='order.ORDER_DATE' :imgUrl='order.IMG_URL' :fprice='order.ORIGIN_PRICE' :wonPrice='order.SELL_PRICE' :url='order.URL' :status='order.STATUS' :country='order.COUNTRY' :deliveryPrice2='order.DELIVERY_PRICE2' :progress="order.PROGRESS" :notice="order.NOTICE" :msg="order.MSG" :key="ordercKey"/>
              </AccordionHeader>
            </AccordionPanel>
            <div class="no-result" v-if="payment({type:'delivery', val:groupingOrders.get(group[0])}) === 0" >
              <br>
              배송비 결제 부탁드립니다!!
              <br><br>
              카카오뱅크 3333-24-9473287 하나됨(배성호)<br><br>
              <select v-model="pay" v-on:change="paySelected()" style="padding:5px;"> 
                <option value = "">간편결제선택</option>
                <option value = "qr.kakaopay.com/Ej79lhQuc" v-if="isMobile">카카오페이(모바일)</option>
                <option value = "geek9.kr/img/kakao_pay.46960bf8.png">카카오페이(QR)</option>
                <option value = "geek9.kr/img/toss_pay.41a340ba.png">토스(QR)</option>
              </select>
              <img src="./assets/kakao_pay.png" style="display: none;" alt="geek9.kr">
              <img src="./assets/toss_pay.png" style="display: none;" alt="geek9.kr">
            </div>
            <div class="no-result" v-else-if="payment({type:'delivery', val:groupingOrders.get(group[0])}) === 1" >
              <br>
              배송비가 곧 업데이트 됩니다!
            </div> 
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <br>
      <div class="div_center" v-if="this.groupingOrders.size !== 0">
        <label @click="paging('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{page}}</span>
        <label @click="paging('next')" class="link">
          다음
        </label>
      </div>
    </div>
  </div>
  <!-- <div class="div_title" >
    <span style="color: #ff7f50;">주문알림</span> 
    <Button v-on:click="click({type:'msgSearch'})" label="조회" size="small" />
  </div>
  <div :key='dashboardKey'>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" class="card main"> 
      <div v-if="msgList.length === 0" class="no-result">
        <span>읽지 않은 메시지가 없습니다</span>
      </div>
      <div v-else id="msg-list" style="text-align:center">
        <DataTable v-model:expandedRows="expandedRows" :value="msgList" dataKey="IDX" stripedRows @rowExpand="onRowExpand">
          <Column expander style="width:5rem;text-align: center;" />
          <Column field="TITLE" header="" style="text-align: center;" />
          <template #expansion="slotProps" >
            <noticec @noticeClick="click" :imgUrl=slotProps.data.IMG_URL :content=slotProps.data.MSG type="list" :idx="slotProps.data.IDX" :regDate="slotProps.data.REG_DATE" :country='slotProps.data.COUNTRY' :url="slotProps.data.URL" :orderType="slotProps.data.ORDER_TYPE" :status="slotProps.data.STATUS" class="content" :key="noticecKey"/>
          </template>
        </DataTable>
        <br>
        <div class="div_center">
          <label @click="msgPaging('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{msgPage}}</span>
          <label @click="msgPaging('next')" class="link">
            다음
          </label>
        </div>
      </div>
    </div>
    <div v-else class="container_no_login card main"> 
      <span class="link" @click="this.$emit('login')" >로그인해주세요</span>
    </div>
  </div>
  <div class="div_title">
    <span style="color: #ff7f50;">판매자 문의 답변</span> 
    <Button v-on:click="click({type:'questionSearch'})" label="조회" size="small" />
  </div>
  <div :key='dashboardKey'>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" class="card main"> 
      <div v-if="questionList.length === 0"  style="text-align:center;">
        <span>판매자 답변이 없습니다</span>
      </div>
      <div v-else id="question-list" >
        <DataTable v-model:expandedRows="expandedRows" :value="questionList" dataKey="IDX" stripedRows @rowExpand="onRowExpand">
          <Column expander style="width: 5rem;" />
          <Column field="REG_DATE" header="문의일" />
          <Column field="DESC" header="문의내용" />
          <Column field="REPLY_YN" header="답변" />
          <template #expansion="slotProps" >
            <noticec @noticeClick="click" type="list2" :question="JSON.parse(JSON.stringify(slotProps.data))" class="content" />
          </template>
        </DataTable>
      </div>
      <br>
      <div class="div_center">
        <label @click="pagingQuestion('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{questionPage}}</span>
        <label @click="pagingQuestion('next')" class="link">
          다음
        </label>
      </div>
    </div>
    <div v-else class="container_no_login card main"> 
      <span class="link" @click="this.$emit('login')" >로그인해주세요</span>
    </div>
  </div> -->
  <Modal v-model='isShow' :close='closeModal' style="z-index: 99999999999;">
    <div id="modal_main" class="modal" >
      <div class="div_title" style="text-align: left;margin-top:-20px;">
        <span style="color: #ff7f50;">알림</span>
      </div>
      <br>
      <p v-html="msg" style="white-space: pre;"></p>
      <br>
      <div class="div_center">
        <Button @click="closeModal()" raised size="small">닫기</Button>
      </div>
    </div>
  </Modal>
  <Modal v-model='isOrderInfoShow' style="z-index: 99999999999;">
    <div class="modal">
      <div class="div_title" style="text-align: left;margin-top:-20px;"><span style="color:#ff7f50;">상세정보</span> </div>
      <Tabs :value="modalIdx">
        <TabList>
          <Tab value=0>주문</Tab>
          <Tab value=1>비용</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value=0>
            <br>
            <table id="list_table" class="table list" style="width:100%;">
              <tr class="list_tr">
                <td style="min-width: 100px;">IDX</td>
                <td>{{ orderInfo.idx}}</td>
              </tr>
              <tr>
                <td>주문일</td>
                <td>{{ this.api.getDateByFormat(orderInfo.orderDate, 'YYYY.MM.DD') }}</td>
              </tr>
              <tr>
                <td>대행국가</td>
                <td>{{ orderInfo.country }}</td>
              </tr>
              <tr v-if="!(orderInfo.deliveryKrCompany === '' || orderInfo.deliveryKrCompany === '없음' || orderInfo.deliveryKrCompany === '선택')">
                <td>국내배송사</td>
                <td>{{ orderInfo.deliveryKrCompany }}</td>
              </tr>
              <tr v-if="orderInfo.invoiceNumber.indexOf('발급전') === -1 && orderInfo.invoiceNumber !== '' ">
                <td>송장번호</td>
                <td v-if="orderInfo.invoiceNumber.indexOf('/') === -1">{{ orderInfo.invoiceNumber }}</td>
                <td v-else><a :href="orderInfo.invoiceNumber" target="_blank" >조회하기</a></td>
              </tr>
              <tr v-if="orderInfo.deliveryPrice !== '0'">
                <td>배송비</td>
                <td>{{ orderInfo.deliveryPrice}}</td>
              </tr>
              <tr>
                <td>진행상태</td>
                <td>{{ orderInfo.status }}</td>
              </tr>
              <tr>
                <td>수취인</td>
                <td>{{ orderInfo.orderName }} </td>
              </tr>
              <tr>
                <td>연락처</td>
                <td>{{ orderInfo.phone }}</td>
              </tr>
              <tr>
                <td>통관부호</td>
                <td>{{ orderInfo.clearance }}</td>
              </tr>
              <tr>
                <td>주소</td>
                <td>( {{orderInfo.addrZip }} ) {{ orderInfo.addrRoad }}</td>
              </tr>
              <tr>
                <td>상세주소</td>
                <td>{{ orderInfo.addrDetail }}</td>
              </tr>
              <tr>
                <td>특이사항</td>
                <td>{{ String(orderInfo.notice).replace('이지영','포워더').replace('정서현','포워더').replace('조재우','포워더') }}</td>
              </tr>
              <tr>
                <td>알림</td>
                <td v-html="orderInfo.msg"/>
              </tr>
            </table>
          </TabPanel>
          <TabPanel value=1>
            <br>
            <div v-if="totalExtraPrice === null || totalExtraPrice === undefined">
              <div class="no-result">2024/11 이후의 주문부터 제공되는 정보입니다;;</div>
            </div>
            <table v-else id="list_table" class="table list" style="width:100%;" >
              <tr class="list_tr" >
                <td style="width: 200px;">착불</td>
                <td>{{ Number(totalExtraPrice.ARRIVAL_PRICE).toLocaleString() }} {{ this.api.getCurrency(totalExtraPrice.COUNTRY ) }}</td>
              </tr>
              <tr>
                <td>사진촬영</td>
                <td>{{ Number(totalExtraPrice.PICTURE_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>해외판매자 수수료</td>
                <td>{{ Number(totalExtraPrice.SITE_PRICE).toLocaleString() }} {{ this.api.getCurrency(totalExtraPrice.COUNTRY ) }}</td>
              </tr>
              <tr>
                <td>포워더 수수료</td>
                <td>{{ Number(totalExtraPrice.FORWARDER_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>추가포장</td>
                <td>{{ Number(totalExtraPrice.PACKAGE_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>환불비</td>
                <td>{{ Number(totalExtraPrice.REFUND_PRICE).toLocaleString() }} {{ this.api.getCurrency(totalExtraPrice.COUNTRY ) }}</td>
              </tr>
              <tr>
                <td>통관/관세/부가세</td>
                <td>{{ Number(totalExtraPrice.CLEARANCE_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>소비자변심</td>
                <td>{{ Number(totalExtraPrice.CONSUMER_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>보관비</td>
                <td>{{ Number(totalExtraPrice.STORAGE_PRICE).toLocaleString() }} {{ this.api.getCurrency(totalExtraPrice.COUNTRY ) }}</td>
              </tr>
              <tr>
                <td>보험비</td>
                <td>{{ Number(totalExtraPrice.INSURANCE_PRICE).toLocaleString() }}원</td>
              </tr>
              <tr>
                <td>배송비</td>
                <td>{{ totalExtraPrice.DELIVERY_PRICE === '0' || totalExtraPrice.DELIVERY_PRICE === 0 ? `미정 (현재 ${totalExtraPrice.STATUS} 상태)` : totalExtraPrice.DELIVERY_PRICE + '원' }}</td>
              </tr>
            </table>
            <br>
            <div style="text-align:left;color:#ff7f50;" class="card" >
              <p>1. 대행비에 포함 : 착불, 해외판매자 수수료, 포워더 수수료</p>
              <p>2. 배송비에 포함 : 사진촬영, 추가포장, 보험</p>
              <p>3. 별도 비용 : 환불비, 통관/관세/부가세, 소비자변심, 보관비</p>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <div class="ad-container">
        <Adsense
          data-ad-client="ca-pub-6699354447815429" 
          data-ad-slot="8230887469"
          data-ad-format="horizontal"
          data-full-width-responsive="true">
        </Adsense>
      </div>
      <br>
      <span>
        <Button @click="closeOrderInfoModal()" label="닫기" raised></Button>
      </span>
    </div>
  </Modal>  
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import orderc from '@/components/content/order_content.vue'
import dashboard from './components/home/dashboard.vue'
import kaorder from './components/list/kaorder.vue'
import { ref, inject } from 'vue'
import router from './router'
// import noticec from '@/components/content/notice_content.vue'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import login from '@/components/common/Login.vue'

import ssr from './components/common/ssr'

export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  mounted() {
    this.addJsonLd()
    this.emitter.on('listReload', this.reload)
    this.api.setPage('list')
    // this.$emit("setMenu", {page:'list'})
    console.log(this.api.getCookies('page'))
    if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) {
      
      let ordersJson = JSON.parse(JSON.stringify(this.$route.params))
      // let flag = true
      this.orders = Object.keys(ordersJson).map( (key) => {
        // flag = false
        let order = JSON.parse(ordersJson[key])
        this.date = order.range
        this.status = order.STATUS
        if(this.status !== undefined) order.STATUS = this.api.getOrderStatus(order.STATUS)
        return order
      })
      this.groupByUid()
      this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date})
      // this.click({type:'msgSearch'})
      // this.click({type:'questionSearch'})
      window.scrollTo(0, 0)
      // if(flag === true) this.listKey++
    }
  },
  unmounted() {
    this.emitter.off('listReload', this.reload)
  },
  components: {
    dashboard, orderc, kaorder, login,
    // noticec, 
    context, policies
  },
  setup() {
    const openPanels = ref([])
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 마이페이지'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트,주문,조회,구매,대행신청'
    const description = '개인 해외직구 구매대행/배송대행 주문 내역'
    const geek9Url = ssr.GEEK9_URL + 'list'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const isMobile = ref(false)
    const userAgent = navigator.userAgent
    const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    isMobile.value = mobileKeywords.some(keyword => userAgent.includes(keyword))
    
    let orders = ref([])
    let groupingOrders = new Map()
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    let loginId = ref(undefined)
    const isShow = ref(false)
    const isOrderInfoShow = ref(false)
    let orderInfo = ref([])
    const showOrderInfoModal = async (param) => {
      isOrderInfoShow.value = true;
      orderInfo.value = param.orderInfo
    }
    const closeOrderInfoModal = async () => {
      isOrderInfoShow.value = false
    }
    const showModal = async () => {
      isShow.value = true;
    }
    const closeModal = async () => {
      isShow.value = false
    }
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    const expandedRows = ref({})
    const ranges = ref([
      { name: '최근 1개월', val:'r1' },
      { name: '최근 3개월', val:'r2' },
      { name: '최근 6개월', val:'r3' },
      { name: '최근 1년', val:'r4' }
    ])
    const cate = ref([
      { label: '마이페이지'},
      { label: '구매', route: '/list' }
    ])
    return {
      orders, orderInfo, onRowExpand, groupingOrders, isShow, isOrderInfoShow, showModal, closeModal, showOrderInfoModal, closeOrderInfoModal, 
      loginId, isMobile, expandedRows, ranges, cate, addJsonLd, openPanels
    }   
  },
  // 0 : 주문접수
  // 1 : 입금대기
  // 2 : 입금완료
  // 3 : 구매완료
  // 4 : 입고대기
  // 5 : 창고도착
  // 6 : 배송중
  // 7 : 거래완료
  // 8 : 거래취소
  // 9 : 환불완료
  data() {
    return {
      groupingKey:0,
      modalIdx:'0',
      menuIdx:'0',
      groupingOrdersKey:-1,
      ccc:0,
      sellPriceIdx:0,
      totalSellPriceList:[],
      totalExtraPrice:{},
      totalWonPrice:0,
      pay: '',
      date: 'r2',
      // listKey: 0,
      page: 1,
      msgPage: 1,
      questionPage: 1,
      dashboardKey: -1,
      kakaoOrderKey: -1,
      status: -3,
      msg: '',
      uidList: Array(),
      msgList: Array(),
      questionList: Array(),
      noticecKey:-1,
      ordercKey:-1,
      progressList:[
        {status:'주문<br>접수', icon:'pi pi-inbox'}, 
        {status:'입금<br>대기', icon:'pi pi-question'}, 
        {status:'입금<br>완료', icon:'pi pi-check'}, 
        {status:'구매<br>완료', icon:'pi pi-credit-card'}, 
        {status:'입고<br>대기', icon:'pi pi-history'}, 
        {status:'창고<br>도착', icon:'pi pi-box'}, 
        {status:'배송<br>중',   icon:'pi pi-truck'}, 
        {status:'거래<br>완료', icon:'pi pi-home'}
      ]
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    async click(param) {
      const type = param.type
      if(type === 'msg') {
        this.showModal()
        this.msg = param.val + '<br><br><br><a href="https://geek9.kr/qna" target="_blank">문의하기</a>'
      } else if(type === 'progress') {
        this.emitter.emit('dialog', {flag:true})
        const status = param.val.status.replace('<br>','')
        this.emitter.emit('distinct' + this.api.getCookies('page'), Number(this.api.getOrderStatus(status)))
      } else if(type === 'file') {
        let fileName = param.val 
        const lowerFileName = String(fileName).toLowerCase()
        if(lowerFileName.endsWith('jpg') || lowerFileName.endsWith('jpeg') || lowerFileName.endsWith('png')) {
          const img = new Image()
          const imgUrl = this.api.WSRV_POCKETBASE_URL + fileName
          window.open(imgUrl, '_blank', `location=no;width:${img.width}px;height:${img.height}px;`)
        } else {
          const link = document.createElement('a')
          // link.href = this.api.WSRV_POCKETBASE_URL + fileName
          link.href = this.api.POCKETBASE_URL + ':8090/api/files/' + fileName
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        }
      } else if(type === 'modify') {
        let groupedOrders = param.val 
        let flag = true
        let orderArray = new Array()
        groupedOrders.map( order => {
          if(order.STATUS === '입금완료' || order.STATUS === '창고도착' || order.STATUS === '구매완료' || (order.STATUS === '입고대기' && order.TRACKING_NUMBER.length > 4) || order.STATUS === '배송중' || order.STATUS === '거래완료' || order.STATUS === '거래취소' || order.STATUS === '환불완료') {
            this.emitter.emit('confirm', {message: order.STATUS + '인 주문은 수정이 안됩니다.', header:'수정', icon:'pi pi-exclamation-triangle'})
            flag = false
            return
          } else orderArray.push(JSON.stringify(order))
        })
        if(flag) router.push({name:"modify", params: orderArray})
        else this.emitter.emit('confirm', {message: '해당 건은 수정이 안됩니다. QnA 게시판에 문의해주세요.', header:'수정', icon:'pi pi-exclamation-triangle'})
      } else if(type === 'orderInfo') {
        let groupedOrder = param.val[0]
        let deliveryIdx = Number(param.deliveryIdx)
        let totDeliveryPrice = document.getElementById('deliveryPrice-' + deliveryIdx)
        const presDeliveryPrice = totDeliveryPrice.innerHTML
        const idx = groupedOrder.IDX
        const orderDate = groupedOrder.ORDER_DATE
        const uid = groupedOrder.UID
        const invoiceNumber = groupedOrder.INVOICE_NUMBER
        const deliveryKrCompany = groupedOrder.DELIVERY_KR_COMPANY
        const status = groupedOrder.STATUS
        const country = groupedOrder.COUNTRY
        const msg = groupedOrder.MSG
        const notice = groupedOrder.NOTICE

        await this.api.axiosGql('SELECT_ORDER_INFO_BY_UID', {type:'SELECT_ORDER_INFO_BY_UID', ID: "", UID:uid})
        .then( async (res) => {
          let result = res.data
          const orderInfo = {
            idx:idx, 
            orderDate:orderDate,
            status:status,
            deliveryKrCompany:deliveryKrCompany,
            invoiceNumber:invoiceNumber,
            deliveryPrice:presDeliveryPrice,
            country:country,
            msg:msg,
            notice:notice,
            orderName:result.ORDER_NAME,
            phone:result.PHONE,
            clearance:result.CLEARANCE,
            addrZip:result.ADDR_ZIP,
            addrRoad:result.ADDR_ROAD,
            addrDetail:result.ADDR_DETAIL,
          }
          this.showOrderInfoModal({orderInfo:orderInfo})
        }).catch( (err) => {
          console.log(err)
          this.$notify('다시 시도해주세요')
          this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:'안내', icon:'pi pi-exclamation-triangle'})
        })

        await this.api.axiosGql('SELECT_ORDER_PRICE_DTL', {type:'SELECT_ORDER_PRICE_DTL', uid:uid})
        .then( async (res) => {
          if(res.status === 200) {
            this.totalExtraPrice = res.data
            if(this.totalExtraPrice !== null) {
              this.totalExtraPrice.DELIVERY_PRICE = presDeliveryPrice
              this.totalExtraPrice.COUNTRY = country 
              this.totalExtraPrice.STATUS = status 
            }
          }
        })
        
      } else if(type === 'SELECT_ORDER_BY_ID2') {
        await this.api.axiosGql(type, {type: type, id:this.api.getCookies('id'), })    
        .then( async (res) => {
          const json = res.data
          if(json === null || json === 'null' || json === undefined || json.length === 0) {
            this.$notify('데이터가 없습니다.')
            if(this.page === 1) {
              this.orderList = Array()
              this.totalCnt = 0
              this.orderedMap.clear()
            } else this.page -= 1
          } else this.orderList = json
        })
      } else if(type === 'msgSearch') this.msgSearch({type:type, page:1})
      else if(type === 'questionSearch') this.questionSearch({type:type, page:1})
      else if(type === 'menu') {
        this.groupingOrders.clear()
        this.groupingKey++
        // this.listKey++
      }
    },
    // menu_click(menu_idx) {
    //   this.orders = ref([])
    //   this.groupingOrders.clear()
    //   // this.listKey++
    //   this.menuIdx = menu_idx
    //   if(this.menuIdx === 0) {
    //     this.api.setPage('list')
    //     this.$refs.list_menu.style.visibility = "visible"
    //   } else {
    //     this.api.setPage('list2')
    //     this.$refs.list_menu.style.visibility = "hidden"
    //   }
    //   for(var i=0; i<2; i++) {
    //     let menu = document.getElementById('menu' + i)
    //     let sub_menu = document.getElementById('sub_menu' + i)
    //     if(i === menu_idx) {
    //       menu.setAttribute("class", "active")
    //       sub_menu.style.display = "block"
    //     } else {
    //       menu.setAttribute("class", "")
    //       sub_menu.style.display = "none"
    //     }
    //   }
    // },
    async refresh() {
      this.emitter.emit('dialog', {flag:true})
      this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date})
      this.orders = ref([])
      this.groupingOrders.clear()
      // this.listKey++
      this.page = 1
    },
    async groupByUid() {
      this.groupingOrders.clear()

      for(var i = 0; i < this.orders.length; i++) {
        const order = this.orders[i]
        let uid = order.UID
        if(this.groupingOrders.has(uid)) {
          let valueList = this.groupingOrders.get(uid)
          valueList.push(order)
          this.groupingOrders.set(uid, valueList)
        } else this.groupingOrders.set(uid, [order])
      }
      this.ordercKey++
    },
    async setOrder(orders, status, uidList) {
      this.clearDeliveryFee()
      
      this.orders = []
      this.orders = orders
      
      await this.groupByUid()
      this.page = 1
      this.status = status
      if(uidList !== undefined) this.uidList = uidList
      this.groupingOrdersKey++
      this.emitter.emit('dialog', {flag:false})
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.dashboardKey += 1
        this.kakaoOrderKey += 1
        this.groupingKey += 1
        this.$emit("setMenu", {page:'list'})
      }
    },
    // async questionSearch(param) {
    //   const type = param.type
    //   this.questionPage = param.page
    //   await this.api.axiosGql("SELECT_TRADE_MSG", {type:"SELECT_TRADE_MSG", clientId:this.api.getCookies('id'), page:Number(this.questionPage), replyYn:0})
    //   .then( async (res) => {
    //     let result = res.data
    //     if(result === undefined || result.length === 0) {
    //       if(type === 'prev' || type === 'next') {
    //         this.questionPage -= 1
    //         this.$notify('마지막 페이지입니다.')
    //         alert('마지막 페이지입니다.')
    //       }
    //       return
    //     } else {
    //       let returnList = []
    //       for(let i=0; i<result.length; i++) {
    //         const tmpRes = result[i]
    //         const tradeIdx =  Number(tmpRes.TRADE_IDX)
    //         await this.api.axiosGql('SELECT_TRADE_BY_IDX', {type:'SELECT_TRADE_BY_IDX', idx:tradeIdx, useYn:1})
    //         .then( async (res) => {
    //           tmpRes.TRADE = res.data[0]
    //           returnList.push(tmpRes)
    //         })
    //         .catch( (err) => {
    //           console.log(err)
    //           alert('다시 시도해주세요')
    //         })
    //       }
    //       this.questionList = returnList
    //       for(var i = 0; i < this.questionList.length; i++) {
    //         const json = this.questionList[i]
    //         this.questionList[i].REG_DATE = this.api.getDateByFormat(this.questionList[i].REG_DATE, 'YYYY.MM.DD')
          
    //         if(json.REPLY === null || json.REPLY === undefined || json.REPLY === '') this.questionList[i].REPLY_YN = 'N'
    //         else this.questionList[i].REPLY_YN = 'Y'
    //       }
    //       // this.dashboardKey++
    //     }
    //   }).catch( (err) => {
    //     console.log(err)
    //     if(type === 'prev') this.questionPage += 1
    //     else if(type === 'next') this.questionPage -= 1
    //   })
    // },
    // async questionPaging(type) {
    //   if(type === 'prev') {
    //     if(this.msgPage === 1) {
    //       this.emitter.emit('confirm', {message: '첫 페이지입니다.', header:'조회', icon:'pi pi-info-circle'})
    //       return
    //     } else this.questionPage -= 1
    //   } else this.questionPage += 1
    //   this.questionSearch({type:type, page:this.questionPage})
    // },
    // async msgSearch(param) {
    //   const type = param.type
    //   this.msgPage = param.page

    //   await this.api.axiosGql("SELECT_ORDER_BY_ID2", {type:"SELECT_ORDER_BY_ID2", id:this.api.getCookies('id'), page:this.msgPage})
    //   .then( async(res) => {
    //     let result = res.data
    //     if(result === undefined || result.length === 0) {
    //       if(type === 'prev' || type === 'next') {
    //         this.msgPage -= 1
    //         this.$notify('마지막 페이지입니다.')
    //         alert('마지막 페이지입니다.')
    //       }
    //       return
    //     } else {
    //       this.msgList = result
    //       for(var i = 0; i < this.msgList.length; i++) {
    //         const json = this.msgList[i]
    //         if(json.MSG_CHK_YN === true) this.msgList[i].TITLE = json.IDX + '. ' + json.NAME + ' 읽음'
    //         else this.msgList[i].TITLE = json.IDX + '. ' + json.NAME + ' 읽지않음'
    //       }
    //       this.noticecKey++
    //     }
    //   }).catch( (err) => {
    //     console.log(err)
    //     if(type === 'prev') this.msgPage += 1
    //     else if(type === 'next') this.msgPage -= 1
    //   })
    // },
    // async msgPaging(type) {
    //   if(type === 'prev') {
    //     if(this.msgPage === 1) {
    //       this.emitter.emit('confirm', {message: '첫 페이지입니다.', header:'조회', icon:'pi pi-info-circle'})
    //       return
    //     } else this.msgPage -= 1
    //   } else this.msgPage += 1
    //   this.msgSearch({type:type, page:this.msgPage})
    // },
    // pagingQuestion(type) {
    //   if(type === 'prev') {
    //     if(this.questionPage === 1) {
    //       this.emitter.emit('confirm', {message: '첫 페이지입니다.', header:'조회', icon:'pi pi-info-circle'})
    //       return
    //     } else this.questionPage -= 1
    //   } else this.questionPage += 1
    //   this.questionSearch({type:type, page:this.questionPage})
    // },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          this.emitter.emit('confirm', {message: '첫 페이지입니다.', header:'조회', icon:'pi pi-info-circle'})
          return
        } else this.page -= 1
      } else this.page += 1
      let gqlKey
      let p
      let presPage = this.api.getCookies('page')
      if(presPage === 'list') {
        gqlKey = 'SELECT_DISTINCT_ORDER_LIST'
        let rangeJson = this.api.getRange(this.date)
        p = {type:gqlKey, id: "" + this.api.getCookies('id'), startdate: rangeJson.startDate, enddate: rangeJson.endDate, status: this.status, page: this.page}
      } else if(presPage === 'list2') {
        gqlKey = 'SELECT_DISTINCT_ORDER_LIST2'
        let rangeJson = this.api.getRange('r3')
        p = {type:gqlKey, id: "", startdate: rangeJson.startDate, enddate: rangeJson.endDate, status: this.status, page: this.page, uidList:this.uidList}
      }
      await this.api.axiosGql(gqlKey, p)
      .then( async (res) => {
        let result
        if(presPage === 'list') result = res.data
        else if(presPage === 'list2') result = res.data
        this.groupingOrdersKey++
        if(result === undefined || result.length === 0 || result === []) {
          if(type === 'next') {
            this.page -= 1
            this.emitter.emit('confirm', {message: '마지막 페이지입니다.', header:'조회', icon:'pi pi-info-circle'})
            return
          } else if(type === 'prev') {
            this.page += 1
            this.emitter.emit('confirm', {message: '데이터가 없습니다.', header:'조회', icon:'pi pi-info-circle'})
            return
          }
        } else this.clearDeliveryFee()
        
        let orderArray = new Array();
        result.map(order => {
          let country = order.COUNTRY
          let currency = ' EUR'
          if(country === '홍콩') currency = ' HKD'
          else if(country === '영국') currency = ' 파운드'
          else if(country === '미국') currency = ' USD'
          else if(country === '일본') currency = ' 엔'
          else if(country === '중국') currency = ' 위안화'
          else if(country === '싱가포르') currency = ' SGD'
          else if(country ==='호주') currency = ' AUD'
          else if(country ==='뉴질랜드') currency = ' NZD'
          else if(country ==='베트남') currency = ' VND'
          else if(country ==='캐나다') currency = ' CAD'
          else if(country === '기타') currency = ''
          
          order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency
          let status = order.STATUS

          if(status !== undefined) order.STATUS = this.api.getOrderStatus(status)
          order.range = this.date
          orderArray.push(JSON.stringify(order))
        })
        let ordersJson = JSON.parse(JSON.stringify(orderArray))
        this.orders = Object.keys(ordersJson).map( (key) => {
          let order = JSON.parse(ordersJson[key])
          this.date = order.range
          return order
        })
        this.groupByUid()
        this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date})
      })
      .catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })      
    },
    clearDeliveryFee() {
      let totDeliveryPrice = document.getElementsByClassName('deliveryPrice')
      for(let i=0; i<totDeliveryPrice.length; i++) {
        totDeliveryPrice[i].innerHTML = '0'
      }
    },
    setValue(param) {
      const type = param.type
      const idx = Number(param.idx)
      if(type === 'orderNumber') {
        const orderNumber = param.orderNumber
        this.groupingOrders.forEach( (order, key) => {
          if(order[0].IDX === idx) {
            order[0].ORDER_NUMBER = orderNumber
            this.groupingOrders.set(key, order)
            // this.listKey++
          }
        })
      } else if(type === 'trackingNumber') {
        const trackingNumber = param.trackingNumber
        this.groupingOrders.forEach( (order, key) => {
          if(order[0].IDX === idx) {
            order[0].TRACKING_NUMBER = trackingNumber
            this.groupingOrders.set(key, order)
            // this.listKey++
          }
        })
      } else if(type === 'deliveryPrice') {
        const status = this.api.getOrderStatus(param.status)
        if(status > -1) {
          let totDeliveryPrice = document.getElementById(type + "-" + idx)
          let presDeliveryPrice = totDeliveryPrice.innerHTML
          const deliveryPrice = param.deliveryPrice
          presDeliveryPrice = Number(presDeliveryPrice.replace(/,/g, ''))
  
          if(presDeliveryPrice === 0) {
            totDeliveryPrice.innerHTML = deliveryPrice.toLocaleString()
          } else {
            presDeliveryPrice = Number(presDeliveryPrice)
            let sum = presDeliveryPrice + deliveryPrice
            totDeliveryPrice.innerHTML = sum.toLocaleString()
          }
        } 
      }
    },
    paySelected() {
      if(this.pay === '') return
      const payment = this.pay
      this.pay = ''
      window.open('https://' + payment, '_blank')
    },
    getOrdersLength(group) {
      const orders = this.groupingOrders.get(group[0]);
      return orders ? `${orders.length}건` : '0건';
    },
    payment(param) {
      const type = param.type
      const orderList = param.val
      if(type === 'delivery') {
        let isDelivered = 1
        let changedDeliver = false
        let isAllDelivered = true
        for(var j=0; j<orderList.length; j++) {
          const order = orderList[j]
          const deliveryPrice2 = order.DELIVERY_PRICE2
          const status = this.api.getOrderStatus(order.STATUS)
          const progress = ('' + order.PROGRESS).endsWith('0')
          if(deliveryPrice2 > 0 && status < 6 && status > -1 && progress) return 0
          else if(!changedDeliver) {
            if(status < 5) {
              isDelivered = -1
              changedDeliver = true
            } else if(status === 5) isDelivered = 1
          }
          if(deliveryPrice2 <= 0) isAllDelivered = false  
        }
        if(isDelivered === 1 && !changedDeliver && !isAllDelivered) return isDelivered
      }
      return -1
    }
  }
}
</script>

<style scoped>
  #list_table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  #list_table > tr {
    height : 40px;
  }
  #list_table > tr > td:nth-child(odd) {
    width:fit-content;
    font-weight: bolder;
    background: #F5F5F5;
  }
  #list_table > tr > td:nth-child(even) {
    border: 1px solid #F5F5F5;
  }
  .navbar {
    width: 100%;
    background-color: white;
    overflow: auto;
    cursor: pointer;
    background-color: lightgoldenrodyellow;
    border-radius: 9px;
  }
  .navbar span {
    padding-top: 7px;
    padding-bottom: 7px;
    float: left;
    color: black;
    width: 50%;
    text-align: center;
    border-radius: 9px;
  }
  .navbar span.active {
    background-color: lightsalmon;
    color: white;
    font-weight: bold;
  }
  @media screen and (max-width: 50px) {
    .navbar span {
      float: none;
      display: block;
      width: 100%;
      text-align: left;
    }
  }
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
#payCaption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}
.modal-content, #payCaption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}
@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
.pay-modal {
  display: none; 
  position: fixed;
  z-index: 99999999; 
  padding-top: 100px; 
  left: auto; 
  top: 0;
  left:0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.9);
}
.progress-font {
  font-size:8px;
  cursor:pointer;
}
</style>